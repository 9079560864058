import { Button, Card, Col, Input, Row } from "antd";
import React from "react";
import { catSubType } from "../../../config";

export const Colors = ({ colorState, setColorState, sizeType }) => {
  const handleInput = (e) => {
    const { name, value } = e.target;
    setColorState({ ...colorState, [name]: value });
  };

  const handleCopyFromTopColor = () => {
    setColorState({
      ...colorState,
      bottomColor: colorState?.topColor?.toLowerCase(),
      bottomColorName:
        colorState?.topColorName?.charAt(0).toUpperCase() +
        colorState?.topColorName?.slice(1)?.toLowerCase(),
    });
  };
  
  return (
    <Row gutter={[16, 16]} className="mb-3">
      {(sizeType === catSubType.fullSet || sizeType === catSubType.top) && (
        <Col span={12}>
          <Card
            bordered
            style={{ borderColor: "lightgray" }}
            title={<p className="font-weight-bold">Top Color and Code</p>}
          >
            <div className="mb-2">
              <label>
                Color Name <small className="text-danger h6">*</small>
              </label>
              <div>
                <Input
                  placeholder="Color Name"
                  size="large"
                  onChange={handleInput}
                  value={colorState.topColorName}
                  name="topColorName"
                />
              </div>
            </div>
            <div>
              <label>
                Color Code <small className="text-danger h6">*</small>
              </label>
              <Input
                placeholder="Color Code"
                size="large"
                onChange={handleInput}
                value={colorState.topColor}
                name="topColor"
                suffix={
                  <div
                    style={{
                      width: "20px",
                      height: "20px",
                      background: colorState?.topColor,
                      border: "2px solid #2d2d2d",
                    }}
                  ></div>
                }
              />
            </div>
          </Card>
        </Col>
      )}
      {(sizeType === catSubType.fullSet || sizeType === catSubType.bottom) && (
        <Col span={12}>
          <Card
            bordered
            style={{ borderColor: "lightgray" }}
            title={<p className="font-weight-bold">Bottom Color and Code</p>}
            extra={
              sizeType === catSubType.fullSet && (
                <Button
                  type="primary"
                  disabled={!colorState?.topColor || !colorState?.topColorName}
                  onClick={handleCopyFromTopColor}
                >
                  Same as Top
                </Button>
              )
            }
          >
            <div className="mb-2">
              <label>
                Color Name <small className="text-danger h6">*</small>
              </label>
              <div>
                <Input
                  placeholder="Color Name"
                  size="large"
                  onChange={handleInput}
                  value={colorState.bottomColorName}
                  name="bottomColorName"
                />
              </div>
            </div>
            <div>
              <label>
                Color Code <small className="text-danger h6">*</small>
              </label>
              <Input
                placeholder="Color Code"
                size="large"
                onChange={handleInput}
                value={colorState.bottomColor}
                name="bottomColor"
                suffix={
                  <div
                    style={{
                      width: "20px",
                      height: "20px",
                      background: colorState?.bottomColor,
                      border: "2px solid #2d2d2d",
                    }}
                  ></div>
                }
              />
            </div>
          </Card>
        </Col>
      )}
    </Row>
  );
};
