import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Link } from "react-router-dom";
import {
  CATEGORY_LIST_UPDATE,
  CREATE_CATEGORY,
  DELETE_CATEGORY,
  FETCH_CATEGORY,
  FILE_UPLOAD,
  UPDATE_CATEGORY,
} from "../graphql/modules";
import {
  Modal,
  Input,
  Popconfirm,
  Switch,
  Radio,
  Upload,
  Spin,
  Button,
} from "antd";
import { DynamicList } from "../components/Shared";
import { errorNotify, getFile, successNotify, warnNotify } from "../util";
import { Fragment } from "react";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import arrayMove from "array-move";
import { MenuOutlined, UploadOutlined } from "@ant-design/icons";
import { catSubType, userRole } from "../config";
import { useSelector } from "react-redux";

const DragHandle = sortableHandle(() => (
  <MenuOutlined style={{ cursor: "grab", color: "#999" }} />
));
const SortableItem = sortableElement((props) => <tr {...props} />);
const SortableContainer = sortableContainer((props) => <tbody {...props} />);
export const AllCategory = ({}) => {
  // const { user } = useSelector((state) => state.auth);
  // const history = useHistory();
  const [limit] = useState(100);
  const [visible, setVisible] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [coverImage, setCoverImage] = useState("");
  const [sizeType, setSizeType] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [isColored, setIsColored] = useState(false);
  // const [viewCategory, setViewCategory] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const { user } = useSelector((state) => state.auth);

  const { data, loading, error, refetch } = useQuery(FETCH_CATEGORY, {
    variables: {
      limit,
      offset: 0,
    },
    fetchPolicy: "cache-and-network",
  });

  const result = data?.FetchCategoryAdmin?.result || [];

  const updateDataState = () => {
    setDataSource(result);
  };

  useEffect(() => {
    if (Array.isArray(result) && !loading) {
      updateDataState();
    }
  }, [result]);

  // create category api
  const [CreateCategoryMutation, { loading: createLoading }] =
    useMutation(CREATE_CATEGORY);
  const createCategory = async () => {
    try {
      const {
        data: { AddCategory },
      } = await CreateCategoryMutation({
        variables: {
          name: categoryName,
          isColored,
          coverImage,
          sizeType,
        },
      });
      if (AddCategory.success) {
        successNotify(AddCategory.message);
        closeModal();
        refetch();
      } else {
        warnNotify(AddCategory.message);
      }
    } catch (error) {}
  };

  // uypdate category
  const [UpdateCategoryMutation, { loading: updateLoading }] =
    useMutation(UPDATE_CATEGORY);

  const updateCategory = async () => {
    try {
      const {
        data: { EditCategory },
      } = await UpdateCategoryMutation({
        variables: {
          categoryId,
          name: categoryName,
          coverImage,
          isColored,
        },
      });
      if (EditCategory.success) {
        successNotify(EditCategory.message);
        refetch();
        closeModal();
      } else {
        warnNotify(EditCategory.message);
      }
    } catch (error) {
      errorNotify(error.message);
    }
  };

  // handle modal
  const closeModal = () => {
    setCategoryName("");
    setSizeType("");
    setVisible(false);
    setCategoryId("");
    setIsColored(false);
  };

  const openModal = (data) => {
    setCategoryName(data?.name || "");
    setSizeType(data?.sizeType || "");
    setCoverImage(data?.coverImage || "");
    setIsColored(data?.isColored);
    setCategoryId(data?._id);
    setVisible(true);
  };

  // const onChangePagination = (p) => {
  //   setPage(p);
  // };

  // drag and drop
  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMove(
        [].concat(dataSource),
        oldIndex,
        newIndex
      ).filter((el) => !!el);
      setDataSource(newData);
      categoryDnD(newData);
    }
  };

  // drag container
  const DraggableContainer = (props) => (
    <SortableContainer
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  // function findIndex base on Table rowKey props
  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = dataSource.findIndex(
      (item) => item._id === restProps["data-row-key"]
    );
    return <SortableItem index={index} {...restProps} />;
  };

  // update drag and drop
  const [DragAndDropMutation] = useMutation(CATEGORY_LIST_UPDATE);
  const categoryDnD = async (newData = []) => {
    const newArray = newData.map((item) => item._id);
    try {
      const {
        data: { UpdateCategoryPosition },
      } = await DragAndDropMutation({
        variables: {
          data: newArray,
        },
      });
      if (UpdateCategoryPosition.success) {
        refetch();
      } else {
        warnNotify(UpdateCategoryPosition.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // delete category
  const [DeleteCategoryMutation] = useMutation(DELETE_CATEGORY);
  const deleteCategory = async (id) => {
    try {
      const {
        data: { DeleteCategory },
      } = await DeleteCategoryMutation({
        variables: {
          categoryId: id,
        },
      });
      if (DeleteCategory.success) {
        successNotify(DeleteCategory.message);
        refetch();
      } else {
        warnNotify(DeleteCategory.message);
      }
    } catch (error) {
      errorNotify(error.message);
    }
  };

  // coverimage upload
  const [FileUploadMutation, { loading: coverLoading }] =
    useMutation(FILE_UPLOAD);
  const onUploadCover = async (e) => {
    try {
      const {
        data: { SingleUpload },
      } = await FileUploadMutation({
        variables: {
          file: e.file?.originFileObj,
        },
      });
      if (SingleUpload?.success) {
        setCoverImage(SingleUpload?.filename);
      } else {
        warnNotify(SingleUpload?.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleUpdateCategory = async (data, value) => {
    try {
      const {
        data: { EditCategory },
      } = await UpdateCategoryMutation({
        variables: {
          categoryId: data?._id,
          name: data?.name,
          isShowAllSize: value,
        },
      });
      if (EditCategory.success) {
        successNotify(EditCategory.message);
        refetch();
        closeModal();
      } else {
        warnNotify(EditCategory.message);
      }
    } catch (error) {
      errorNotify(error.message);
    }
  };

  const columns = [
    {
      title: "#",
      render: (_text, _row, index) => index + 1,
    },
    {
      title: "Sort",
      render: () => <DragHandle />,
      className: "drag-visible",
      hideen: user.role === userRole.Moderator,
    },
    {
      title: "Category Name",
      dataIndex: "name",
    },
    {
      title: "All size visible",
      dataIndex: "isShowAllSize",
      render: (text, record) => (
        <div>
          {record?.subcategory?.length === 0 && (
              <Switch
                checked={text}
                onChange={(value) => handleUpdateCategory(record, value)}
                checkedChildren="All sizes"
                unCheckedChildren="Stock only"
              />
            )}
        </div>
      ),
    },
    {
      title: "Image",
      dataIndex: "coverImage",
      render: (text) => (
        <img
          src={getFile(text)}
          width="100"
          alt=""
          style={{ height: "100px", objectFit: "cover" }}
        />
      ),
    },
    {
      title: "Colored",
      dataIndex: "isColored",
      render: (text) => (text ? "Yes" : "No"),
    },
    {
      title: "Type",
      dataIndex: "sizeType",
    },
    {
      title: "Action",
      width: "15%",
      render: (text, record) => (
        <Fragment>
          {userRole.Moderator !== user.role && (
            <span style={{ display: "inline-block" }}>
              <div
                onClick={() => openModal(record)}
                style={{ cursor: "pointer", display: "inline-block" }}
              >
                <i className="fas fa-pencil mr-2"></i>
              </div>
              {record?.subcategory?.length === 0 &&
                record?.products?.length === 0 && (
                  <Popconfirm
                    title="Are you sure?"
                    okText="Delete"
                    okType="danger"
                    onConfirm={() => deleteCategory(record._id)}
                  >
                    <div style={{ display: "inline-block" }}>
                      <i
                        className="fas fa-trash mr-2"
                        style={{ cursor: "pointer" }}
                      ></i>
                    </div>
                  </Popconfirm>
                )}
            </span>
          )}

          <Link to={`/categories/${record?._id}`}>
            <i className="fas fa-eye" style={{ cursor: "pointer" }}></i>
          </Link>
        </Fragment>
      ),
    },
  ].filter((item) => !item.hideen);

  if (error) {
    return <div className="card">Something went wrong</div>;
  }

  let createBtn = (
    <button
      onClick={() => setVisible(true)}
      className="btn-common"
      type="primary"
    >
      <i className="fas fa-plus-circle mr-2"></i> Create Category
    </button>
  );

  return (
    <Fragment>
      <DynamicList
        title="List of Categories"
        rowKey={(row) => row._id}
        loading={loading}
        columns={columns}
        dataSource={dataSource || []}
        actions={createBtn}
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
          },
        }}
      />
      <Modal
        title={categoryId ? "Update Cateegory" : "Add new category"}
        open={visible}
        onOk={categoryId ? updateCategory : createCategory}
        okText={categoryId ? "Update" : "Create"}
        okButtonProps={{
          disabled: !categoryName || !sizeType,
          loading: updateLoading || createLoading,
        }}
        onCancel={closeModal}
      >
        <div className="mb-2">
          <div>
            <label>Category Name</label>
          </div>
          <Input
            size="large"
            value={categoryName}
            placeholder="Category name"
            onChange={(e) => setCategoryName(e.target.value)}
          />
        </div>
        <div className="mb-2">
          <div>
            <label>Cover Image</label>
          </div>
          <Spin spinning={coverLoading}>
            <img
              src={getFile(coverImage)}
              alt="cover"
              width="150"
              height="100"
            />
            <div className="mt-2">
              <Upload showUploadList={false} onChange={onUploadCover}>
                <Button type="primary" icon={<UploadOutlined />}>
                  Upload
                </Button>
              </Upload>
            </div>
          </Spin>
        </div>
        <div className="mb-2">
          <div>
            <label>Category Type</label>
          </div>
          <div style={{ border: "1px solid #d9d9d9", padding: "7px 12px" }}>
            <Radio.Group
              onChange={(e) => setSizeType(e.target.value)}
              value={sizeType}
            >
              <Radio value={catSubType.fullSet}>Full Set</Radio>
              <Radio value={catSubType.top}>Top</Radio>
              <Radio value={catSubType.bottom}>Bottom</Radio>
              <Radio value={catSubType.mixed}>Mixed</Radio>
            </Radio.Group>
          </div>
        </div>

        <div className="mt-3">
          <label className="mr-2">Color:</label>
          <Switch
            onChange={(value) => setIsColored(value)}
            checked={isColored}
          />
        </div>
      </Modal>
    </Fragment>
  );
};
