import React from "react";
import { useMutation } from "@apollo/react-hooks";
import { Input, Spin } from "antd";
import { getFile, successNotify, warnNotify } from "../../util";
import { ADD_BANNER, EDIT_BANNER, FILE_UPLOAD } from "../../graphql/modules";
import { useState } from "react";
import { useEffect } from "react";

const PosterOrVideo = ({
  refetch,
  serialText,
  banner,
  isFirstBottomPoster,
  mobile
}) => {
  const [link, setLink] = useState("");
  const [anchorLink, setAnchorLink] = useState("");
  const [fileType, setFileType] = useState("");

  useEffect(() => {
    if (banner?._id) {
      setLink(banner?.link || "");
      setFileType(banner?.type || "");
      setAnchorLink(banner?.anchorLink || "");
    }
  }, [banner]);
  // add banner
  const [AddBannerMutation, { loading: addLoading }] = useMutation(ADD_BANNER);
  const addBanner = async () => {
    try {
      const {
        data: { AddBanner },
      } = await AddBannerMutation({
        variables: {
          link,
          anchorLink,
          type: fileType,
          isFirstBottomPoster,
          isBottomPoster: true,
          isMobileItem: mobile
        },
      });
      if (AddBanner.success) {
        successNotify(AddBanner.message);
        refetch();
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  // update banner
  const [EditBannerMutation, { loading: updateLoading }] =
    useMutation(EDIT_BANNER);
  const editBanner = async () => {
    try {
      const {
        data: { EditBanner },
      } = await EditBannerMutation({
        variables: {
          id: banner?._id,
          link,
          anchorLink,
          type: fileType,
          isFirstBottomPoster,
          isBottomPoster: true,
          isMobileItem: mobile
        },
      });
      if (EditBanner.success) {
        successNotify(EditBanner.message);
        refetch();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // file upload to aws
  const [FileUploadMutaion, { loading: uploadLoading }] =
    useMutation(FILE_UPLOAD);
  const uploadFile = async (e) => {
    const file = e.target.files[0];
    try {
      const {
        data: { SingleUpload },
      } = await FileUploadMutaion({
        variables: {
          file,
        },
      });
      // console.log(SingleUpload)
      if (SingleUpload.success) {
        let type = file.type.includes("video") ? "video" : "image";
        setLink(SingleUpload.filename);
        setFileType(type);
        // uploadBanner(SingleUpload.filename, type);
      } else {
        warnNotify(SingleUpload.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div className="col-md-6">
      <Spin spinning={addLoading || updateLoading}>
        <div className="landing-video">
          <div className="mb-4">
            <div className="mb-2">{serialText} Poster/video</div>
            <Spin spinning={uploadLoading}>
              <div className="mb-2">
                {fileType === "video" ? (
                  <video
                    width="100%"
                    height="100%"
                    autoPlay
                    loop
                    style={{ objectFit: "fill" }}
                    muted
                  >
                    <source src={getFile(link)} />
                  </video>
                ) : (
                  <img width="100%" src={getFile(link)} />
                )}
              </div>
            </Spin>

            <input
              type="file"
              accept="video/*,image/*"
              onChange={uploadFile}
              id="video-upload"
              disabled={uploadLoading}
              className="mb-3"
            />
            <div className="mb-3">
              <label className="mb-2">Banner Redirect URL</label>
              <Input
                size="large"
                placeholder="Banner redirect url"
                value={anchorLink}
                onChange={(e) => setAnchorLink(e.target.value)}
              />
            </div>
            <div className="text-right">
              <button
                htmlFor="video-upload"
                className="btn-common text-right"
                disabled={!link}
                onClick={() => {
                  if (banner?._id) editBanner();
                  else addBanner();
                }}
              >
                <i className="fas fa-plus-circle mr-2"></i>Update
              </button>
            </div>
          </div>
        </div>
      </Spin>
    </div>
  );
};

export default PosterOrVideo;
