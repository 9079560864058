import React, { useState } from "react";
import {
  Card,
  Collapse,
  Input,
  Modal,
  Popconfirm,
  Space,
  Spin,
  Tooltip,
} from "antd";
import {
  CaretRightOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusCircleFilled,
} from "@ant-design/icons";
import Answer from "./Answer";
import ReactQuill from "react-quill";
import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  ADD_POLICY,
  DELETE_POLICY,
  EDIT_POLICY,
  FETCH_POLICY,
} from "../../../graphql/modules";
import { successNotify, warnNotify } from "../../../util";
const PolicyFaq = ({}) => {
  const [visible, setVisible] = useState(false);
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [policyId, setPolicyId] = useState("");

  // handle modal
  const closeModal = () => {
    setPolicyId("");
    setTitle("");
    setDesc("");
    setVisible(false);
  };
  const openModal = (data = {}) => {
    setPolicyId(data?._id);
    setTitle(data?.title);
    setDesc(data?.desc);
    setVisible(true);
  };

  // handle editor input
  const onEditorStateChange = (value) => {
    setDesc(value);
  };

  // fetch all policy
  const { data, refetch, loading } = useQuery(FETCH_POLICY);
  const allPolicy = data?.FetchPrivacyAndPolicy?.result || [];
  // add policy api
  const [AddPolicyMutaion, { loading: addLoading }] = useMutation(ADD_POLICY);
  const addPolicy = async () => {
    try {
      const {
        data: { AddPrivacyAndPolicy },
      } = await AddPolicyMutaion({
        variables: {
          data: {
            title,
            desc,
          },
        },
      });
      if (AddPrivacyAndPolicy.success) {
        closeModal();
        successNotify(AddPrivacyAndPolicy.message);
        refetch();
      } else {
        warnNotify(AddPrivacyAndPolicy.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  // update faq api
  const [EditPolicyMutaion, { loading: editLoading }] =
    useMutation(EDIT_POLICY);
  const editPolicy = async () => {
    try {
      const {
        data: { EditPrivacyAndPolicy },
      } = await EditPolicyMutaion({
        variables: {
          id: policyId,
          data: {
            title,
            desc,
          },
        },
      });
      if (EditPrivacyAndPolicy.success) {
        closeModal();
        successNotify(EditPrivacyAndPolicy.message);
        refetch();
      } else {
        warnNotify(EditPrivacyAndPolicy.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // delete faq question
  const [DeletePolicyMutaion] = useMutation(DELETE_POLICY);
  const deletePolicy = async (id) => {
    try {
      const {
        data: { DeletePrivacyAndPolicy },
      } = await DeletePolicyMutaion({
        variables: {
          id,
        },
      });
      if (DeletePrivacyAndPolicy.success) {
        successNotify(DeletePrivacyAndPolicy.message);
        refetch();
      } else {
        warnNotify(DeletePrivacyAndPolicy.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // extra
  const getExtra = (data) => (
    <React.Fragment>
      <div className="d-flex justify-content-around align-items-center">
        <div className="mr-2">
          <EditOutlined
            onClick={(e) => {
              openModal(data);
              e.stopPropagation();
            }}
          />
        </div>
        <div className="mr-2">
          <Popconfirm
            title="Are you sure?"
            okType="danger"
            onConfirm={(e) => {
              deletePolicy(data._id);
              e.stopPropagation();
            }}
          >
            <DeleteOutlined
              style={{ color: "red" }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
          </Popconfirm>
        </div>
      </div>
    </React.Fragment>
  );

  const getExtraForCategoryCard = () => (
    <div className="d-flex justify-content-around align-items-center">
      <div className="mr-2">
        <Tooltip title="Add policy">
          <PlusCircleFilled style={{ cursor: "pointer" }} onClick={openModal} />
        </Tooltip>
      </div>
    </div>
  );
  return (
    <Card
      className="mb-4"
      title="Click on plus icon to add policy"
      extra={getExtraForCategoryCard()}
    >
      <Spin spinning={loading}>
        <Space direction="vertical" style={{ width: "100%" }}>
          {allPolicy.map((item) => (
            <Collapse
              key={item?._id}
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              )}
              items={[
                {
                  key: "1",
                  label: item?.title,
                  children: <Answer key="1" answerText={item?.desc} />,
                  extra: getExtra(item),
                },
              ]}
            />
          ))}
        </Space>
      </Spin>
      <Modal
        open={visible}
        title={
          policyId ? "Update question and answer" : "Add question and answer"
        }
        onCancel={closeModal}
        okText={policyId ? "Update" : "Add"}
        okButtonProps={{
          disabled: !title || !desc || addLoading || editLoading,
          loading: addLoading || editLoading,
        }}
        onOk={policyId ? editPolicy : addPolicy}
      >
        <div className="mb-4">
          <label>Subject*</label>
          <Input
            size="large"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="question"
          />
        </div>
        <div>
          <label>Answer*</label>
          <ReactQuill
            modules={PolicyFaq.modules}
            value={desc || ""}
            onChange={onEditorStateChange}
          />
        </div>
      </Modal>
    </Card>
  );
};

export default PolicyFaq;

PolicyFaq.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "blockquote"],
    [{ indent: "-1" }, { indent: "+1" }],
  ],
  clipboard: {
    matchVisual: false,
  },
};
