import React from "react";
import { Empty } from "antd";
import { getFile } from "../../util";

const ProdutView = ({ products }) => {
  return (
    <div className="add-product-modal">
      <div className="product-wrapper">
        {!products.length && (
          <Empty
            description="No product has been added to this subcategory."
            image="https://virtualshipment.in/images/no-data.png"
            className="w-100 empty-text"
          />
        )}
        {Array.isArray(products) &&
          products.map(({ product }) => {
            return (
              <div key={product._id} className="single-product">
                <img
                  src={getFile(product?.gallery )}
                  alt="product-image"
                />
                <p className="product-name text-center">{product.name}</p>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default ProdutView;
