import { useMutation, useQuery } from "@apollo/react-hooks";
import { Button, Input } from "antd";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { FETCH_STOCK_LIMIT, UPDATE_STOCK_LIMIT } from "../../graphql/modules";
import { successNotify, warnNotify } from "../../util";

const StockLimit = () => {
  const [limit, setLimit] = useState("");
  const { data } = useQuery(FETCH_STOCK_LIMIT);
  const stockLimit = data?.FetchStockLimit?.limit;

  const [UpdateStockMutation, { loading }] = useMutation(UPDATE_STOCK_LIMIT);
  const onUpdateStock = async () => {
    try {
      const {
        data: { UpdateStockLimit },
      } = await UpdateStockMutation({
        variables: {
          limit: Number(limit),
        },
      });
      if (UpdateStockLimit.success) {
        successNotify(UpdateStockLimit?.message);
      } else {
        warnNotify(UpdateStockLimit?.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    setLimit(stockLimit);
  }, [stockLimit]);

  return (
    <div>
      <div className="col-md-4">
        <div>
          <label>Stock limit number</label>
        </div>
        <Input
          size="large"
          type="number"
          placeholder="Stock limit quantity"
          value={limit}
          onChange={(e) => setLimit(e.target.value)}
        />
        <div className="text-right mt-4">
          <Button
            type="primary"
            onClick={onUpdateStock}
            disabled={limit < 0 || !limit}
            loading={loading}
          >
            Update
          </Button>
        </div>
      </div>
    </div>
  );
};

export default StockLimit;
