import React, { useState } from "react";
import {
  CheckOutlined,
  CloseOutlined,
  DeleteFilled,
  EditOutlined,
} from "@ant-design/icons";
import { useMutation } from "@apollo/react-hooks";
import { Modal, Spin } from "antd";

import {
  DELETE_SUBCATEGORY_GROUP,
  EDIT_SUBCATEGORY_GROUP,
} from "../../graphql/modules";

export const GroupManage = ({ groups, refetch }) => {
  const [visible, setVisible] = useState(false);

  const handleCancel = () => {
    setVisible(false);
    // setEditMode(false);
  };

  // delete subcategory group

  const [DeleteGroup, { loading }] = useMutation(DELETE_SUBCATEGORY_GROUP);

  const handleDeleteGroup = async (subcategoryGroupId) => {
    try {
      const {
        data: { DeleteSubcategoryGroup },
      } = await DeleteGroup({
        variables: {
          subcategoryGroupId,
        },
      });
      if (DeleteSubcategoryGroup.success) {
        setVisible(false);
        refetch();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const [EditGroup, { loading: editLoading }] = useMutation(
    EDIT_SUBCATEGORY_GROUP
  );
  const handleEdit = async (id, name) => {
    try {
      const {
        data: { EditSubcategoryGroup },
      } = await EditGroup({
        variables: {
          id,
          name,
        },
      });
      if (EditSubcategoryGroup.success) {
        refetch();
        handleCancel();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div>
      <button
        className="btn-common"
        style={{ fontSize: "12px" }}
        type="primary"
        onClick={() => setVisible(true)}
      >
        Manage Groups
      </button>
      <Modal
        title="Group"
        open={visible}
        className="group-modal"
        width={300}
        centered
        onCancel={handleCancel}
        footer={false}
      >
        <Spin spinning={loading || editLoading}>
          <div className="">
            {groups?.map((grp) => (
              <SingleGroup
                key={grp?._id}
                grp={grp}
                handleDeleteGroup={handleDeleteGroup}
                handleEdit={handleEdit}
              />
            ))}
          </div>
        </Spin>
      </Modal>
    </div>
  );
};

const SingleGroup = ({ grp, handleDeleteGroup, handleEdit }) => {
  const [editMode, setEditMode] = useState(false);
  const [name, setName] = useState("");
  const handleEditMode = (value) => {
    setName(value);
    setEditMode(true);
  };

  const onEdit = () => {
    handleEdit(grp?._id, name);
    setEditMode(false);
  };

  return (
    <div className="category-group mb-4">
      <input
        value={editMode ? name : grp?.name}
        disabled={!editMode}
        onChange={(e) => setName(e.target.value)}
      />
      <div className="action">
        {editMode ? (
          <React.Fragment>
            <CloseOutlined
              style={{
                cursor: "pointer",
                marginRight: "10px",
              }}
              onClick={() => setEditMode(false)}
            />
            <CheckOutlined
              style={{
                cursor: "pointer",
              }}
              onClick={onEdit}
            />
          </React.Fragment>
        ) : (
          <React.Fragment>
            {grp?.subcategory?.length < 1 && (
              <DeleteFilled
                style={{
                  color: "red",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
                onClick={() => handleDeleteGroup(grp?._id)}
              />
            )}
            <EditOutlined
              style={{
                cursor: "pointer",
              }}
              onClick={() => handleEditMode(grp?.name)}
            />
          </React.Fragment>
        )}
      </div>
    </div>
  );
};
