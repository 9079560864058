import { Button } from "antd";
import React, { Fragment } from "react";
import ReactQuill from "react-quill";

const AboutContent = ({ onTextHandler, contentText, saveContent, loading }) => {
  return (
    <Fragment>
      <ReactQuill
        modules={AboutContent.modules}
        value={contentText || ""}
        onChange={onTextHandler}
        className="mb-4"
      />
      <div className="text-right">
        <Button
          type="primary"
          disabled={!contentText || loading}
          onClick={saveContent}
          loading={loading}
        >
          Save
        </Button>
      </div>
    </Fragment>
  );
};

export default AboutContent;

AboutContent.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "blockquote", "link"],
    [{ indent: "-1" }, { indent: "+1" }],
  ],
  clipboard: {
    matchVisual: false,
  },
};
