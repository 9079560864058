import { useQuery } from "@apollo/react-hooks";
import { Empty, Spin } from "antd";
import React from "react";
import { ProductList } from "../components/Products";
import { DynamicList } from "../components/Shared";
import { Link } from 'react-router-dom'
import { FETCH_SINGLE_SUBCATEGORY } from "../graphql/modules";
import { catSubType } from "../config";

export const SubcategoryView = ({ match }) => {
  // subcategory id
  const subcategoryId = match.params.id;

  //initial state
  // const [visible, setVisible] = useState(false);

  // fetch products
  const { data, loading, error, refetch } = useQuery(FETCH_SINGLE_SUBCATEGORY, {
    variables: {
      subcategoryId,
    },
    fetchPolicy: "cache-and-network",
  });

  const result = data?.FetchSingleSubcategoryAdmin?.result || {};


  const mixedCategoryUrl = `/add-mixed-product/${subcategoryId}?cat-sub=subcategoryId`;
  const regularCategoryUrl = `/add-product/${subcategoryId}?size-type=${result?.sizeType}&cat-sub=subcategoryId`;

  //page top action button
  let createBtn = (
    <React.Fragment>
      <Link
        // onClick={() => setVisible(true)}
        to={result?.sizeType === catSubType.mixed ? mixedCategoryUrl:regularCategoryUrl}
        className="btn-common btn-anchor"
      >
        <i className="fas fa-plus-circle mr-2"></i> Add Product
      </Link>
    </React.Fragment>
  );

  // error ui
  if (error) {
    return <div className="card">Something went wrong</div>;
  }

  return (
    <React.Fragment>
      <DynamicList
        title={`Products list of ${result?.name}`}
        loading={loading}
        actions={createBtn}
        tableHide={true}
      >
        <Spin spinning={loading}>
          {(result?.products?.length < 1 ||
            loading) && <Empty />}
          <ProductList
            data={result?.products || []}
            sizeType={result?.sizeType}
            refetch={refetch}
            from={{
              type: "subcategoryId",
              id: result?._id,
            }}
          />
        </Spin>
      </DynamicList>
      {/* <ProductForm
        data={{}}
        visible={visible}
        sizeType={result?.sizeType}
        setVisible={setVisible}
        from={{
          type: "subcategoryId",
          id: data?.FetchSingleSubcategoryAdmin?.result?._id,
        }}
        refetch={refetch}
      /> */}
    </React.Fragment>
  );
};
