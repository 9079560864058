import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/react-hooks";
import { Button, Input, Modal, Spin } from "antd";
import React from "react";
import { useState } from "react";
import { getFile } from "../../../util";
import { FILE_UPLOAD } from "../../../graphql/modules";

export const StyleImages = ({ styles, setStyles }) => {
  const [visible, setVisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [name, setName] = useState("");
  const [quantity, setQuantity] = useState(0);
  const [selectedIdx, setSelectedIdx] = useState(0);
  const [styleImage, setStyleImage] = useState("");
  const [SingleUplaodMutation, { loading: uploadLoading }] =
    useMutation(FILE_UPLOAD);
  const uploadImage = async (e) => {
    try {
      const {
        data: { SingleUpload },
      } = await SingleUplaodMutation({
        variables: {
          file: e.target.files[0],
        },
      });
      if (SingleUpload.success) {
        setStyleImage(SingleUpload.filename);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // delete from state
  const delteStyle = (idx) => {
    let images = [...styles];
    images.splice(idx, 1);
    setStyles([...images]);
    console.log(images)
  };

  const onOkModal = () => {
    onCancel();
    let images = [...styles];
    images.push({ name, quantity: Number(quantity), url: styleImage });
    setStyles([...images]);
  };

  const onEditModal = ({ name, quantity, url }, idx) => {
    setName(name);
    setQuantity(quantity);
    setSelectedIdx(idx);
    setStyleImage(url);
    setEditVisible(true);
  };

  const onEditOkModal = () => {
    let images = [...styles];
    images[selectedIdx] = { name, quantity: Number(quantity), url: styleImage };
    setStyles([...images]);
    onCancel();
  };

  const onCancel = () => {
    setVisible(false);
    setEditVisible(false);
    setSelectedIdx(0);
    setName("");
    setStyleImage("");
  };
  return (
    <div className="mt-4">
      <div className="d-flex align-items-center justify-content-between mb-2">
        <h6>Styles & Colors</h6>
        <Button
          type="primary"
          className=""
          style={{ borderRadius: "5px" }}
          onClick={() => setVisible(true)}
        >
         <PlusOutlined/> ADD
        </Button>
      </div>

      <div className="d-flex">
        <div className="w-50">
          <b>Image</b>
        </div>
        <div className="w-25">
          <div className="text-center">
            <b>Name</b>
          </div>
        </div>
        <div className="w-25">
          <div className="text-center">
            <b>Quantity</b>
          </div>
        </div>
      </div>
      {styles?.map((item, idx) => (
        <div
          className="d-flex align-items-center mb-2 p-3 position-relative"
          key={idx}
          style={{ border: "1px solid #D0CFCF	", borderRadius: "5px" }}
        >
          <div className="w-50">
            <img
              style={{ width: "100px", height: "100px" }}
              src={getFile(item?.url)}
              alt=""
            />
          </div>
          <div className="w-25">
            <div className="text-center">
              <b>{item?.name}</b>
            </div>
          </div>
          <div className="w-25">
            <div className="text-center">
              <b>{item?.quantity}</b>
            </div>
          </div>
          <div
            className="position-absolute"
            style={{
              top: "6px",
              right: "10px",
              fontSize: "15px",
              cursor: "pointer",
            }}
          >
            <EditOutlined onClick={() => onEditModal(item, idx)} />
            <DeleteOutlined
              style={{ color: "red", marginLeft: "10px" }}
              onClick={delteStyle}
            />
          </div>
        </div>
      ))}
      {styles.length === 0 && (
        <div
          className="p-4 text-center"
          style={{
            border: "1px solid #D0CFCF	",
            borderRadius: "5px",
            color: "lightgray",
          }}
        >
          <span>Empty</span>
        </div>
      )}
      <Modal
        open={visible}
        onCancel={onCancel}
        title="Style Add"
        onOk={onOkModal}
        okButtonProps={{
          disabled: !name || !styleImage,
        }}
        okText="Add"
      >
        <div className="mb-3">
          <label>Name</label>
          <Input
            size="large"
            placeholder="Style name"
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
        </div>
        <div className="mb-3">
          <label>Quantity</label>
          <Input
            size="large"
            placeholder="Style quantity"
            onChange={(e) => setQuantity(e.target.value)}
            value={quantity}
          />
        </div>
        <div className="mb-3">
          <label className="mb-2">Image</label>
          <div className="text-center mb-2">
            <Spin spinning={uploadLoading}>
              <img src={getFile(styleImage)} width="200" alt=""/>
            </Spin>
          </div>
          <div className="text-center">
            <Input
              size="large"
              style={{ width: "200px" }}
              type="file"
              accept="/*img"
              id="upload-style-image"
              onChange={uploadImage}
            />
          </div>
        </div>
      </Modal>
      <Modal
        open={editVisible}
        onCancel={onCancel}
        title="Style Edit"
        onOk={onEditOkModal}
        okButtonProps={{
          disabled: !name || !styleImage,
        }}
        okText="Update"
      >
        <div className="mb-3">
          <label>Name</label>
          <Input
            size="large"
            placeholder="Style name"
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
        </div>
        <div className="mb-3">
          <label>Quantity</label>
          <Input
            size="large"
            placeholder="Style quantity"
            onChange={(e) => setQuantity(e.target.value)}
            value={quantity}
          />
        </div>
        <div className="mb-3">
          <label className="mb-2">Image</label>
          <div className="text-center mb-2">
            <Spin spinning={uploadLoading}>
              <img src={getFile(styleImage)} width="200" alt=""/>
            </Spin>
          </div>
          <div className="text-center">
            <Input
              size="large"
              style={{ width: "200px" }}
              type="file"
              id="upload-style-modal"
              accept="/*img"
              onChange={uploadImage}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};
