import { Input, Modal, Spin } from "antd";
import React from "react";
import { useState } from "react";
import { DynamicList } from "../components/Shared";
import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  ADD_LEGAL_CATEGORY,
  DELETE_LEGAL_CATEGORY,
  EDIT_LEGAL_CATEGORY,
  FETCH_LEGAL_CATEGORY,
} from "../graphql/modules";
import { successNotify, warnNotify } from "../util";
import { SingleLegal } from "../components/Legal";

export const Legal = () => {
  const [name, setName] = useState("");
  const [visible, setVisible] = useState(false);
  const [categoryId, setCategoryId] = useState("");

  // fetch all faq
  const { data, loading, refetch } = useQuery(FETCH_LEGAL_CATEGORY);
  const allLegal = data?.FetchLegalTerm?.result || [];

  // add category faq api
  const [AddFaqCategoryMutation, { loading: addLoading }] =
    useMutation(ADD_LEGAL_CATEGORY);
  const addFaqCategory = async () => {
    try {
      const {
        data: { AddLeagalCategory },
      } = await AddFaqCategoryMutation({
        variables: {
          name,
        },
      });
      if (AddLeagalCategory.success) {
        closeModal();
        successNotify(AddLeagalCategory.message);
        refetch();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // update category faq api
  const [EditFaqCategoryMutation, { loading: editLoading }] =
    useMutation(EDIT_LEGAL_CATEGORY);
  const editFaqCategory = async () => {
    try {
      const {
        data: { EditLeagalCategory },
      } = await EditFaqCategoryMutation({
        variables: {
          name,
          categoryId,
        },
      });
      if (EditLeagalCategory.success) {
        closeModal();
        successNotify(EditLeagalCategory.message);
        refetch();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // delete category faq
  const [DeleteFaqCategoryMutaion] = useMutation(
    DELETE_LEGAL_CATEGORY
  );
  const deleteFaqCategory = async (id) => {
    try {
      const {
        data: { DeleteLeagalCategory },
      } = await DeleteFaqCategoryMutaion({
        variables: {
          categoryId: id,
        },
      });
      if (DeleteLeagalCategory.success) {
        successNotify(DeleteLeagalCategory.message);
        refetch();
      } else {
        warnNotify(DeleteLeagalCategory.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // confirm modal
  const confirmDeleteCategory = (id)=>{
      Modal.confirm({
          content: 'If you delete an section then all question and ans will be delete',
          onOk: ()=>deleteFaqCategory(id),
          okType: 'danger',
          okText: 'Delete',
          cancelText: 'Cancel'
      })
  }
 
  // handle modal
  const closeModal = () => {
    setVisible(false);
    setCategoryId("");
    setName("");
  };
  const openModal = (data = {}) => {
    setCategoryId(data?._id);
    setName(data?.name);
    setVisible(true);
  };

  // const jsx
  let createBtn = (
    <React.Fragment>
      <button
        onClick={() => setVisible(true)}
        className="btn-common"
        type="primary"
      >
        <i className="fas fa-plus-circle mr-2"></i> Create Category
      </button>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <DynamicList
        title="Set Legal and Terms"
        actions={createBtn}
        tableHide={true}
      >
        <Spin spinning={loading}>
          {allLegal.map((item) => (
            <SingleLegal
              refetch={refetch}
              key={item._id}
              loading={loading}
              category={item}
              confirmDeleteCategory={confirmDeleteCategory}
              editFaqCategory={openModal}
            />
          ))}
        </Spin>
      </DynamicList>
      <Modal
        open={visible}
        okText={categoryId ? "Update" : "Add"}
        title={categoryId ? "Update Legal subject" : "Add Legal subject"}
        onOk={categoryId ? editFaqCategory : addFaqCategory}
        onCancel={closeModal}
        okButtonProps={{
          disabled: !name || addLoading || editLoading,
          loading: addLoading || editLoading,
        }}
      >
        <label>Subject</label>
        <div>
          <Input
            placeholder="subject"
            size="large"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
      </Modal>
    </React.Fragment>
  );
};
