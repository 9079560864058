import React, { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { CHANGE_EMAIL, UPDATE_PROFILE } from "../graphql/modules";
import { successNotify, warnNotify } from "../util";
import { Input, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser, updateProfileAction } from "../store/modules";
import { CustomButton } from "../components/Shared";
import Modal from "antd/lib/modal/Modal";

export const UpdateProfile = ({ match }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const [state, setState] = useState({
    firstname: user?.firstname,
    lastname: user?.lastname,
  });

  const [visible, setVisible] = useState(false);
  const [emailChange, setEmailChange] = useState({
    newEmail: "",
    password: "",
  });

  // update profile api
  const [UpdateProfileMutation, { loading }] = useMutation(UPDATE_PROFILE, {
    variables: {
      profileData: {
        ...state,
      },
    },
  });

  const onUpdate = async (e) => {
    e.preventDefault();
    try {
      const {
        data: { AdminProfileUpdate },
      } = await UpdateProfileMutation();
      if (AdminProfileUpdate.success) {
        successNotify(AdminProfileUpdate.message);
        dispatch(updateProfileAction(state));
      } else {
        warnNotify(AdminProfileUpdate.message);
      }
    } catch (error) {
      console.log(error.message);
      warnNotify(error.message);
    }
  };

  // handle basic info input
  const onChange = (e) =>
    setState({ ...state, [e.target.name]: e.target.value });

  // handle email change
  const handleEmailChange = (e) =>
    setEmailChange({ ...emailChange, [e.target.name]: e.target.value });

    // control modal
  const closeModal = () => {
    setEmailChange({ newEmail: "", password: "" });
    setVisible(false)
  };

  // logout
  const onLogout = () => {
    dispatch(logoutUser());
  };

  // email change api
  const [ChangeEmailMutation, { loading: emailLoading }] = useMutation(
    CHANGE_EMAIL,
    {
      variables: {
        password: emailChange.password,
        newEmail: emailChange.newEmail,
      },
    }
  );
  const onChangeEmail = async (e) => {
    // e.preventDefault();
    const {
      data: { ChangeEmailAdmin },
    } = await ChangeEmailMutation();
    if (ChangeEmailAdmin.success) {
      onLogout()
      closeModal();
      successNotify(ChangeEmailAdmin.message);

    } else {
      warnNotify(ChangeEmailAdmin.message);
    }
  };

  const disable = !state.firstname || !state.lastname;

  return (
    <Spin spinning={false}>
      <div>
        <div className="heading"></div>
        <h1 className="title">Update Profile</h1>
        <form onSubmit={onUpdate}>
          <div className="card">
            <div className="create-permit-details">
              <div className="form-row">
                <div className="form-group col-md-4">
                  <input
                    type="name"
                    className="form-control"
                    placeholder="First name"
                    name="firstname"
                    value={state.firstname || ""}
                    onChange={onChange}
                  />
                </div>
                <div className="form-group col-md-4">
                  <input
                    type="name"
                    className="form-control"
                    placeholder="Last name"
                    name="lastname"
                    value={state.lastname || ""}
                    onChange={onChange}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-4">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    name="email"
                    value={user?.email || ""}
                    onChange={onChange}
                    onClick={() => setVisible(true)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="account-details-btn">
            <CustomButton
              type="submit"
              loading={loading}
              disabled={disable || loading}
              className="btn btn-primary"
            >
              Update
            </CustomButton>
          </div>
        </form>
      </div>
      <Modal
        open={visible}
        title="Change Email"
        onOk={onChangeEmail}
        onCancel={closeModal}
        okButtonProps={{
          disabled: emailLoading,
          loading: emailLoading,
        }}
      >
        <div>
          <label>Password</label>
        </div>
        <Input
          size="large"
          placeholder="Password"
          name="password"
          value={emailChange.password || ""}
          onChange={handleEmailChange}
          className="mb-3"
        />
        <div>
          <label>New Email</label>
        </div>
        <Input
          size="large"
          value={emailChange.newEmail || ""}
          placeholder="New Email"
          name="newEmail"
          onChange={handleEmailChange}
        />
      </Modal>
    </Spin>
  );
};
