import { Input, Row } from "antd";
import React from "react";

export const AccessoriesInventory = ({ inventoryState, setInventoryState }) => {
  return (
    <Row>
      <div style={{ width: "300px" }}>
        <label>
          Quantity <small className="text-danger h6">*</small>
        </label>
        <Input
          size="large"
          placeholder="Quantity"
          type="number"
          value={inventoryState?.quantity}
          onChange={(e) =>
            setInventoryState({
              ...inventoryState,
              quantity: e.target.value,
            })
          }
        />
      </div>
    </Row>
  );
};
