import gql from "graphql-tag";

export const FETCH_SIZING = gql`
  query FetchSizing($limit: Int, $offset: Int, $type: SizeType) {
    FetchSizing(limit: $limit, offset: $offset, type: $type) {
      code
      count
      message
      success
      result {
        _id
        name
      }
    }
  }
`;

export const DELETE_SIZING = gql`
  mutation DeleteSizing($sizingId: ID!) {
    DeleteSizing(sizingId: $sizingId) {
      code
      success
      message
    }
  }
`;

export const EDIT_SIZING = gql`
  mutation EditSizing($sizingId: ID!, $name: String!) {
    EditSizing(sizingId: $sizingId, name: $name) {
      code
      success
      message
    }
  }
`;

export const ADD_SIZING = gql`
  mutation AddSizing($name: String!, $type: SizeType!) {
    AddSizing(name: $name, type: $type) {
      code
      success
      message
    }
  }
`;

export const FETCH_SIZE_GUIDE = gql`
  query FetchSizeGuide($type: SizeGuideTypeEnum) {
    FetchSizeGuide(type: $type) {
      code
      message
      success
      result {
        _id
        type
        name
        measure_1
        measure_2
        createdAt
      }
    }
  }
`;

export const UPDATE_SIZING_POSITION = gql`
  mutation UpdateSizingPosition($data: [ID]) {
    UpdateSizingPosition(data: $data) {
      code
      success
      message
    }
  }
`;

export const ADD_SIZE_GUIDE = gql`
  mutation AddSizeGuide($data: SizeGuideInput!) {
    AddSizeGuide(data: $data) {
      code
      message
      success
    }
  }
`;

export const EDIT_SIZE_GUIDE = gql`
  mutation EditSizeGuide($sizeGuideId: ID!, $data: SizeGuideInput!) {
    EditSizeGuide(sizeGuideId: $sizeGuideId, data: $data) {
      code
      message
      success
    }
  }
`;

export const DELETE_SIZE_GUIDE = gql`
  mutation DeleteSizeGuide($sizeGuideId: ID!) {
    DeleteSizeGuide(sizeGuideId: $sizeGuideId) {
      code
      message
      success
    }
  }
`;

export const FETCH_APP_SETTINGS = gql`
  query FetchAppSettings {
    FetchAppSettings {
      code
      success
      message
      quantity
      isInMaintenance
      announcement {
        msg
        isOn
      }
    }
  }
`;

export const UPDATE_APP_SETTINGS = gql`
  mutation UpdateAppSettings(
    $quantity: Int
    $isInMaintenance: Boolean
    $announcement: AnnouncementInput
  ) {
    UpdateAppSettings(
      quantity: $quantity
      isInMaintenance: $isInMaintenance
      announcement: $announcement
    ) {
      code
      success
      message
    }
  }
`;

export const CREATE_ORDER_DISCOUNT = gql`
  mutation CreateOrderDiscount($inputData: OrderDiscountInput) {
    CreateOrderDiscount(inputData: $inputData) {
      success
      message
    }
  }
`;

export const UPDATE_ORDER_DISCOUNT = gql`
  mutation UpdatedOrderDiscount($id: ID, $inputData: OrderDiscountInput) {
    UpdatedOrderDiscount(id: $id, inputData: $inputData) {
      success
      message
    }
  }
`;

export const DELETE_ORDER_DISCOUNT = gql`
  mutation DeleteOrderDiscount($id: ID) {
    DeleteOrderDiscount(id: $id) {
      success
      message
    }
  }
`;

export const FETCH_DISCOUNT_ON_PURCHASE = gql`
  query FetchOrderDiscount {
    FetchOrderDiscount {
      result {
        _id
        # isDiscountOn
        validUntil
        amount
        discountAmount
        status
        createdAt
      }
    }
  }
`;

export const FETCH_STOCK_LIMIT = gql`
  query FetchStockLimit {
    FetchStockLimit {
      code
      success
      limit
    }
  }
`;

export const UPDATE_STOCK_LIMIT = gql`
  mutation UpdateStockLimit($limit: Int) {
    UpdateStockLimit(limit: $limit) {
      code
      success
      message
    }
  }
`;

export const ADD_TAX = gql`
  mutation AddTax($data: TaxInput!) {
    AddTax(data: $data) {
      message
      success
    }
  }
`;

export const EDIT_TAX = gql`
  mutation EditTax($taxId: ID!, $data: TaxInput!) {
    EditTax(taxId: $taxId, data: $data) {
      message
      success
    }
  }
`;

export const DELETE_TAX = gql`
  mutation DeleteTax($taxId: ID!) {
    DeleteTax(taxId: $taxId) {
      message
      success
    }
  }
`;

export const FETCH_TAXES = gql`
  query FetchTax($limit: Int, $offset: Int) {
    FetchTax(limit: $limit, offset: $offset) {
      success
      result {
        _id
        country
        state
        rate
      }
    }
  }
`;
