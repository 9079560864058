import React, { Fragment, useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Button, Switch, Spin, Collapse } from "antd";
import { DynamicList } from "../components/Shared";
import { FETCH_APP_SETTINGS, UPDATE_APP_SETTINGS } from "../graphql/modules";
import { successNotify, warnNotify } from "../util";
import ReactQuill from "react-quill";
import { EditOutlined } from "@ant-design/icons";
import DiscountOnPrice from "../components/Settings/DiscountOnPrice";
import StockLimit from "../components/Settings/StockLimit";
import Tax from "../components/Settings/Tax";
// import CartQuantityLimit from "../components/Settings/CartQuantity";

export const Settings = () => {
  const [minimumQuantityState, setMinimumQuantityState] = useState("");
  const [isInMaintenance, setIsInMaintenance] = useState(false);
  const [isAnnounceMentPublished, setIsAnnouncementPublished] = useState(false);
  const [text, setText] = useState("");
  // fetch minimum quantity
  const {
    data: appSettings,
    refetch: appSettingRefetch,
    loading,
  } = useQuery(FETCH_APP_SETTINGS);
  useEffect(() => {
    setMinimumQuantityState(appSettings?.FetchAppSettings?.quantity);
    if (!loading) {
      setIsInMaintenance(!!appSettings?.FetchAppSettings?.isInMaintenance);
    }
    setIsAnnouncementPublished(
      appSettings?.FetchAppSettings?.announcement?.isOn
    );
    setText(appSettings?.FetchAppSettings?.announcement?.msg);
  }, [appSettings]);

  //update api for minimum quantity
  const [UpdateMinimumQuantityMutation, { loading: appSettingLoading }] =
    useMutation(UPDATE_APP_SETTINGS);

  const onUpdateSettings = async (fieldName, value) => {
    try {
      const {
        data: { UpdateAppSettings },
      } = await UpdateMinimumQuantityMutation({
        variables: {
          quantity: Number(minimumQuantityState),
          [fieldName]: value,
        },
      });
      if (UpdateAppSettings.success) {
        appSettingRefetch();
        successNotify(UpdateAppSettings.message);
      } else {
        warnNotify(UpdateAppSettings.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const onUpdateAnnounceSettings = async (value) => {
    try {
      const {
        data: { UpdateAppSettings },
      } = await UpdateMinimumQuantityMutation({
        variables: {
          announcement: {
            msg: text,
            isOn: value,
          },
        },
      });
      if (UpdateAppSettings.success) {
        appSettingRefetch();
        successNotify(UpdateAppSettings.message);
      } else {
        warnNotify(UpdateAppSettings.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <Fragment>
      <Spin spinning={appSettingLoading || loading}>
        {/* <DynamicList title="Set Minimum quantity number" tableHide>
          <Input
            size="large"
            className="mb-2"
            placeholder="Quantity"
            type="number"
            value={minimumQuantityState}
            onChange={(e) => setMinimumQuantityState(e.target.value)}
          />
          <div className="text-right">
            <Button
              type="primary"
              disabled={!minimumQuantityState}
              onClick={() => onUpdateSettings(isInMaintenance)}
            >
              Save
            </Button>
          </div>
        </DynamicList> */}
        <DynamicList title="Maintaincy Section" tableHide>
          <div className="d-flex align-items-center justify-content-between">
            <p style={{ fontWeight: "400", fontSize: "17px" }}>
              {isInMaintenance
                ? "Website is in maintenance mode."
                : "Website is running."}
            </p>
            <Switch
              checked={isInMaintenance}
              onChange={(value) => {
                setIsInMaintenance(value);
                onUpdateSettings("isInMaintenance", value);
              }}
            />
          </div>
        </DynamicList>
        <DynamicList title="Website announcement" tableHide>
          <div className="d-flex align-items-center justify-content-between">
            <p style={{ fontWeight: "400", fontSize: "17px" }}>
              {isAnnounceMentPublished
                ? "Announcement is published."
                : "Announcement is closed."}
            </p>
            <Switch
              checked={isAnnounceMentPublished}
              onChange={(value) => {
                setIsAnnouncementPublished(value);
                onUpdateAnnounceSettings(value);
              }}
            />
          </div>
          <div className="mt-5">
            <div className="announce-text">
              <label>Announcement message</label>

              <Collapse>
                <Collapse.Panel
                  header={
                    <div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            appSettings?.FetchAppSettings?.announcement?.msg,
                        }}
                      />
                    </div>
                  }
                  extra={[<EditOutlined key="1" />]}
                  key="1"
                  showArrow={false}
                  collapsible="extra"
                >
                  <ReactQuill
                    modules={Settings.modules}
                    style={{whiteSpace: "pre"}}
                    placeholder="write messege"
                    onChange={(value) => setText(value)}
                    value={text}
                  />
                  <div className="text-right mt-3">
                    <Button
                      type="primary"
                      disabled={!text}
                      onClick={() =>
                        onUpdateAnnounceSettings(isAnnounceMentPublished)
                      }
                    >
                      Update
                    </Button>
                  </div>
                </Collapse.Panel>
              </Collapse>
            </div>
          </div>
        </DynamicList>
        {/* <DynamicList title="Cart quantity limitation" tableHide>
          {!loading && (
            <CartQuantityLimit
              onUpdateSettings={onUpdateSettings}
              limit={appSettings?.FetchAppSettings?.cartQuantityLimit}
              loading={loading}
            />
          )}
        </DynamicList> */}
        <DynamicList title="Discount on purchase price" tableHide>
          <DiscountOnPrice />
        </DynamicList>
        <DynamicList title="Stock Limit" tableHide>
          <StockLimit />
        </DynamicList>
        <DynamicList title="Tax" tableHide>
          <Tax />
        </DynamicList>
      </Spin>
    </Fragment>
  );
};

Settings.modules = {
  toolbar: [["bold", "italic", "underline", "blockquote", "link"]],
  clipboard: {
    matchVisual: false,
  },
};
