import React from "react";
import { DynamicList } from "../components/Shared";
import { useQuery } from "@apollo/react-hooks";
import { Spin } from "antd";
import { FETCH_CATEGORY, FETCH_HOMEPAGE_SECTION } from "../graphql/modules";
import {
  CategorySection,
  SetupLandingCategory,
  SetupPoster,
  // SectionSort,
} from "../components/LandingPage";
import PosterOrVideoWrapper from "../components/LandingPage/PosterOrVideoWrapper";
import ShopCollection from "../components/LandingPage/ShopCollection";
import CustomerPicks from "../components/LandingPage/CustomerPicks";

export const LandingPage = () => {
  // const fetch all homepage
  const { data } = useQuery(FETCH_HOMEPAGE_SECTION);
  const homePageData = data?.FetchHomepageSectionsWithProduct?.result || [];
  const { data: allCategory, loading: categoryLaoding } =
    useQuery(FETCH_CATEGORY);
  const category = allCategory?.FetchCategoryAdmin?.result || [];

  return (
    <Spin spinning={false} className="landing-page">
      {/* <DynamicList title="Set position of section" tableHide={true}>
        <SectionSort />
      </DynamicList> */}
      <DynamicList title="Setup Banner video (Desktop)" tableHide={true}>
        <SetupPoster />
      </DynamicList>
      <DynamicList title="Setup Banner video (Mobile)" tableHide={true}>
        <SetupPoster mobile={true} />
      </DynamicList>
      <DynamicList title="Setup Poster/Video (Desktop)" tableHide>
        <PosterOrVideoWrapper />
      </DynamicList>
      <DynamicList title="Setup Poster/Video (Mobile)" tableHide>
        <PosterOrVideoWrapper mobile={true}/>
      </DynamicList>
      <DynamicList title="Category Section" tableHide>
        <CategorySection category={category}/>
      </DynamicList>
      {homePageData?.length > 0 && (
        <DynamicList title="Carousel Section" tableHide>
          <SetupLandingCategory
            category={category}
            type="Caurosel"
            data={homePageData?.find((item) => item?.type === "Caurosel")}
            loading={categoryLaoding}
          />
        </DynamicList>
      )}
      {/* {homePageData?.map((item, idx) => (
        <DynamicList
          key={idx}
          title={
            item?.type === "Featured" ? "Feature Section" : "Carousel Section"
          }
          tableHide={true}
        >
          <SetupLandingCategory
            category={category}
            type={item?.type}
            data={item}
            loading={categoryLaoding}
          />
        </DynamicList>
      ))} */}
      <DynamicList title="Setup Shop Collection" tableHide={true}>
        <ShopCollection />
      </DynamicList>
      <DynamicList title="Setup Customer Picks" tableHide={true}>
        <CustomerPicks />
      </DynamicList>
    </Spin>
  );
};
