import { useMutation } from "@apollo/react-hooks";
import { Input, Modal } from "antd";
import { useState } from "react";
import { CREATE_SUBCATEGORY_GROUP } from "../../graphql/modules";
import { successNotify } from "../../util";

export const CreateGroup = ({ categoryId, refetch }) => {
  const [visible, setVisible] = useState(false);
  const [name, setName] = useState("");
  const handleCancel = () => {
    setVisible(false);
    setName("");
  };

  const [CreateGroupMutation, { loading }] = useMutation(
    CREATE_SUBCATEGORY_GROUP
  );
  const handleSubmit = async () => {
    try {
      const {
        data: { AddSubcategoryGroup },
      } = await CreateGroupMutation({
        variables: {
          categoryId,
          name,
        },
      });
      if (AddSubcategoryGroup.success) {
        successNotify(AddSubcategoryGroup.message);
        refetch();
        handleCancel();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div>
      <button
        className="btn-common"
        style={{ fontSize: "12px" }}
        type="primary"
        onClick={() => setVisible(true)}
      >
        <i className="fas fa-plus-circle mr-2"></i> Create Group
      </button>
      <Modal
        title="Create group"
        open={visible}
        onCancel={handleCancel}
        okText="Create"
        onOk={handleSubmit}
        okButtonProps={{
          disabled: loading || !name,
          loading,
        }}
      >
        <div className="mb-2">Group Name</div>
        <Input
          size="large"
          placeholder="group name"
          onChange={(e) => setName(e.target.value)}
          value={name}
        />
      </Modal>
    </div>
  );
};
