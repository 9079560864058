import React, { useEffect, useState } from "react";
import { DynamicList } from "../components/Shared";
import { useQuery } from "@apollo/react-hooks";
import { FETCH_ALL_ORDER } from "../graphql/modules";
import moment from "moment";
import { OrderStatus } from "../config/constants";
import { Button, Input, Pagination, Select, Tag } from "antd";
import { EyeFilled } from "@ant-design/icons";
import { Link, useLocation, useHistory } from "react-router-dom";

export const Orders = ({ ...props }) => {
  const [limit] = useState(20);
  const [page, setPage] = useState(1);
  const [searchFilter, setSearchFilter] = useState({
    email: "",
    orderId: "",
    status: "",
    code: "",
    productName: "",
    customerName: "",
  });
  const [inputData, setInputData] = useState({
    email: "",
    orderId: "",
    status: "",
    code: "",
    customerName: "",
    productName: "",
  });

  const location = useLocation();
  const history = useHistory();

  // Function to update URL with search filters
  const updateURL = (filters) => {
    const searchParams = new URLSearchParams();
    for (const key in filters) {
      if (filters[key]) searchParams.set(key, filters[key]);
    }
    searchParams.set("page", filters.page);
    history.push({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  // fetch order
  const { data, loading } = useQuery(FETCH_ALL_ORDER, {
    variables: {
      limit,
      offset: page - 1,
      ...searchFilter,
    },
    // fetchPolicy:"network-only"
  });

  // page handle
  // const search = useLocation().search;
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const pageNumber = params.get("page") ? Number(params.get("page")) : 1;
    setPage(pageNumber);
    const filters = {
      email: params.get("email") || "",
      orderId: params.get("orderId") || "",
      status: params.get("status") || "",
      code: params.get("code") || "",
      productName: params.get("productName") || "",
      customerName: params.get("customerName") || "",
    };
    setSearchFilter(filters);
    setInputData(filters);
  }, [location.search]);


  const onChangePagination = (p) => {
    setPage(p);
    updateURL({ ...searchFilter, page: p });
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setInputData({ ...inputData, [name]: value });
  };

  const handleStatus = (value) => {
    setInputData({ ...inputData, status: value });
  };

  const onSubmitSearch = () => {
    setPage(1);
    setSearchFilter({ ...searchFilter, ...inputData });
    updateURL({ ...inputData, page: 1 });
  };

  // reset search data
  const onReset = () => {
    setPage(1);
    setSearchFilter({
      email: "",
      orderId: "",
      status: "",
      code: "",
      productName: "",
      customerName: "",
    });
    setInputData({
      email: "",
      orderId: "",
      status: "",
      code: "",
      productName: "",
      customerName: "",
    });
    updateURL({
      email: "",
      orderId: "",
      status: "",
      code: "",
      productName: "",
      customerName: "",
      page: 1,
    });
  };

  const allorderList = data?.FetchAllOrderAdmin?.result || [];
  const total = data?.FetchAllOrderAdmin?.count || 0;

  const columns = [
    {
      title: "Order Id",
      dataIndex: "orderId",
      key: "orderId",
    },
    {
      title: "Order Date",
      render: (text, record) =>
        moment(Number(record.createdAt)).format("MM/DD/YYYY T hh:MM A"),
    },
    {
      title: "Email",
      render: (text, record) =>
        record?.user?.email?.toLowerCase() || record?.email?.toLowerCase(),
    },
    {
      title: "Price",
      render: (_, record) => (
        <p style={{ margin: 0 }}>
          {parseFloat(record?.grandTotal || 0.0).toFixed(2)} USD
        </p>
      ),
    },
    {
      title: "Installment Pay",
      render: (_, record) => (
        <p style={{ margin: 0 }}>
          {record?.cardType === "afterpay" ? (
            <Tag color="green">Yes</Tag>
          ) : (
            "N/A"
          )}
        </p>
      ),
    },
    {
      title: "Status",
      render: (_, record) => getStatus(record.status),
    },
    {
      title: "Refund",
      render: (_, record) =>
        record.isRefunded ? <Tag color="green">Yes</Tag> : "N/A",
    },
    {
      title: "Coupon Used",
      render: (_, record) =>
        record?.coupon?._id ? (
          <Tag color="cyan">Yes</Tag>
        ) : (
          <Tag color="orange">No</Tag>
        ),
    },
    {
      title: "Actions",
      render: (_, record) => {
        return (
          <div>
            <Link to={`/orders/${record?._id}`}>
              <EyeFilled />
            </Link>
          </div>
        );
      },
    },
  ];

  const searchComponent = (
    <div className="row">
      <div className="col-md-3">
        <Input
          onChange={handleInput}
          name="orderId"
          value={inputData.orderId}
          size="large"
          className="mr-2 mb-3"
          placeholder="Order id"
        />
      </div>
      <div className="col-md-3">
        <Input
          onChange={handleInput}
          value={inputData.email}
          name="email"
          size="large"
          className="mr-2 mb-3"
          placeholder="Email"
        />
      </div>
      <div className="col-md-3">
        <Input
          onChange={handleInput}
          value={inputData.productName}
          name="productName"
          size="large"
          className="mr-2 mb-3"
          placeholder="Product Name"
        />
      </div>
      <div className="col-md-3">
        <Input
          onChange={handleInput}
          value={inputData.code}
          name="code"
          size="large"
          className="mr-2 mb-3"
          placeholder="Product code"
        />
      </div>
      <div className="col-md-3">
        <Select
          style={{ width: "100%" }}
          className="mr-2 mb-3"
          value={inputData.status}
          size="large"
          onChange={handleStatus}
        >
          <Select.Option value="" disabled>
            Select
          </Select.Option>
          <Select.Option value={OrderStatus.PreOrder}>
            {OrderStatus.PreOrder}
          </Select.Option>
          <Select.Option value={OrderStatus.Pending}>
            {OrderStatus.Pending}
          </Select.Option>
          <Select.Option value={OrderStatus.Shipped}>
            {OrderStatus.Shipped}
          </Select.Option>
          <Select.Option value={OrderStatus.Delivered}>
            {OrderStatus.Delivered}
          </Select.Option>
          <Select.Option value={OrderStatus.Deleted}>
            {OrderStatus.Deleted}
          </Select.Option>
        </Select>
      </div>
      <div className="col-md-3">
        <Input
          size="large"
          className="mr-2 mb-3"
          name="customerName"
          placeholder="Customer Name"
          value={inputData.customerName}
          onChange={handleInput}
        />
      </div>
      <div className="col-md-12">
        <div className="text-right">
          <Button type="primary" className="mr-2" onClick={onSubmitSearch}>
            Search
          </Button>
          <Button type="default" onClick={onReset}>
            Reset
          </Button>
        </div>
      </div>
    </div>
  );

  return (
    <DynamicList
      title="All orders"
      data={allorderList}
      columns={columns}
      loading={loading}
      tableFilter={searchComponent}
      pagination={
        <Pagination
          className="pagination"
          total={total}
          pageSize={limit}
          current={page}
          showSizeChanger={false}
          hideOnSinglePage
          onChange={onChangePagination}
        />
      }
    ></DynamicList>
  );
};

const getStatus = (status) => {
  switch (status) {
    case OrderStatus.PreOrder:
      return <Tag color="orange">{status}</Tag>;
    case OrderStatus.Pending:
      return <Tag color="orange">{status}</Tag>;
    case OrderStatus.Shipped:
      return <Tag color="green">{status}</Tag>;
    case OrderStatus.Delivered:
      return <Tag color="blue">{status}</Tag>;
    case OrderStatus.Canceled:
      return <Tag color="red">{status}</Tag>;
    case OrderStatus.Deleted:
      return <Tag color="red">{status}</Tag>;
    case OrderStatus.Partial:
      return <Tag color="green">{status}</Tag>;
    default:
      break;
  }
};
