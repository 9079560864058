import gql from "graphql-tag";

export const UPDATE_COUPON_VELIDATION = gql`
  mutation UpdateCouponAdmin(
    $dateMilis: String
    $couponId: ID
    $discountInPercent: Float
  ) {
    UpdateCouponAdmin(
      dateMilis: $dateMilis
      couponId: $couponId
      discountInPercent: $discountInPercent
    ) {
      code
      success
      message
    }
  }
`;

export const UPDATE_COUPON_STATUS = gql`
  mutation UpdateCoupneStatus(
    $status: CouponStatus
    $couponId: ID
    $evidenceLink: String
    $desc: String
    $cancellationNote: String
  ) {
    UpdateCoupneStatus(
      status: $status
      couponId: $couponId
      evidenceLink: $evidenceLink
      desc: $desc
      cancellationNote: $cancellationNote
    ) {
      code
      success
      message
    }
  }
`;

export const FETCH_COLLABORATORS = gql`
  query FetchAllAdminOrCollaborators(
    $limit: Int
    $offset: Int
    $email: String
    $role: [AdminRole]
    $instagramProfile: String
  ) {
    FetchAllAdminOrCollaborators(
      limit: $limit
      offset: $offset
      email: $email
      role: $role
      instagramProfile: $instagramProfile
    ) {
      count
      result {
        _id
        firstname
        lastname
        email
        instagramProfile
        createdAt
        status
        role
      }
    }
  }
`;
export const FETCH_SINGLE_COLLABORATOR = gql`
  query FetchSingleCollaboratorAdmin(
    $limit: Int
    $offset: Int
    $status: CouponStatus
    $code: String
    $userId: ID
  ) {
    FetchSingleCollaboratorAdmin(
      limit: $limit
      offset: $offset
      status: $status
      code: $code
      userId: $userId
    ) {
      count
      coupons {
        _id
        code
        usedCount
        validUntil
        status
        discountInPercent
        cancellationNote
        createdAt
        evidenceLink
        desc
        moneyEarned
      }
      collaborator {
        _id
        firstname
        lastname
        email
        instagramProfile
        createdAt
        moneyEarned
      }
    }
  }
`;
