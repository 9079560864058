import gql from "graphql-tag";

export const DELETE_ADMIN = gql`
  mutation DeleteAdmin($id: ID) {
    DeleteAdmin(id: $id) {
      code
      success
      message
    }
  }
`;

export const UPDATE_ROLE = gql`
  mutation UpdateAdminRole($id: ID, $role: AdminRole) {
    UpdateAdminRole(id: $id, role: $role) {
      code
      success
      message
    }
  }
`;
