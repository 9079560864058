import { Empty } from "antd";
import React from "react";
import { getFile } from "../../util";
import { catSubType } from "../../config";

const SingleSubcategory = ({
  categoryName,
  name,
  products,
  onSelectToggle,
  isSelected,
  newProcuts,
  sizeType
}) => {
  return (
    <div>
      <h2 className="category">{`${categoryName} / ${name}`}</h2>
      <div className="product-wrapper">
        {!products.length && (
          <Empty
            description="No product has been added to this subcategory."
            image="https://virtualshipment.in/images/no-data.png"
            className="w-100 empty-text"
          />
        )}
        {products.map(({colors,...item}) => {
          const idx = isSelected(item);
          const gallery = item?.isAccessories ? item?.gallery[0]: sizeType === catSubType.fullSet ?colors[0]?.gallery[0]: colors[0][sizeType?.toLowerCase()+'Gallery'][0];
          return (
            <div
              key={item._id}
              className={`single-product position-relative `}
              onClick={() => onSelectToggle({...item, sizeType, gallery})}
            >
              {idx && <div className="product-count">{idx}</div>}
              <img src={getFile(gallery)} alt="product-image" />
              <p className="product-name text-center">{item.name}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SingleSubcategory;
