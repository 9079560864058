import React, { useEffect, useRef } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Card, Col, Empty, Input, Modal, Popconfirm, Row, Spin } from "antd";
import { getFile, notify, successNotify, warnNotify } from "../../util";
import {
  ADD_CUSTOMER_PICK,
  DELETE_CUSTOMER_PICK,
  EDIT_CUSTOMER_PICK,
  FETCH_CUSTOMER_PICK,
  FILE_UPLOAD,
  UPDATE_CUSTOMR_PICK_TITLE,
} from "../../graphql/modules";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useState } from "react";

const CustomerPicks = () => {
  const [link, setLink] = useState("");
  const [pickLink, setPickLink] = useState("");
  const [userName, setUserName] = useState("");
  const [visible, setVisible] = useState("");
  const [bannerId, setBannerId] = useState("");

  const fileInputRef = useRef(null);
  // fetch banner
  const { data, refetch, loading } = useQuery(FETCH_CUSTOMER_PICK);
  const customerPickList = data?.FetchCustomerPicks?.result || [];
  const [title, setTitle] = useState(data?.FetchCustomerPicks?.sectionTitle);

  useEffect(()=>{
    setTitle(data?.FetchCustomerPicks?.sectionTitle)
  },[data])

  // video set into home page api
  const [AddBannerMutation, { loading: bannerUploading }] =
    useMutation(ADD_CUSTOMER_PICK);

  const uploadBanner = async () => {
    try {
      const {
        data: { AddCustomerPicks },
      } = await AddBannerMutation({
        variables: {
          link,
          userName,
          pickLink,
        },
      });
      if (AddCustomerPicks.success) {
        onCloseModal();
        successNotify(AddCustomerPicks.message);
        refetch();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const [UpdateCustomerSectionTitle, { loading: pickTitleLoading }] =
    useMutation(UPDATE_CUSTOMR_PICK_TITLE);

  const updatePickTitle = async () => {
    try {
      const {
        data: { UpdateCustomerPicksTitle },
      } = await UpdateCustomerSectionTitle({
        variables: { title },
      });
      if (UpdateCustomerPicksTitle.success) {
        successNotify(UpdateCustomerPicksTitle.message);
        refetch();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // update banner
  const [EditBannerMutation, { loading: updateBannerLoading }] =
    useMutation(EDIT_CUSTOMER_PICK);
  const editBanner = async () => {
    try {
      const {
        data: { EditCustomerPicks },
      } = await EditBannerMutation({
        variables: {
          id: bannerId,
          link,
          userName,
          pickLink,
        },
      });
      if (EditCustomerPicks.success) {
        onCloseModal();
        successNotify(EditCustomerPicks.message);
        refetch();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // delete banner
  const [DeleteBannerMutation, { loading: deleteLoading }] =
    useMutation(DELETE_CUSTOMER_PICK);
  const deleteBanner = async (id) => {
    try {
      const {
        data: { DeleteCustomerPicks },
      } = await DeleteBannerMutation({
        variables: {
          id,
        },
      });
      if (DeleteCustomerPicks.success) {
        notify(DeleteCustomerPicks);
        refetch();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // file upload to aws
  const [FileUploadMutaion, { loading: uploadLoading }] =
    useMutation(FILE_UPLOAD);
  const uploadFile = async (e) => {
    const file = e.target.files[0];
    try {
      const {
        data: { SingleUpload },
      } = await FileUploadMutaion({
        variables: {
          file,
        },
      });
      // console.log(SingleUpload)
      if (SingleUpload.success) {
        // let type = file.type.includes("video") ? "video" : "image";
        setLink(SingleUpload.filename);
        // setFileType(type);
        // uploadBanner(SingleUpload.filename, type);
      } else {
        warnNotify(SingleUpload.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleEditBanner = ({
    _id,
    link,
    // anchorLink,
    // type,
    userName,
    pickLink,
  }) => {
    setPickLink(pickLink || "");
    setLink(link);
    // setFileType(type);
    setUserName(userName);
    setBannerId(_id);
    setVisible(true);
  };

  const onCloseModal = () => {
    setVisible(false);
    setLink("");
    setPickLink("");
    setUserName("");
    setBannerId("");
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  return (
    <Spin spinning={loading || deleteLoading}>
      <div className="landing-video">
        <div className="mb-5">
          <div>
            <label>Title</label>
          </div>
          <Input
            size="large"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <div className="mt-3 text-right">
            <button
              className="btn-common"
              type="primary"
              onClick={updatePickTitle}
              disabled={!title}
            >
              Update Title
            </button>
          </div>
        </div>
        {customerPickList?.length < 1 && <Empty />}

        <Row gutter={[16, 16]}>
          {customerPickList.map((bnr, index) => (
            <Col lg={6} key={bnr._id}>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  marginBottom: "40px",
                }}
              >
                <Card
                  bodyStyle={{ padding: 0 }}
                  style={
                    {
                      // width: 300,
                    }
                  }
                  hoverable
                  cover={
                    <>
                      <img width="100%" src={getFile(bnr?.link)} alt="" />
                      <p
                        className="mt-2 pl-2 font-weight-bold"
                        style={{ color: "#e762e7", fontSize: "15px" }}
                      >
                        {bnr?.userName}
                      </p>
                    </>
                    // )
                  }
                  actions={[
                    <EditOutlined
                      key="pencil"
                      onClick={() => handleEditBanner(bnr)}
                    />,
                    <Popconfirm
                      title="Are you sure?"
                      onConfirm={() => deleteBanner(bnr?._id)}
                      okButtonProps={{ danger: "true" }}
                    >
                      <DeleteOutlined key="ellipsis" />
                    </Popconfirm>,
                  ]}
                />
              </div>
            </Col>
          ))}
        </Row>

        <Modal
          title={bannerId ? "Update Customer Pick" : "Add Customer Pick"}
          open={visible}
          okText={bannerId ? "Update" : "Add"}
          onCancel={onCloseModal}
          onOk={bannerId ? editBanner : uploadBanner}
          okButtonProps={{
            disabled: !link,
            loading: bannerUploading || updateBannerLoading,
          }}
        >
          <div className="mb-4">
            <div className="mb-2">File *</div>
            <Spin spinning={uploadLoading}>
              <div className="mb-2">
                {/* {fileType === "video" ? (
                  <video
                    width="100%"
                    height="100%"
                    autoPlay
                    loop
                    style={{ objectFit: "fill" }}
                    muted
                  >
                    <source src={getFile(link)} />
                  </video>
                ) : ( */}
                <img width="100%" src={getFile(link)} alt="" />
                {/* )} */}
              </div>
            </Spin>
            <input
              type="file"
              accept="video/*,image/*"
              onChange={uploadFile}
              id="video-upload"
              disabled={uploadLoading}
              ref={fileInputRef}
            />
          </div>
          <div className="mb-2">
            <div className="mb-2">Username</div>
            <Input
              size="large"
              placeholder="Enter username"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
            />
          </div>
          <div className="mb-2">
            <div className="mb-2">Pick URL</div>
            <Input
              size="large"
              placeholder="Enter pick url"
              value={pickLink}
              onChange={(e) => setPickLink(e.target.value)}
            />
          </div>
        </Modal>

        <div className="text-right mt-4">
          <button
            className="btn-common"
            type="primary"
            onClick={() => setVisible(true)}
          >
            <i className="fas fa-plus-circle mr-2"></i> Add Customer Pick
          </button>
        </div>
      </div>
    </Spin>
  );
};

export default CustomerPicks;
