import gql from "graphql-tag";

export const ADMIN_LOGIN = gql`
  mutation AdminLogin($email: String!, $password: String!, $role: AdminRole) {
    AdminLogin(email: $email, password: $password, role: $role) {
      code
      success
      token
      message
      user {
        firstname
        lastname
        email
        role
      }
    }
  }
`;

export const VERIFFICATION_CODE = gql`
  mutation Verify2FCode($code: String!) {
    Verify2FCode(code: $code) {
      code
      success
      token
      message
      user {
        firstname
        lastname
        email
        _id
        role
      }
    }
  }
`;

export const ADMIN_SIGNUP = gql`
  mutation AdminRegister($userInput: AdminRegistrationInput) {
    AdminRegister(userInput: $userInput) {
      code
      success
      message
    }
  }
`;

export const ADMIN_FORGOT_PASSWORD = gql`
  mutation AdminForgetPassword($email: String!) {
    AdminForgetPassword(email: $email) {
      code
      success
      message
    }
  }
`;

export const ADMIN_LOGOUT = gql`
  mutation Logout($code: String) {
    Logout(code: $code) {
      code
      message
      success
    }
  }
`;

export const GET_VERIFICATION_LINK = gql`
  mutation userGetVerificationLink($email: String) {
    userGetVerificationLink(email: $email) {
      msg
      code
    }
  }
`;

export const ADMIN_RESET_PASSWORD = gql`
  mutation AdminResetPassowrd($newPassword: String!, $securityCode: String!) {
    AdminResetPassowrd(newPassword: $newPassword, securityCode: $securityCode) {
      code
      success
      message
    }
  }
`;

export const USER_VERIFY_EMAIL = gql`
  mutation verifyEmail($securityCode: String!) {
    VerifyEmail(securityCode: $securityCode) {
      code
      success
      message
    }
  }
`;

export const CONFIRM_EMAIL_CHANGE = gql`
  mutation ConfirmChangeEmailAdmin($securityCode: String!) {
    ConfirmChangeEmailAdmin(securityCode: $securityCode) {
      code
      success
      message
    }
  }
`;

export const UPDATE_PROFILE = gql`
  mutation AdminProfileUpdate($profileData: AdminProfileUpdateInput) {
    AdminProfileUpdate(profileData: $profileData) {
      code
      success
      message
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation ($oldPassword: String!, $newPassword: String!) {
    AdminPassowrdUpdate(oldPassword: $oldPassword, newPassword: $newPassword) {
      code
      message
      success
    }
  }
`;

export const CHANGE_EMAIL = gql`
  mutation ChangeEmailAdmin($newEmail: String, $password: String) {
    ChangeEmailAdmin(newEmail: $newEmail, password: $password) {
      code
      message
      success
    }
  }
`;

export const FETCH_USER_BY_ID_ADMIN = gql`
  query FetchUserByIdAdmin($id: ID) {
    FetchUserByIdAdmin(id: $id) {
      user {
        _id
        role
        firstname
        lastname
        email
      }
    }
  }
`;

// export const REGISTER_USER = gql`
//   mutation Register($userInput: RegistrationInput) {
//     Register(userInput: $userInput) {
//       code
//       message
//       success
//     }
//   }
// `;
