import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { AuthLayout } from "../containers";
import { userRole } from "../config";

// auth component
export const AuthRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated, user } = useSelector((state) => state?.auth);
  return isAuthenticated ? (
    <Redirect to={getRouteBasedOnRole(user?.role)} />
  ) : (
    <Route
      {...rest}
      component={(props) => (
        <AuthLayout>
          <Component {...props} />
        </AuthLayout>
      )}
    />
  );
};

// authenticated route
export const PrivateRoute = ({
  component: Component,
  role: Role = [],
  ...rest
}) => {
  const {isAuthenticated, user} = useSelector((state) => state?.auth);
  return isAuthenticated &&
    (Role.includes(user?.role)) ? (
    <Route {...rest} component={(props) => <Component {...props} />} />
  ) : (
    <Redirect to="/" from={rest.path}/>
  );
};

// public route
// export const PublicRoute = ({ component: Component, ...rest }) => {
//   const isAuthenticated = useSelector((state) => state?.auth?.isAuthenticated);
//   return isAuthenticated ? (
//     <Redirect to="/customers" />
//   ) : (
//     <Route
//       {...rest}
//       component={(props) => (
//           <Component {...props} />
//       )}
//     />
//   );
// };

const getRouteBasedOnRole = (role) => {
  switch (role) {
    case userRole.superAdmin:
      return "/admin";
    case userRole.Admin:
      return "/admin";
    case userRole.Moderator:
      return "/collaborators";
    default:
      return "/my-profile"
  }
};