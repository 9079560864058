import React from "react";
// import { Collapse } from "antd";

// const {Panel} = Collapse
const Details = ({ answerText }) => {
  // handle modal

  return (
    <div className="col-12">
      <div
        dangerouslySetInnerHTML={{
          __html: answerText,
        }}
      />
    </div>
  );
};

export default Details;
