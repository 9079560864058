import { CheckOutlined } from "@ant-design/icons";
import { Button, Col, Input, Row, Select, List, Checkbox } from "antd";
import React, { Fragment, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { FETCH_SIZING } from "../../graphql/modules";
import SizeList from "./SizeList";
import { useQuery } from "@apollo/react-hooks";
import { sortOfSize } from "../../util";
import { catSubType } from "../../config";

const Sizes = ({
  productData,
  handleSizeTop,
  handleSizeBottom,
  setProductData,
  productId,
  sizeType
}) => {
  const { data } = useQuery(FETCH_SIZING, {
    variables: {
      type: "topSizing",
    },
  });
  const sizes = data?.FetchSizing?.result || [];
  const { data: bottomSize } = useQuery(FETCH_SIZING, {
    variables: {
      type: "bottomSizing",
    },
  });
  const sizesBottom = bottomSize?.FetchSizing?.result || [];
  const [qtcTop, setQtcTop] = useState("");
  const [qtc, setQtcUpdate] = useState({
    top: "",
    bottom: "",
  });
  const [qtcBottom, setQtcBottom] = useState("");
  const [sizeIdTop, setSizeIdTop] = useState("");
  const [sizeIdBottom, setSizeIdBottom] = useState("");
  const [sizeTop, setSizeTop] = useState([]);
  const [sizeTopStore, setSizeTopStore] = useState([
    ...productData?.topSizing?.filter((st) => st.quantity > 0),
    ...productData?.topSizing?.filter((st) => st.quantity < 1),
  ]);

  const [sizeBottomStore, setSizeBottomStore] = useState([
    ...productData?.bottomSizing?.filter((st) => st.quantity > 0),
    ...productData?.bottomSizing.filter((st) => st.quantity < 1),
  ]);
  const [sizeBottom, setSizeBottom] = useState([]);
  // top state and function section
  const handleQtcForTop = (e) => {
    setQtcTop(e.target.value);
  };
  const handleSizeForTopState = (value) => {
    setSizeTop(value);
  };

  const createFormatSizeList = (newData) =>
    newData.map((item) => ({
      quantity: item.quantity,
      size: item?.size?._id,
    }));

  const addTopSizeStore = (type) => {
    const newData = [...sizeTopStore];
    if (type === "all") {
      for (let i = 0; sizes?.length > i; i++) {
        const fdx = newData.findIndex(
          (itm) => itm?.size?.name === sizes[i]?.name
        );
        if (fdx >= 0) {
          newData[fdx] = { quantity: Number(qtcTop), size: sizes[i] };
        } else {
          newData.push({ quantity: Number(qtcTop), size: sizes[i] });
        }
      }
    } else {
      for (let i = 0; sizeTop?.length > i; i++) {
        newData.push({
          quantity: Number(qtcTop),
          size: JSON.parse(sizeTop[i]),
        });
      }
    }

    // newData.push({ quantity: Number(qtcTop), size: sizeTop });
    setSizeTopStore(sortOfSize(newData, "TOP"));
    handleSizeTop(createFormatSizeList(newData));
    setQtcTop("");
    setSizeTop([]);
  };

  // delete sizes
  const deleteTopSizeFromStore = (id) => {
    const prev = [...sizeTopStore];
    const newData = prev.filter((item) => item?.size?._id !== id);
    setSizeTopStore(newData);
    handleSizeTop(createFormatSizeList(newData));
  };
  const editTopSizingFromStore = () => {
    const newData = [...sizeTopStore];
    sizeTopStore.map((item, index) => {
      if (item?.size?._id === sizeIdTop) {
        newData[index].quantity = Number(qtc.top);
      }
    });
    setSizeTopStore([...newData]);
    handleSizeTop(createFormatSizeList(newData));
    setQtcUpdate({ ...qtc, top: "" });
    setSizeIdTop("");
  };
  const handleQtcEditTop = (id, quan) => {
    setSizeIdTop(id);
    setQtcUpdate({ ...qtc, top: quan });
  };

  // bottom state section and function
  const handleQtcForBottom = (e) => {
    setQtcBottom(e.target.value);
  };
  const handleSizeForBottomState = (value) => {
    setSizeBottom(value);
  };

  const addBottomSizeStore = (type) => {
    const newData = [...sizeBottomStore];

    if (type === "all") {
      for (let i = 0; sizesBottom?.length > i; i++) {
        const fdx = newData.findIndex(
          (itm) => itm?.size?.name === sizesBottom[i]?.name
        );
        if (fdx >= 0) {
          newData[fdx] = { quantity: Number(qtcBottom), size: sizesBottom[i] };
        } else {
          newData.push({ quantity: Number(qtcBottom), size: sizesBottom[i] });
        }
      }
    } else {
      for (let i = 0; sizeBottom?.length > i; i++) {
        newData.push({
          quantity: Number(qtcBottom),
          size: JSON.parse(sizeBottom[i]),
        });
      }
    }
    setSizeBottomStore(sortOfSize(newData, "BOTTOM"));
    handleSizeBottom(createFormatSizeList(newData));
    setQtcBottom("");
    setSizeBottom([]);
  };

  const deleteBottomSizeFromStore = (id) => {
    const prev = [...sizeBottomStore];
    const newData = prev.filter((item) => item?.size?._id !== id);
    setSizeBottomStore(newData);
    handleSizeBottom(createFormatSizeList(newData));
  };
  const handleQtcEditBottom = (id, quan) => {
    setSizeIdBottom(id);
    setQtcUpdate({ ...qtc, bottom: quan });
  };

  const editBottomSizingFromStore = () => {
    const newData = [...sizeBottomStore];
    sizeBottomStore.map((item, index) => {
      if (item?.size?._id === sizeIdBottom) {
        newData[index].quantity = Number(qtc.bottom);
      }
    });
    setSizeBottomStore([...newData]);
    handleSizeBottom(createFormatSizeList(newData));
    setQtcUpdate({ ...qtc, bottom: "" });
    setSizeIdBottom("");
  };

  const handleChangequantity = (name, value) => {
    setQtcUpdate({ ...qtc, [name]: value });
  };
  const onBottomSizeDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const { source, destination } = result;
    const [removed] = sizeBottomStore.splice(source.index, 1);
    sizeBottomStore.splice(destination.index, 0, removed);
    setSizeBottomStore([...sizeBottomStore]);
    handleSizeBottom(sizeBottomStore);
  };
  const onTopSizeDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const { source, destination } = result;
    const [removed] = sizeTopStore.splice(source.index, 1);
    sizeTopStore.splice(destination.index, 0, removed);
    setSizeTopStore([...sizeTopStore]);
    handleSizeTop(sizeTopStore);
  };
  return (
    <Fragment>
      <Row>
        <div className="mb-4">
          {sizeType === catSubType.fullSet && (
            <h6 style={{ color: "grey" }}>
              <span className="mr-3">This product is accessories?</span>{" "}
              <Checkbox
                onChange={(e) =>
                  setProductData({
                    ...productData,
                    isAccessories: e.target.checked,
                  })
                }
                checked={productData.isAccessories}
                disabled={productId}
              />
            </h6>
          )}
        </div>
      </Row>
      {productData.isAccessories ? (
        <Row>
          <div style={{ width: "300px" }}>
            <Input
              size="large"
              placeholder="Quantity"
              type="number"
              value={String(productData?.quantity)}
              onChange={(e) =>
                setProductData({ ...productData, quantity: e.target.value })
              }
            />
          </div>
        </Row>
      ) : (
        <Row gutter={[16, 16]} className="mb-4">
          <Col lg={12}>
            <div>
              <label>Top Size</label>
            </div>
            <div className="d-flex align-items-baseline ">
              <div style={{ width: "100%" }}>
                <Select
                  size="large"
                  mode="multiple"
                  placeholder="Please select"
                  style={{
                    width: "100%",
                    marginRight: "12px",
                  }}
                  onChange={handleSizeForTopState}
                  value={sizeTop}
                >
                  <Select.Option disabled value="">
                    Size
                  </Select.Option>
                  {sizes?.map((item) => (
                    <Select.Option
                      disabled={checkAlreadyDeclered(item?.name, sizeTopStore)}
                      key={item._id}
                      value={JSON.stringify(item)}
                    >
                      <div className="d-flex justify-content-between align-items-center">
                        {item.name}
                        {checkAlreadyDeclered(item.name, sizeTopStore) && (
                          <CheckOutlined style={{ color: "green" }} />
                        )}
                      </div>
                    </Select.Option>
                  ))}
                </Select>
                <div>
                  <Button
                    className="mt-2 mr-3"
                    type="primary"
                    disabled={!qtcTop || sizeTop?.length > 0}
                    onClick={() => addTopSizeStore("all")}
                  >
                    All size select
                  </Button>
                  <Button
                    className="mt-2"
                    type="primary"
                    disabled={sizeTop?.length > 0}
                    onClick={() => {
                      setSizeTopStore([]);
                      handleSizeTop(createFormatSizeList([]));
                    }}
                  >
                    Delete All Sizes
                  </Button>
                </div>
              </div>
              <Input
                size="large"
                type="number"
                placeholder="Qty"
                value={qtcTop}
                onChange={handleQtcForTop}
                style={{ width: "100px", marginRight: "10px" }}
              />
              <Button
                disabled={!qtcTop || sizeTop.length === 0}
                type="primary"
                size="large"
                onClick={addTopSizeStore}
              >
                Add
              </Button>
            </div>
            <div style={{ width: "100%", marginTop: "30px" }}>
              <DragDropContext onDragEnd={onTopSizeDragEnd}>
                <Droppable droppableId="droppable3">
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                    >
                      <List
                        dataSource={[...sizeTopStore]}
                        bordered
                        header={
                          <div className="d-flex justify-content-between">
                            <b>Size Name</b>
                            <b>Quantity</b>
                            <b>Action</b>
                          </div>
                        }
                        renderItem={(item, index) => (
                          <Draggable
                            key={item?.size?._id}
                            draggableId={item?.size?._id}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                key={item?.size?._id}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                              >
                                <SizeList
                                  item={item}
                                  sizeId={sizeIdTop}
                                  name="top"
                                  handleChangequantity={handleChangequantity}
                                  setSizeId={setSizeIdTop}
                                  qtcUpdate={qtc.top}
                                  setQtcUpdate={setQtcUpdate}
                                  editSizingFromStore={editTopSizingFromStore}
                                  deleteSizeFromStore={deleteTopSizeFromStore}
                                  handleQtcEdit={handleQtcEditTop}
                                />
                              </div>
                            )}
                          </Draggable>
                        )}
                      />
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </Col>
          <Col lg={12}>
            <div>
              <label>Bottom Size</label>
            </div>
            <div className="size-selection d-flex align-items-baseline">
              <div style={{ width: "100%" }}>
                <Select
                  size="large"
                  mode="multiple"
                  placeholder="Please select"
                  style={{
                    width: "100%",
                    marginRight: "10px",
                  }}
                  onChange={handleSizeForBottomState}
                  value={sizeBottom}
                >
                  <Select.Option disabled value="">
                    Size
                  </Select.Option>
                  {sizesBottom?.map((item) => (
                    <Select.Option
                      disabled={checkAlreadyDeclered(
                        item.name,
                        sizeBottomStore
                      )}
                      key={item._id}
                      value={JSON.stringify(item)}
                    >
                      <div className="d-flex justify-content-between align-items-center">
                        {item.name}
                        {checkAlreadyDeclered(item.name, sizeBottomStore) && (
                          <CheckOutlined style={{ color: "green" }} />
                        )}
                      </div>
                    </Select.Option>
                  ))}
                </Select>
                <div className="mt-2">
                  <Button
                    disabled={!qtcBottom || sizeBottom?.length > 0}
                    onClick={() => addBottomSizeStore("all")}
                    type="primary"
                  >
                    All Size Select
                  </Button>
                  <Button
                    className="ml-3"
                    type="primary"
                    disabled={sizeBottom?.length > 0}
                    onClick={() => {
                      setSizeBottomStore([]);
                      handleSizeBottom(createFormatSizeList([]));
                    }}
                  >
                    Delete All Sizes
                  </Button>
                </div>
              </div>
              <Input
                size="large"
                type="number"
                width="150px"
                placeholder="Qty"
                value={qtcBottom}
                onChange={handleQtcForBottom}
                style={{ width: "100px", marginRight: "10px" }}
              />
              <Button
                disabled={!qtcBottom || sizeBottom?.length === 0}
                onClick={addBottomSizeStore}
                type="primary"
                size="large"
              >
                Add
              </Button>
            </div>
            <div style={{ width: "100%", marginTop: "30px" }}>
              <DragDropContext onDragEnd={onBottomSizeDragEnd}>
                <Droppable droppableId="droppable3">
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                    >
                      <List
                        header={
                          <div className="d-flex justify-content-between">
                            <b>Size Name</b>
                            <b>Quantity</b>
                            <b>Action</b>
                          </div>
                        }
                        bordered
                        dataSource={[...sizeBottomStore]}
                        renderItem={(item, index) => (
                          <Draggable
                            key={item?.size?._id}
                            draggableId={item?.size?._id}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                // key={item?.size?._id}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                              >
                                <SizeList
                                  // key={item?.size?._id}
                                  item={item}
                                  sizeId={sizeIdBottom}
                                  setSizeId={setSizeIdBottom}
                                  qtcUpdate={qtc.bottom}
                                  name="bottom"
                                  handleChangequantity={handleChangequantity}
                                  setQtcUpdate={setQtcUpdate}
                                  editSizingFromStore={
                                    editBottomSizingFromStore
                                  }
                                  deleteSizeFromStore={
                                    deleteBottomSizeFromStore
                                  }
                                  handleQtcEdit={handleQtcEditBottom}
                                />
                              </div>
                            )}
                          </Draggable>
                        )}
                      />
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </Col>
        </Row>
      )}
    </Fragment>
  );
};

export default Sizes;

const checkAlreadyDeclered = (sizeName, store = []) => {
  let flag = store.findIndex((item) => item?.size?.name === sizeName);
  return flag >= 0;
};

const getListStyle = (isDraggingOver) => ({
  // background: isDraggingOver ? "lightblue" : "lightgrey",
  // padding: grid,
  // width: 250,
});

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  // padding: grid * 2,
  // margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  // background: isDragging ? "lightgreen" : "grey",

  // styles we need to apply on draggables
  ...draggableStyle,
});
