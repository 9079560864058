import { UploadOutlined } from "@ant-design/icons";
import { Col, Row, Switch, Upload } from "antd";
import React from "react";
import ImgCrop from "antd-img-crop";
// import SingleCrop from "./SingleCrop";
import { useState } from "react";
import { GalleryDnd } from "./Gallery/GalleryDnd";
import { StyleImg } from "./Gallery/StyleImg";



const Gallery = ({
  fileList = [],
  onRemove,
  handleUploadGallery,
  handleUplaodGalleryMultiple,
  isAccessories,
  styles,
  setStyles,
}) => {


  const [galleryImageMode, setGalleryImageMode] = useState(true);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const { source, destination } = result;
    const [removed] = fileList.splice(source.index, 1);
    fileList.splice(destination.index, 0, removed);
  };


  return (
    <Row gutter={[16, 16]}>
      {isAccessories && (
        <Col lg={12}>
          <div className="mb-5">
            <h5>Styles & Color</h5>
            <StyleImg styles={styles} setStyles={setStyles} />
          </div>
        </Col>
      )}

      <Col lg={24}>
        <div>
          <h5>Gallery</h5>
          <small>
            Switch to {galleryImageMode ? "crop" : "multiple"} image uplaod
          </small>
          <Switch
            style={{ marginLeft: "5px" }}
            size="small"
            checked={galleryImageMode}
            onClick={(check) => setGalleryImageMode(check)}
          />
        </div>
        <div className="d-flex" style={{ marginTop: "10px" }}>
          <GalleryDnd
            onDragEnd={onDragEnd}
            onRemove={onRemove}
            data={fileList}
          />

          {fileList?.length < 4 &&
            (!galleryImageMode ? (
              <ImgCrop
                rotationslider={true}
                grid
                quality={1}
                onModalOk={(data) => handleUploadGallery(data)}
                aspect={5 / 7}
              >
                <Upload
                  showUploadList={false}
                  style={{
                    border: "1px dotted #fcfc",
                    padding: "41px 25px",
                    marginTop: "10px",
                  }}
                  fileList={fileList}
                >
                  <UploadOutlined /> Upload
                </Upload>
              </ImgCrop>
            ) : (
              <div className="d-inline-block">
                <label
                  style={{
                    padding: "60px 22px",
                      aspectRatio:"5/7",
                      border: "1px dotted black",
                  }}
                  htmlFor="upload-gallery"
                >
                  + Upload
                </label>
                <input
                  type="file"
                  onChange={handleUplaodGalleryMultiple}
                  className="d-none"
                  id="upload-gallery"
                  multiple
                  accept="image/*"
                />
              </div>
            ))}
        </div>
      </Col>
    </Row>
  );
};

export default Gallery;
