import gql from "graphql-tag";

export const INVITE_COLLABORATOR = gql`
  mutation InviteAdminOrCollaborator($userInput: AdminRegistrationInput) {
    InviteAdminOrCollaborator(userInput: $userInput) {
      code
      success
      message
    }
  }
`;

export const UPDATE_ADMIN_STATUS = gql`
  mutation UpdateAdminStatus($id: ID, $status: UserStatus) {
    UpdateAdminStatus(id: $id, status: $status) {
      code
      success
      message
    }
  }
`;
