import { Input, Row, Button, Spin } from "antd";
import React, { useState } from "react";

export const AccessoriesInventory = ({ onSubmit, loading, product = {} }) => {
  // state declare
  const [inventoryState, setInventoryState] = useState({
    quantity: product?.quantity || 0,
  });

  // handle submit
  const handleSubmit = () => {
    const data = {
      quantity: Number(inventoryState.quantity),
    };
    onSubmit(data);
  };

  const disable = !inventoryState.quantity

  return (
    <Spin spinning={loading}>
      <Row>
        <div style={{ width: "300px" }}>
          <label>
            Quantity <small className="text-danger h6">*</small>
          </label>
          <Input
            size="large"
            placeholder="Quantity"
            type="number"
            value={inventoryState?.quantity}
            onChange={(e) =>
              setInventoryState({
                ...inventoryState,
                quantity: e.target.value,
              })
            }
          />
        </div>
      </Row>

      <div className="w-100 mt-3 text-right px-2">
        <Button
          className="px-4"
          type="primary"
          disabled={disable || loading}
          onClick={handleSubmit}
        >
          Update
        </Button>
      </div>
    </Spin>
  );
};
