import { DollarCircleOutlined } from "@ant-design/icons";
import { Col, Input } from "antd";
import React from "react";
import { catSubType } from "../../../config";

export const PriceInput = ({handleInput, sizeType, infoState}) => {

    const label = sizeType === catSubType.fullSet ? 'Price': `Price (${sizeType?.toLowerCase()})`;
    const name = sizeType === catSubType.fullSet ? 'price': sizeType?.toLowerCase()+'Price';
    const price = sizeType === catSubType.fullSet ? infoState.price: infoState[sizeType?.toLowerCase()+'Price'];
  return (
    <Col lg={8}>
      <label>{label} <small className="text-danger h6">*</small></label>
      <Input
        type="number"
        prefix={<DollarCircleOutlined />}
        size="large"
        placeholder="Price"
        onChange={handleInput}
        value={price || ""}
        name={name}
      />
    </Col>
  );
};
