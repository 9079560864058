import React, { useState, useRef, useEffect } from "react";
import { Input, Card, Popconfirm, Row, Col, Modal, Spin } from "antd";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { getFile, successNotify, warnNotify } from "../../util";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  ADD_CATEGORY_FEATURE,
  DELETE_FEATURE_CATEOGRY,
  EDIT_FEATURE_CATEGORY,
  FETCH_FEATURE_CATEOGRY,
  FILE_UPLOAD,
  UPDATE_FEATURE_SECTION_TITLE,
} from "../../graphql/modules";

export const CategorySection = () => {
  const [sectionTitle, setSectionTitle] = useState("");
  const [imageLink, setImageLink] = useState("");
  const [url, setUrl] = useState("");
  const [cardTitle, setCardTitle] = useState("");
  const [visible, setVisible] = useState(false);
  const [cardId, setCardId] = useState("");
  const fileInputRef = useRef(null);

  const { data, refetch, loading } = useQuery(FETCH_FEATURE_CATEOGRY);
  const allItem = data?.FetchFeaturedCategory?.result || [];
  useEffect(() => {
    setSectionTitle(allItem[0]?.sectionTitle);
  }, [data]);
  const [EditItemMutation, { loading: editLoading }] = useMutation(
    EDIT_FEATURE_CATEGORY
  );
  const editItem = async () => {
    try {
      const {
        data: { EditFeaturedCategory },
      } = await EditItemMutation({
        variables: {
          id: cardId,
          imageLink,
          cardTitle,
          url,
        },
      });
      if (EditFeaturedCategory?.success) {
        successNotify(EditFeaturedCategory?.message);
        refetch();
        onCloseModal(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [AddItemMutation, { loading: addLoading }] =
    useMutation(ADD_CATEGORY_FEATURE);
  const addItem = async () => {
    try {
      const {
        data: { AddFeaturedCategory },
      } = await AddItemMutation({
        variables: {
          imageLink,
          url,
          cardTitle,
        },
      });
      if (AddFeaturedCategory.success) {
        successNotify(AddFeaturedCategory.message);
        refetch();
        onCloseModal();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [DeleteItemMutation, { loading: deleteLoading }] = useMutation(
    DELETE_FEATURE_CATEOGRY
  );
  const handleDeleteItem = async (id) => {
    try {
      const {
        data: { DeleteFeaturedCategory },
      } = await DeleteItemMutation({
        variables: {
          id,
        },
      });
      if (DeleteFeaturedCategory?.success) {
        successNotify(DeleteFeaturedCategory?.message);
        refetch();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [FileUploadMutaion, { loading: uploadLoading }] =
    useMutation(FILE_UPLOAD);
  const uploadFile = async (e) => {
    const file = e.target.files[0];
    try {
      const {
        data: { SingleUpload },
      } = await FileUploadMutaion({
        variables: {
          file,
        },
      });
      if (SingleUpload.success) {
        setImageLink(SingleUpload.filename);
      } else {
        warnNotify(SingleUpload.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const [UpdateTitleMutation, { loading: titleLoading }] = useMutation(
    UPDATE_FEATURE_SECTION_TITLE
  );
  const handleUpdateTitle = async () => {
    try {
      const {
        data: { UpdateFeaturedCategoryTitle },
      } = await UpdateTitleMutation({
        variables: {
          sectionTitle,
        },
      });
      if (UpdateFeaturedCategoryTitle.success) {
        successNotify(UpdateFeaturedCategoryTitle.message);
        refetch();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditItem = ({ _id, imageLink, url, cardTitle }) => {
    setCardId(_id);
    setImageLink(imageLink);
    setUrl(url);
    setCardTitle(cardTitle);
    setVisible(true);
  };

  const onCloseModal = () => {
    setVisible(false);
    setCardId("");
    setCardTitle("");
    setImageLink("");
    setUrl("");
    setCardId("");
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  return (
    <div>
      <div className="row mb-4">
        <div className="col-md-6">
          <div className="mb-2">
            <div className="mb-2">Section Title</div>
            <Input
              size="large"
              placeholder="Enter section title"
              value={sectionTitle}
              onChange={(e) => setSectionTitle(e.target.value)}
            />
          </div>
          <div className="text-right">
            <Spin spinning={titleLoading}>
              <button
                className="btn-common"
                disabled={titleLoading}
                onClick={handleUpdateTitle}
              >
                Update Title
              </button>
            </Spin>
          </div>
        </div>
      </div>
      <Spin spinning={loading}>
        <Row gutter={[16, 16]}>
          {allItem?.map((catItem) => (
            <Col lg={6} key={catItem?._id}>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  marginBottom: "40px",
                }}
              >
                <Card
                  bodyStyle={{ padding: 0 }}
                  hoverable
                  cover={<img width="100%" src={getFile(catItem?.imageLink)} alt=""/>}
                  actions={[
                    <EditOutlined
                      key="pencil"
                      onClick={() => handleEditItem(catItem)}
                    />,
                    <Popconfirm
                      title="Are you sure?"
                      okButtonProps={{ danger: "true", loading: deleteLoading }}
                      onConfirm={() => handleDeleteItem(catItem?._id)}
                    >
                      <DeleteOutlined key="ellipsis" />
                    </Popconfirm>,
                  ]}
                >
                  <h6 className="px-4 py-2">{catItem?.cardTitle}</h6>
                </Card>
              </div>
            </Col>
          ))}
        </Row>
      </Spin>

      <div className="row">
        <div className="col-12">
          <div className="text-right">
            <button className="btn-common" onClick={() => setVisible(true)}>
              <i className="fas fa-plus-circle mr-2"></i>Add Category
            </button>
          </div>
        </div>
      </div>
      <Modal
        title={cardId ? "Update Category Item" : "Add Category Item"}
        open={visible}
        okText={cardId ? "Update" : "Add"}
        onCancel={onCloseModal}
        onOk={cardId ? editItem : addItem}
        okButtonProps={{
          disabled: !imageLink,
          loading: editLoading || addLoading,
        }}
      >
        <div className="mb-2">
          <div className="mb-2">Card Title</div>
          <Input
            size="large"
            placeholder="Enter card Title"
            value={cardTitle}
            onChange={(e) => setCardTitle(e.target.value)}
          />
        </div>
        <div className="mb-2">
          <div className="mb-2">URL</div>
          <Input
            size="large"
            placeholder="Enter redirect URL"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
          />
        </div>
        <div className="mb-4">
          <div className="mb-2">File *</div>
          <Spin spinning={false}>
            <div className="mb-2">
              <img width="100%" src={getFile(imageLink)} />
            </div>
          </Spin>
          <input
            type="file"
            accept="image/*"
            onChange={uploadFile}
            disabled={uploadLoading}
            ref={fileInputRef}
          />
        </div>
      </Modal>
    </div>
  );
};
