import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { DynamicList } from "../components/Shared";
import {
  DELETE_ADMIN,
  FETCH_COLLABORATORS,
  INVITE_COLLABORATOR,
  UPDATE_ADMIN_STATUS,
  UPDATE_ROLE,
} from "../graphql/modules";
import moment from "moment";
import { FullName } from "../util/helper";
import {
  Input,
  Modal,
  Pagination,
  Select,
  Tag,
  Button,
  Popconfirm,
} from "antd";
import { successNotify, warnNotify } from "../util";
import { userRole } from "../config";
import { useSelector } from "react-redux";

export const Admin = () => {
  // state inititlization
  const [limit] = useState(20);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState({});
  const [userInput, setUserInput] = useState({});
  const [visible, setVisible] = useState(false);

  const { user } = useSelector((state) => state.auth);

  const { data, loading, refetch } = useQuery(FETCH_COLLABORATORS, {
    variables: {
      limit,
      offset: page - 1,
      role: [userRole.Admin, userRole.Moderator],
      ...filter,
    },
  });
  const allAdmins = data?.FetchAllAdminOrCollaborators?.result || [];
  const total = data?.FetchAllAdminOrCollaborators?.count || 0;

  // invite admin
  const [updateAdminStatusMutation, { loading: statusUpdateLoading }] =
    useMutation(UPDATE_ADMIN_STATUS);
  const onUpdateAdminStatus = async ({ id, status }) => {
    try {
      const {
        data: { UpdateAdminStatus },
      } = await updateAdminStatusMutation({
        variables: {
          id,
          status,
        },
      });
      if (UpdateAdminStatus.success) {
        successNotify(UpdateAdminStatus?.message);
        refetch();
      } else {
        warnNotify(UpdateAdminStatus?.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  // invite admin
  const [inviteAdminMutation, { loading: addLoading }] =
    useMutation(INVITE_COLLABORATOR);
  const addAdmin = async () => {
    try {
      const {
        data: { InviteAdminOrCollaborator },
      } = await inviteAdminMutation({
        variables: {
          userInput,
        },
      });
      if (InviteAdminOrCollaborator.success) {
        successNotify(InviteAdminOrCollaborator?.message);
        onCancelModal();
        refetch();
      } else {
        warnNotify(InviteAdminOrCollaborator?.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const [DeleteAdminMutation, { laoding: deleteLoading }] =
    useMutation(DELETE_ADMIN);
  const onDeleteAdmin = async (id) => {
    try {
      const {
        data: { DeleteAdmin },
      } = await DeleteAdminMutation({
        variables: {
          id,
        },
      });
      if (DeleteAdmin.success) {
        refetch();
      }
    } catch (error) {}
  };

  const [UpdateRoleMutation, { laoding: roleLoading }] =
    useMutation(UPDATE_ROLE);
  const onUpdateRole = async (id, role) => {
    try {
      const {
        data: { UpdateAdminRole },
      } = await UpdateRoleMutation({
        variables: {
          id,
          role,
        },
      });
      if (UpdateAdminRole.success) {
        refetch();
      }
    } catch (error) {}
  };

  // page handler
  const onChangePagination = (p) => {
    setPage(p);
  };

  // table column
  const column = [
    {
      title: "Member Since",
      dataIndex: "createdAt",
      render: (_, record) =>
        moment(Number(record.createdAt)).format("MM/DD/YYYY hh:mm A"),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Name",
      render: (_, record) => (FullName(record) ? FullName(record) : "---"),
    },
    {
      title: "Role",
      dataIndex: "role",
      render: (text) =>
        text === userRole.Admin ? (
          <Tag color="purple">{text}</Tag>
        ) : (
          <Tag color="cyan">{text}</Tag>
        ),
    },
    {
      title: "Status",
      dataIndex: "status",
      // render: (text) =>
      key: "status",
      render: (text) =>
        text === "Active" ? (
          <Tag color="green">{text}</Tag>
        ) : text === "Blocked" ? (
          <Tag color="red">{text}</Tag>
        ) : (
          <Tag color="orange">{text}</Tag>
        ),
    },
    {
      title: "Action",
      dataIndex: "_id",
      render: (id, data) => (
        <div>
          <Popconfirm
            title="Are you sure?"
            okButtonProps={{ type: "danger" }}
            onConfirm={() => onDeleteAdmin(id)}
          >
            <Button size="small" type="danger" loading={deleteLoading}>
              Delete
            </Button>
          </Popconfirm>

          {data?.status === "Pending" ? null : data?.status === "Active" ? (
            <Button
              className="ml-2"
              danger
              // loading={statusUpdateLoading}
              onClick={() => onUpdateAdminStatus({ id, status: "Blocked" })}
              size="small"
            >
              Suspend
            </Button>
          ) : (
            <Button
              className="ml-2"
              type="primary"
              loading={statusUpdateLoading}
              onClick={() => onUpdateAdminStatus({ id, status: "Active" })}
              size="small"
            >
              Re-active
            </Button>
          )}
          {data?.status === "Pending" || data?.status === "Blocked" ? null : (
            <Button
              size="small"
              className="ml-2"
              type="primary"
              loading={roleLoading}
              onClick={() =>
                onUpdateRole(
                  id,
                  data?.role === userRole.Admin
                    ? userRole.Moderator
                    : userRole.Admin
                )
              }
            >
              Make as{" "}
              {data?.role === userRole.Admin
                ? userRole.Moderator
                : userRole.Admin}
            </Button>
          )}
        </div>
      ),
      hidden: userRole.Admin === user.role,
    },
  ].filter((item) => !item.hidden);

  const handleInput = (e) => {
    const { name, value } = e.target;
    setFilter({ ...filter, [name]: value });
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setUserInput({ ...userInput, [name]: value });
  };

  // control collaborator
  const onCancelModal = () => {
    setUserInput({});
    setVisible(false);
  };

  let createBtn = (
    <button
      onClick={() => setVisible(true)}
      className="btn-common"
      type="primary"
    >
      <i className="fas fa-plus-circle mr-2"></i> Invite admin
    </button>
  );

  const searchComponent = (
    <div className="d">
      <Input
        onChange={handleInput}
        name="email"
        value={filter.email}
        size="large"
        className="mr-2"
        placeholder="Email"
        style={{ maxWidth: "350px" }}
      />
      <Select
        style={{ width: "300px" }}
        onChange={(value) => {
          if (value === "") {
            const flt = { ...filter };
            delete flt.role;
            setFilter({ ...flt });
          } else {
            setFilter({ ...filter, role: value });
          }
        }}
        size="large"
        value={filter?.role}
        placeholder="Select role"
      >
        <Select.Option value="">All</Select.Option>
        <Select.Option value={userRole.Admin}>{userRole.Admin}</Select.Option>
        <Select.Option value={userRole.Moderator}>
          {userRole.Moderator}
        </Select.Option>
      </Select>
    </div>
  );

  const disable =
    !userInput?.email ||
    !userInput?.firstname ||
    !userInput?.lastname ||
    !userInput?.role;

  return (
    <div>
      <DynamicList
        title="All Admin"
        data={allAdmins}
        columns={column}
        actions={createBtn}
        loading={loading || statusUpdateLoading}
        tableFilter={searchComponent}
        pagination={
          <Pagination
            className="pagination"
            total={total}
            pageSize={limit}
            current={page}
            showSizeChanger={false}
            onChange={onChangePagination}
          />
        }
      />
      <Modal
        title="Inviting admin"
        open={visible}
        onOk={addAdmin}
        onCancel={onCancelModal}
        okText="Invite"
        okButtonProps={{
          disabled: disable || addLoading,
          loading: addLoading,
          style: { background: "#000", borderColor: "#000" },
        }}
      >
        <div className="mb-3">
          <div>First name</div>
          <Input
            placeholder="first name"
            size="large"
            name="firstname"
            onChange={onChange}
            value={userInput?.firstname}
          />
        </div>
        <div className="mb-3">
          <div>Last name</div>
          <Input
            placeholder="last name"
            name="lastname"
            size="large"
            onChange={onChange}
            value={userInput?.lastname}
          />
        </div>
        <div className="mb-3">
          <div>Email</div>
          <Input
            placeholder="email"
            name="email"
            size="large"
            onChange={onChange}
            value={userInput?.email}
          />
        </div>
        <div className="mb-3">
          <div>Role</div>
          <Select
            style={{ width: "100%" }}
            onChange={(value) => setUserInput({ ...userInput, role: value })}
            size="large"
            value={userInput?.role}
            placeholder="Select role"
          >
            <Select.Option value={userRole.Admin}>
              {userRole.Admin}
            </Select.Option>
            <Select.Option value={userRole.Moderator}>Viewer</Select.Option>
          </Select>
        </div>
      </Modal>
    </div>
  );
};
