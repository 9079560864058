import { useQuery } from "@apollo/react-hooks";
import React from "react";
import { DynamicList } from "../components/Shared";
import { FETCH_ALL_SUBSCRIBER_USER } from "../graphql/modules";

export const SubscriberList = () => {
  const { data, loading } = useQuery(FETCH_ALL_SUBSCRIBER_USER);
  const allSubscriber = data?.FetchSubscriberList?.result || [];

  const column = [
    {
      title: "Email",
      dataIndex: "email",
    },
  ];

  return (
    <DynamicList
      title="All Subscriber"
      loading={loading}
      data={allSubscriber}
      columns={column}
    />
  );
};
