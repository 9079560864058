import { BulbOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  Col,
  Input,
  Row,
  Button,
  Checkbox,
  Select,
  Tag,
  Switch,
  Tooltip,
} from "antd";
import React from "react";
import { useState } from "react";
import Description from "./ProductInfo/Descriptions";
import { PriceInput } from "./ProductInfo/PriceInputBox";
import { catSubType } from "../../config";

export const ProductInfo = ({ sizeType, infoState, setInfoState, catType }) => {
  const [codeInput, setCodeInput] = useState("");
  const descriptionType =
    sizeType === catSubType.fullSet
      ? "descriptions"
      : sizeType?.toLowerCase() + "Descriptions";

  // add code
  const onAddCode = () => {
    const code = infoState?.code || [];
    code.push(codeInput);
    setInfoState({ ...infoState, code });
    setCodeInput("");
  };

  //delete code
  const deleteCode = (name) => {
    const code = [...infoState?.code].filter((item) => item !== name);
    setInfoState({ ...infoState, code });
  };

  //handle descriptions
  const handleDescription = (descriptions) => {
    setInfoState({ ...infoState, [descriptionType]: descriptions });
  };

  // handle input
  const handleInput = (e) => {
    const { name, value } = e.target;
    setInfoState({ ...infoState, [name]: value });
  };

  //checkboxHandle
  const handleCheckBox = (e) => {
    const { name, checked } = e.target;
    setInfoState({ ...infoState, [name]: checked });
  };

  const productPrice =
    sizeType === "FULL_SET"
      ? infoState?.price
      : infoState?.[sizeType?.toLowerCase() + "Price"];
  return (
    <Row gutter={[16, 16]}>
      { (catType === catSubType.mixed && !infoState?.isAccessories) &&
        <Col lg={8}>
          <label className="d-block">
            Product Type <small className="text-danger h6">*</small>
          </label>
          <Select
              size="large"
              style={{width:'100%'}}
              onChange={(value) =>
                setInfoState({ ...infoState, sizeType: value })
              }
              value={infoState.sizeType}
            >
              <Select.Option key={catSubType.fullSet}>Full Set</Select.Option>
              <Select.Option key={catSubType.top}>Top</Select.Option>
              <Select.Option key={catSubType.bottom}>Bottom</Select.Option>
            </Select>
        </Col>
      }
      <Col lg={8}>
        <label>
          Product Name <small className="text-danger h6">*</small>
        </label>
        <Input
          size="large"
          placeholder="Product name"
          value={infoState?.name || ""}
          onChange={handleInput}
          name="name"
        />
      </Col>
      <PriceInput
        handleInput={handleInput}
        infoState={infoState}
        sizeType={sizeType}
      />
      <Col lg={8}>
        <label style={{ width: "50%", marginRight: "10px" }}>
          Sale Discount( %)
          <Checkbox
            checked={infoState?.haveDiscount}
            onChange={handleCheckBox}
            name="haveDiscount"
            className="ml-2"
          ></Checkbox>
        </label>
        {infoState?.haveDiscount && (
          <label>
            Discount On <small className="text-danger h6">*</small>
          </label>
        )}
        <div className="d-flex">
          <Input
            type="number"
            size="large"
            style={{ width: "50%", marginRight: "10px" }}
            disabled={!infoState?.haveDiscount}
            placeholder="Discount"
            onChange={handleInput}
            value={infoState?.discount || ""}
            name="discount"
            suffix={
              <span>
                <Tooltip title="Price after discount">
                  <Tag color="orange" style={{ fontSize: "14px" }}>
                    {infoState?.discount
                      ? Math.round(
                          productPrice -
                            (infoState.discount * productPrice) / 100
                        )
                      : ""}
                  </Tag>
                </Tooltip>
              </span>
            }
          />
          {infoState?.haveDiscount && (
            <Select
              size="large"
              style={{ width: "50%" }}
              onChange={(value) =>
                setInfoState({ ...infoState, discountType: value })
              }
              value={infoState.discountType}
            >
              <Select.Option key="">Select</Select.Option>
              <Select.Option key="all">All</Select.Option>
              <Select.Option key="instock">In Stock</Select.Option>
              <Select.Option key="preOrder">Pre-Order</Select.Option>
            </Select>
          )}
        </div>
      </Col>

      <Col lg={8}>
        <label>Product code</label>
        <Input
          size="large"
          placeholder="Product code"
          onChange={(e) => setCodeInput(e.target.value)}
          name="code"
          value={codeInput}
          suffix={
            <Button type="primary" onClick={onAddCode} disabled={!codeInput}>
              Add
            </Button>
          }
        />
        <div className="mt-3">
          {Array.isArray(infoState?.code) &&
            infoState?.code?.map((item) => (
              <div
                className="d-flex justify-content-between align-items-center border mb-2 p-2 rounded bg-white"
                key={item}
              >
                <div>{item}</div>
                <div>
                  <DeleteOutlined
                    style={{ cursor: "pointer" }}
                    onClick={() => deleteCode(item)}
                  />
                </div>
              </div>
            ))}
        </div>
      </Col>
      <Col lg={16}>
        <label>Product description</label>
        <Description
          data={infoState[descriptionType]}
          onChange={handleDescription}
        />
      </Col>
      <Col span={24}>
        {sizeType === catSubType.fullSet && (
          <Tag
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              padding: "5px 10px",
              marginBottom: "10px",
            }}
            color="success"
          >
            <span className="mr-3">
              <BulbOutlined className="mr-2" />
              Mark as Accessories
            </span>{" "}
            <Checkbox
              onChange={(e) =>
                setInfoState({
                  ...infoState,
                  isAccessories: e.target.checked,
                })
              }
              checked={infoState.isAccessories}
              // disabled={productId}
            />
          </Tag>
        )}
      </Col>
      <Col lg={16}>
        <label>New tag availibility</label>
        <div>
          <Switch
            checkedChildren="ON"
            unCheckedChildren="OFF"
            checked={infoState?.isNewProduct}
            onChange={(checked) =>
              setInfoState({ ...infoState, isNewProduct: checked })
            }
          />
        </div>
      </Col>
    </Row>
  );
};
