import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { DynamicList } from "../components/Shared";
import { FETCH_COLLABORATORS, INVITE_COLLABORATOR } from "../graphql/modules";
import moment from "moment";
import { FullName } from "../util/helper";
import { Input, Modal, Pagination, Tag } from "antd";
import { Link } from "react-router-dom";
import { successNotify, warnNotify } from "../util";
import { userRole } from "../config";
import { useSelector } from "react-redux";

export const Collaborators = () => {
  // state inititlization
  const [limit] = useState(20);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState({});
  const [userInput, setUserInput] = useState({ role: userRole.collaborator });
  const [visible, setVisible] = useState(false);
  const {user} = useSelector((state) => state.auth);

  const { data, loading, refetch } = useQuery(FETCH_COLLABORATORS, {
    variables: {
      limit,
      offset: page - 1,
      role: [userRole.collaborator],
      ...filter,
    },
  });
  const allCollaborators = data?.FetchAllAdminOrCollaborators?.result || [];
  const total = data?.FetchAllAdminOrCollaborators?.count || 0;

  // invite collaborator
  const [InviteCollaboratorMutation, { loading: addLoading }] =
    useMutation(INVITE_COLLABORATOR);
  const addCollaborator = async () => {
    try {
      const {
        data: { InviteAdminOrCollaborator },
      } = await InviteCollaboratorMutation({
        variables: {
          userInput,
        },
      });
      if (InviteAdminOrCollaborator.success) {
        successNotify(InviteAdminOrCollaborator?.message);
        onCancelModal();
        refetch();
      } else {
        warnNotify(InviteAdminOrCollaborator?.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // page handler
  const onChangePagination = (p) => {
    setPage(p);
  };

  // table column
  const column = [
    {
      title: "Member Since",
      dataIndex: "createdAt",
      render: (_, record) =>
        moment(Number(record.createdAt)).format("MM/DD/YYYY hh:mm A"),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Name",
      render: (_, record) => (FullName(record) ? FullName(record) : "---"),
    },
    {
      title: "Instagram Profile",
      dataIndex: "instagramProfile",
      render: (text) =>
        text ? (
          <a target="_blank" href={text?.includes("https://") ? text: "https://"+text}>
            {text}
          </a>
        ) : (
          "---"
        ),
    },
    {
      title: "Status",
      dataIndex: "status",
      // render: (text) =>
      key: "status",
      render: (text) =>
        text === "Active" ? (
          <Tag color="green">{text}</Tag>
        ) : (
          <Tag color="orange">{text}</Tag>
        ),
    },
    {
      title: "Action",
      dataIndex: "_id",
      render: (text, record) =>
        record?.status === "Active" && (
          <Link to={`/collaborators/${text}`}>
            <i
              className="fas fa-eye"
              style={{ cursor: "pointer", color: "rgba(0,0,0,.85)" }}
            ></i>
          </Link>
        ),
      hidden: userRole.Moderator === user.role
    },
  ].filter(item=>!item.hidden);

  const handleInput = (e) => {
    const { name, value } = e.target;
    setFilter({ ...filter, [name]: value });
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setUserInput({ ...userInput, [name]: value });
  };

  // control collaborator
  const onCancelModal = () => {
    setUserInput({ role: userRole.collaborator });
    setVisible(false);
  };

  let createBtn = (
    <button
      onClick={() => setVisible(true)}
      className="btn-common"
      type="primary"
    >
      <i className="fas fa-plus-circle mr-2"></i> Invite collaborator
    </button>
  );

  const searchComponent = (
    <div className="d-flex justify-content-between align-items-center">
      <Input
        onChange={handleInput}
        name="email"
        value={filter.email}
        size="large"
        className="mr-2"
        placeholder="Email"
      />
      <Input
        onChange={handleInput}
        name="instagramProfile"
        value={filter.instagramProfile}
        size="large"
        className="mr-2"
        placeholder="Instagram Profile Link"
      />
    </div>
  );

  const disable =
    !userInput?.email || !userInput?.firstname || !userInput?.lastname;

  return (
    <div>
      <DynamicList
        title="All Collaborator"
        data={allCollaborators}
        columns={column}
        actions={createBtn}
        loading={loading}
        tableFilter={searchComponent}
        pagination={
          <Pagination
            className="pagination"
            total={total}
            pageSize={limit}
            current={page}
            showSizeChanger={false}
            onChange={onChangePagination}
          />
        }
      />
      <Modal
        title="Inviting collaborator"
        open={visible}
        onOk={addCollaborator}
        onCancel={onCancelModal}
        okText="Invite"
        okButtonProps={{
          disabled: disable || addLoading,
          loading: addLoading,
          style: { background: "#000", borderColor: "#000" },
        }}
      >
        <div className="mb-3">
          <div>First name</div>
          <Input
            placeholder="first name"
            size="large"
            name="firstname"
            onChange={onChange}
            value={userInput?.firstname}
          />
        </div>
        <div className="mb-3">
          <div>Last name</div>
          <Input
            placeholder="last name"
            name="lastname"
            size="large"
            onChange={onChange}
            value={userInput?.lastname}
          />
        </div>
        <div className="mb-3">
          <div>Email</div>
          <Input
            placeholder="email"
            name="email"
            size="large"
            onChange={onChange}
            value={userInput?.email}
          />
        </div>
      </Modal>
    </div>
  );
};
