import { UploadOutlined } from "@ant-design/icons";
import { Button, Col, Row, Spin, Switch, Upload, message } from "antd";
import React from "react";
import ImgCrop from "antd-img-crop";
// import SingleCrop from "./SingleCrop";
import { useState } from "react";
import { catSubType } from "../../config";
import { ImageList } from "./Gallery/ImageList";
import { useMutation } from "@apollo/react-hooks";
import { FILE_UPLOAD } from "../../graphql/modules";
import { StyleImages } from "./Gallery/StyleImages";
// import { StyleImg } from "./Gallery/StyleImg";

export const Gallery = ({ product = {}, loading, onSubmit, sizeType }) => {
  // state declare
  const [galleryState, setGalleryState] = useState({
    gallery: product?.gallery || [],
  });

  const [styleState, setStyleState] = useState(product?.styles);

  const [galleryImageMode, setGalleryImageMode] = useState(true);

  // image upload api
  const [SingleUplaodMutation, { loading: uploadLoading }] =
    useMutation(FILE_UPLOAD);

  const handleUploadGallery = async (e) => {
    // const MAX_LENGTH = 4 - galleryState[galleryType].length;
    // if (Array.from(e.target.files).length > MAX_LENGTH) {
    //   e.preventDefault();
    //   message.warning("Cannot upload files more than 4");
    //   return;
    // }

    const { files = [] } = await e.target;
    let images = [...galleryState[galleryType]] || [];

    for (var i = 0; i < files.length; i++) {
      try {
        // const compressedFile = await imageCompression(files[i], options);
        const {
          data: { SingleUpload },
        } = await SingleUplaodMutation({
          variables: {
            file: files[i],
          },
        });
        if (SingleUpload.success) {
          images.push(SingleUpload.filename);
          setGalleryState({ ...galleryState, [galleryType]: images });
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  // handle submit
  const handleSubmit = () => {
    onSubmit({ ...galleryState, styles: styleState });
  };

  // product type
  const galleryType =
    sizeType === catSubType.fullSet
      ? "gallery"
      : sizeType?.toLowerCase() + "Gallery";

  const disable = galleryState[galleryType].length === 0;

  return (
    <Spin spinning={loading}>
      <Row gutter={[16, 16]}>
        {product?.isAccessories && (
          <Col lg={24}>
            <div className="mb-5">
              <StyleImages styles={styleState} setStyles={setStyleState} />
              {/* <StyleImg styles={styles} setStyles={setStyles} /> */}
            </div>
          </Col>
        )}

        <Col lg={24}>
          <Spin spinning={uploadLoading}>
            <h5>Gallery</h5>
            <small>
              Switch to {galleryImageMode ? "crop" : "multiple"} image uplaod
            </small>
            <Switch
              style={{ marginLeft: "5px" }}
              size="small"
              checked={galleryImageMode}
              onClick={(check) => setGalleryImageMode(check)}
            />
            <div className="d-flex" style={{ marginTop: "10px" }}>
              <ImageList
                galleryState={galleryState}
                galleryType={galleryType}
                setGalleryState={setGalleryState}
              />

              {!galleryImageMode ? (
                  <ImgCrop
                    rotationslider={true}
                    showGrid
                    quality={1}
                    onModalOk={(data) =>
                      handleUploadGallery({ target: { files: [data] } })
                    }
                    aspect={5 / 7}
                  >
                    <Upload
                      showUploadList={false}
                      className="upload-picture"
                      listType="picture-card"
                    >
                      <UploadOutlined /> Upload
                    </Upload>
                  </ImgCrop>
                ) : (
                  <div className="d-inline-block">
                    <input
                      type="file"
                      onChange={handleUploadGallery}
                      className="upload-product-image"
                      // id={id}
                      multiple
                      accept="image/*,video/*"
                      style={{width:'100px'}}
                    />
                  </div>
                )}
            </div>
          </Spin>
        </Col>
        <div className="w-100 mt-3 text-right px-2">
          <Button
            className="px-4"
            type="primary"
            disabled={disable}
            onClick={handleSubmit}
          >
            Update
          </Button>
        </div>
      </Row>
    </Spin>
  );
};
