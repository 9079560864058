import React from "react";
import { Spin } from "antd";
import moment from "moment";

export const UserProfile = ({
  loading = false,
  user,
  backTo,
  actions = "",
  showCollaboratorInfo,
}) => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <Spin spinning={loading}>
            <div className="card">
              <div className="account-details">
                <div className="account-details-colum">
                  <h6>Full Name</h6>
                  <p>{user?.firstname + " " + user?.lastname}</p>
                </div>
                <div className="account-details-colum">
                  <h6>Email</h6>
                  <p>{user?.email}</p>
                </div>
                {showCollaboratorInfo && (
                  <>
                    <div className="account-details-colum">
                      <h6>Instagram Profile</h6>
                      <a target="_blank" href={user?.instagramProfile}>
                        {user?.instagramProfile}
                      </a>
                    </div>
                    <div className="account-details-colum">
                      <h6>Money Earned</h6>
                      <p>{(user?.moneyEarned || 0).toFixed(2)} USD</p>
                    </div>
                    <div className="account-details-colum">
                      <h6>Member Since</h6>
                      <p>
                        {moment(Number(user.createdAt)).format(
                          "MM/DD/YYYY hh:mm A"
                        )}
                      </p>
                    </div>
                  </>
                )}
              </div>
            </div>
          </Spin>
          {actions}
        </div>
      </div>
    </div>
  );
};
