import {
  BgColorsOutlined,
  CaretRightFilled,
  DeleteOutlined,
} from "@ant-design/icons";
import { Collapse } from "antd";
import React from "react";
import { Inventory } from "./Inventory/Inventory";
import { catSubType } from "../../config";
import { successNotify } from "../../util";

export const ClothInventory = ({
  sizeType,
  inventoryState,
  setInventoryState,
}) => {


  const handleUpdate = (data, idx) => {
    const colors = [...inventoryState?.colors];
    colors[idx] = { ...data };
    setInventoryState({...inventoryState, colors: colors});
    successNotify('Update successfylly')
  };

  const deleteColor = (idx)=>{
    const colors = [...inventoryState?.colors];
    colors.splice(idx, 1);
    setInventoryState({...inventoryState, colors: colors})
  }


  const panelStyle = {
    marginBottom: 24,
    // border: "none",
    background: "#d3d3d324",
    border: "1px solid lightgray",
    borderRadius: "5px",
  };

  const items = inventoryState?.colors?.map(
    (data, idx) => ({
      key: `${idx + 1}`,
      label: (
        <div className="d-flex align-items-cemter">
          <BgColorsOutlined
            className="mr-2 text-success"
            style={{ marginTop: "3px" }}
          />
          <h6 className="m-0">{`${idx + 1}. ${
            sizeType === catSubType.fullSet
              ? data?.topColorName + " & " + data?.bottomColorName
              : data[sizeType.toLowerCase() + "ColorName"]
          }`}</h6>
        </div>
      ),
      children: (
        <Inventory
          sizeType={sizeType}
          colorData={{ ...data }}
          handleUpdate={handleUpdate}
          idx={idx}
        />
      ),
      style: panelStyle,
      extra: [
          <DeleteOutlined
            onClick={(e) => {
              e.stopPropagation();
              deleteColor(idx)
            }}
            key="1"
            className="text-danger"
          />
      ],
    })
  );

 
  return (
    <Collapse
      bordered
      accordion
      items={items}
      expandIcon={({ isActive }) => (
        <CaretRightFilled
          style={{ fontSize: "18px" }}
          rotate={isActive ? 90 : 0}
        />
      )}
      expandIconPosition="end"
      style={{ background: "#fff" }}
      defaultActiveKey={["1"]}
    />
  );
};