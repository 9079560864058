import {
  BgColorsOutlined,
  CaretRightFilled,
  DeleteOutlined,
} from "@ant-design/icons";
import { Collapse, Spin } from "antd";
import React from "react";
import { Inventory } from "./Inventory/Inventory";
import { catSubType } from "../../config";
import { useMutation } from "@apollo/react-hooks";
import { DELETE_COLOR_OF_PRODUCTY } from "../../graphql/modules";
import { successNotify, warnNotify } from "../../util";

export const ClothInventory = ({ product = {}, sizeType, refetch }) => {
  const panelStyle = {
    marginBottom: 24,
    background: "#d3d3d324",
    border: "1px solid lightgray",
    borderRadius: "5px",
  };

  const [DeleteColorMutation, { loading }] = useMutation(
    DELETE_COLOR_OF_PRODUCTY
  );
  const onDeleteColor = async (colorId) => {
    try {
      const {
        data: { DeleteProductColor },
      } = await DeleteColorMutation({
        variables: {
          productId: product?._id,
          colorId,
        },
      });
      if (DeleteProductColor.success) {
        successNotify(DeleteProductColor.message);
        refetch();
      } else {
        warnNotify(DeleteProductColor.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const items = getItems(sizeType, product?.colors)?.map((data, idx) => ({
    key: `${idx + 1}`,
    label: (
      <div className="d-flex align-items-cemter">
        <BgColorsOutlined
          className="mr-2 text-success"
          style={{ marginTop: "3px" }}
        />
        <h6 className="m-0">{`${idx + 1}. ${
          sizeType === catSubType.fullSet
            ? data?.topColorName + " & " + data?.bottomColorName
            : data[sizeType.toLowerCase() + "ColorName"]
        }`}</h6>
      </div>
    ),
    children: (
      <Inventory
        // onSubmit={onEditProduct}
        refetch={refetch}
        sizeType={sizeType}
        colorData={{ ...data, productId: product._id }}
      />
    ),
    style: panelStyle,
    extra: [
      data.deletable && getItems(sizeType, product?.colors).length>1 && (
        <DeleteOutlined
          onClick={(e) => {
            e.stopPropagation();
            onDeleteColor(data?._id);
          }}
          className="text-danger"
        />
      ),
    ],
  }));
  return (
    <Spin spinning={loading}>
      <Collapse
        bordered
        accordion
        items={items}
        expandIcon={({ isActive }) => (
          <CaretRightFilled
            style={{ fontSize: "18px" }}
            rotate={isActive ? 90 : 0}
          />
        )}
        expandIconPosition="end"
        style={{ background: "#fff" }}
        defaultActiveKey={["1"]}
      />
    </Spin>
  );
};


const getItems = (sizeType, colorData)=>{
  let filterdata = colorData?.filter((data)=>{
    let filterBy = sizeType===catSubType.fullSet ? (!!data?.topColor && !!data?.bottomColor): (!!data[sizeType.toLowerCase()+'Color']);
    let deletable = false;
    if(sizeType===catSubType.fullSet){
      deletable= !!data?.topColor && !!data?.bottomColor;
    } else if(sizeType===catSubType.top){
      deletable= !!data?.topColor && !data?.bottomColor;
    } else {
      deletable = !data?.topColor && !!data?.bottomColor;
    }
    if(filterBy){
      data.deletable = deletable;
      return {...data};
    }
  })
  return filterdata
}
