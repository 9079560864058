import React, { useState } from "react";
import { Card, Collapse, Input, Modal, Popconfirm, Space, Tooltip } from "antd";
import {
  CaretRightOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusCircleFilled,
} from "@ant-design/icons";
import Answer from "./Answer";
import ReactQuill from "react-quill";
import { useMutation } from "@apollo/react-hooks";
import {
  ADD_LEGAL_IN_CATEGORY,
  DELETE_LEGAL_IN_CATEGORY,
  EDIT_LEGAL_IN_CATEGORY,
} from "../../graphql/modules";
import { successNotify, warnNotify } from "../../util";

const { Panel } = Collapse;
const SingleLegal = ({
  editFaqCategory,
  category,
  refetch,
  confirmDeleteCategory,
}) => {
  const [visible, setVisible] = useState(false);
  const [qua, setQua] = useState("");
  const [ans, setAns] = useState("");
  const [faqId, setFaqId] = useState("");

  // handle modal
  const closeModal = () => {
    setFaqId("");
    setQua("");
    setAns("");
    setVisible(false);
  };
  const openModal = (data = {}) => {
    setFaqId(data?._id);
    setQua(data?.qua);
    setAns(data?.ans);
    setVisible(true);
  };

  // handle editor input
  const onEditorStateChange = (value) => {
    setAns(value);
  };

  //
  const [AddFAQMutaion, { loading: addLoading }] = useMutation(
    ADD_LEGAL_IN_CATEGORY
  );
  const addFaq = async () => {
    try {
      const {
        data: { AddLegalTerm },
      } = await AddFAQMutaion({
        variables: {
          categoryId: category._id,
          qua,
          ans,
        },
      });
      if (AddLegalTerm.success) {
        closeModal();
        successNotify(AddLegalTerm.message);
        refetch();
      } else {
        warnNotify(AddLegalTerm.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  // update faq api
  const [EditFAQMutaion, { loading: editLoading }] = useMutation(
    EDIT_LEGAL_IN_CATEGORY
  );
  const editFaq = async () => {
    try {
      const {
        data: { EditLegalTerm },
      } = await EditFAQMutaion({
        variables: {
          faqId,
          qua,
          ans,
        },
      });
      if (EditLegalTerm.success) {
        closeModal();
        successNotify(EditLegalTerm.message);
        refetch();
      } else {
        warnNotify(EditLegalTerm.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // delete faq question
  const [DeleteFaqMutaion] = useMutation(DELETE_LEGAL_IN_CATEGORY);
  const deleteFaq = async (id) => {
    try {
      const {
        data: { DeleteLegalTerm },
      } = await DeleteFaqMutaion({
        variables: {
          faqId: id,
        },
      });
      if (DeleteLegalTerm.success) {
        successNotify(DeleteLegalTerm.message);
        refetch();
      } else {
        warnNotify(DeleteLegalTerm.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // extra
  const getExtra = (data) => (
    <React.Fragment>
      <div className="d-flex justify-content-around align-items-center">
        <div className="mr-2">
          <EditOutlined
            onClick={(e) => {
              openModal(data);
              e.stopPropagation();
            }}
          />
        </div>
        <div className="mr-2">
          <Popconfirm
            title="Are you sure?"
            okType="danger"
            onConfirm={(e) => {
              deleteFaq(data._id);
              e.stopPropagation();
            }}
          >
            <DeleteOutlined
              style={{ color: "red" }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
          </Popconfirm>
        </div>
      </div>
    </React.Fragment>
  );

  const getExtraForCategoryCard = () => (
    <React.Fragment>
      <div className="d-flex justify-content-around align-items-center">
        <div className="mr-2">
          <EditOutlined onClick={() => editFaqCategory(category)} />
        </div>
        <div className="mr-2">
          <Tooltip title="Add question">
            <PlusCircleFilled
              style={{ cursor: "pointer" }}
              onClick={openModal}
            />
          </Tooltip>
        </div>
        <DeleteOutlined
          style={{ color: "red" }}
          onClick={(e) => {
            confirmDeleteCategory(category?._id);
            e.stopPropagation();
          }}
        />
      </div>
    </React.Fragment>
  );

  const allFaq = category?.faq || [];

  return (
    <Card
      className="mb-4"
      title={category?.name}
      extra={getExtraForCategoryCard()}
    >
      <Space direction="vertical" style={{ width: "100%" }}>
        {allFaq.map((item) => (
          <Collapse
            key={item?._id}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotationslider={isActive ? 90 : 0} />
            )}
            items={[
              {
                key: "1",
                label: item?.qua,
                children: <Answer answerText={item?.ans} />,
                extra: getExtra(item),
              },
            ]}
          />
        ))}
      </Space>
      <Modal
        open={visible}
        title={faqId ? "Update legal and terms" : "Add legal and terms"}
        onCancel={closeModal}
        okText={faqId ? "Update" : "Add"}
        okButtonProps={{
          disabled: !qua || !ans || addLoading || editLoading,
          loading: addLoading || editLoading,
        }}
        onOk={faqId ? editFaq : addFaq}
      >
        <div className="mb-4">
          <label>Question*</label>
          <Input
            size="large"
            value={qua}
            onChange={(e) => setQua(e.target.value)}
            placeholder="question"
          />
        </div>
        <div>
          <label>Answer*</label>
          <ReactQuill
            modules={SingleLegal.modules}
            value={ans || ""}
            onChange={onEditorStateChange}
          />
        </div>
      </Modal>
    </Card>
  );
};

export default SingleLegal;

SingleLegal.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "blockquote"],
    [{ indent: "-1" }, { indent: "+1" }],
  ],
  clipboard: {
    matchVisual: false,
  },
};
