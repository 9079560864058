import React, { useState } from "react";
import { DynamicList } from "../components/Shared";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import { CREATE_PRODUCT } from "../graphql/modules";
import { Button, Collapse, Modal, Row } from "antd";
import {
  Gallery,
  AccessoriesInventory,
  ClothInventory,
  ProductInfo,
} from "../components/AddProduct";
import { successNotify, warnNotify } from "../util";
import {
  CaretRightFilled,
  DatabaseFilled,
  InfoCircleFilled,
  PictureFilled,
} from "@ant-design/icons";
import { catSubType } from "../config";
import { Colors } from "../components/AddProduct/Inventory/Colors";
import { SizeTable } from "../components/AddProduct/Inventory/SizeTable";
import { ColorsGallery } from "../components/AddProduct/Gallery/ColorsGallery";
import { Fragment } from "react";

export const AddMixedProduct = () => {
  const history = useHistory();
  const { id } = useParams();
  const location = useLocation();
//   const infoState.sizeType = new URLSearchParams(location.search).get("size-type");
  const catOrSub = new URLSearchParams(location.search).get("cat-sub");
  const defaulState = {
    inventoryState: {
      topSizing: [],
      bottomSizing: [],
      quantity: 0,
    },
    galleryState: {
      gallery: [],
      topGallery: [],
      bottomGallery: [],
      styles: [],
    },
    colorState: {
      topColor: "",
      topColorName: "",
      bottomColor: "",
      bottomColorName: "",
      topSizing: [],
      bottomSizing: [],
    },
  };
  // state declare
  const [open, setOpen] = useState(false);
  const [infoState, setInfoState] = useState({
    isAccessories: false,
    isNewProduct: true,
    discountType: "all",
    sizeType: 'FULL_SET',
  });
  const [inventoryState, setInventoryState] = useState({
    quantity: 0,
    colors: [],
  });

  const [galleryState, setGalleryState] = useState({
    ...defaulState.galleryState,
  });

  const [colorState, setColorState] = useState({ ...defaulState.colorState });

  // update product
  const [AddNewProductMutation, { loading }] = useMutation(CREATE_PRODUCT);
  const onAddProduct = async () => {
    const colorsFormat = inventoryState?.colors?.map((item) => ({
      ...item,
      topSizing: item?.topSizing?.map(({ size, quantity }) => ({
        size: size?._id,
        quantity,
      })),
      bottomSizing: item?.bottomSizing?.map(({ size, quantity }) => ({
        size: size?._id,
        quantity,
      })),
    }));
    const formatData = {
      ...infoState,
      gallery: galleryState?.gallery,
      styles: galleryState?.styles,
      colors: [...colorsFormat],
      price: parseFloat(infoState.price),
      topPrice: parseFloat(infoState.topPrice),
      bottomPrice: parseFloat(infoState.bottomPrice),
      discount: parseFloat(infoState.discount),
      quantity: Number(inventoryState.quantity),
    };
    try {
      const {
        data: { AddProduct },
      } = await AddNewProductMutation({
        variables: {
          data: {
            [catOrSub]: catOrSub === "subcategoryId" ? id : [id],
            ...formatData,
          },
        },
      });
      if (AddProduct.success) {
        successNotify(AddProduct.message);
        history.goBack();
      } else {
        warnNotify(AddProduct.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // disables panels
  const inventoryDisable =
    !infoState.name || infoState.sizeType === catSubType.fullSet
      ? !infoState.price
      : !infoState[infoState.sizeType.toLowerCase() + "Price"];
  const galleryDisable = infoState.isAccessories
    ? !Boolean(inventoryState.quantity)
    : inventoryState.colors.length === 0;

  // accordion components
  const panelStyle = {
    marginBottom: 24,
    background: "#d3d3d324",
    border: "1px solid lightgray",
    borderRadius: "5px",
  };
  const items = [
    {
      key: "1",
      label: (
        <div className="d-flex align-items-cemter">
          <InfoCircleFilled
            className="mr-2 text-success"
            style={{ marginTop: "3px" }}
          />
          <h6 className="m-0">Product Info</h6>
        </div>
      ),
      children: (
        <ProductInfo
          sizeType={infoState.sizeType}
          infoState={infoState}
          catType={catSubType.mixed}
          setInfoState={setInfoState}
        />
      ),
      style: panelStyle,
      visible: "true",
    },
    {
      key: "2",
      label: (
        <div className="d-flex align-items-cemter">
          <DatabaseFilled
            className="mr-2 text-success"
            style={{ marginTop: "3px" }}
          />
          <h6 className="m-0">
            {infoState?.isAccessories ? "Inventory" : "Colors & Inventory"}
          </h6>
        </div>
      ),
      children: infoState?.isAccessories ? (
        <AccessoriesInventory
          inventoryState={inventoryState}
          setInventoryState={setInventoryState}
        />
      ) : (
        <ClothInventory
          sizeType={infoState.sizeType}
          inventoryState={inventoryState}
          setInventoryState={setInventoryState}
        />
      ),
      style: panelStyle,
      collapsible:
        inventoryDisable ||
        (infoState.haveDiscount && !infoState.discountType) ||
        (infoState.haveDiscount && !infoState.discount)
          ? "disabled"
          : "collapsible",
      visible: "true",
      extra: [
        infoState?.isAccessories ? (
          ""
        ) : (
          <Button
            type="primary"
            onClick={(e) => {
              e.stopPropagation();
              setOpen(true);
            }}
            key="1"
            disabled={
              inventoryDisable ||
              (infoState.haveDiscount && !infoState.discountType) ||
              (infoState.haveDiscount && !infoState.discount)
            }
          >
            Add Color
          </Button>
        ),
      ],
    },
    {
      key: "3",
      label: (
        <div className="d-flex align-items-cemter">
          <PictureFilled
            className="mr-2 text-success"
            style={{ marginTop: "3px" }}
          />
          <h6 className="m-0">Gallery</h6>
        </div>
      ),
      children: (
        <Gallery
          sizeType={infoState.sizeType}
          galleryState={galleryState}
          setGalleryState={setGalleryState}
          isAccessories={infoState.isAccessories}
        />
      ),
      style: panelStyle,
      collapsible: galleryDisable ? "disabled" : "collapsible",
      visible: infoState?.isAccessories ? "true" : "false",
    },
  ].filter((item) => item.visible === "true");

  const handleAddColor = () => {
    const colorsData = [...inventoryState.colors];
    const gallery = { ...galleryState };
    delete gallery.styles;
    const colorsDataFormat = {
      ...colorState,
      topColor: colorState?.topColor?.toLowerCase(),
      bottomColor: colorState?.bottomColor?.toLowerCase(),
      topColorName:
        colorState?.topColorName?.charAt(0)?.toUpperCase() +
        colorState?.topColorName?.slice(1)?.toLowerCase(),
      bottomColorName:
        colorState?.bottomColorName?.charAt(0)?.toUpperCase() +
        colorState?.bottomColorName?.slice(1)?.toLowerCase(),
    };
    colorsData.push({ ...colorsDataFormat, ...gallery });
    setInventoryState({ ...inventoryState, colors: colorsData });
    closeModal();
  };

  const closeModal = () => {
    setOpen(false);
    setColorState({ ...defaulState.colorState });
    setGalleryState({ ...defaulState.galleryState });
  };

  const disableFullset =
    !colorState?.topColor ||
    !colorState?.bottomColor ||
    !colorState?.topColorName ||
    !colorState?.bottomColorName ||
    colorState?.topSizing.length === 0 ||
    colorState?.bottomSizing.length === 0 ||
    galleryState?.gallery.length === 0;
  const disableTop =
    !colorState?.topColor ||
    !colorState?.topColorName ||
    colorState?.topSizing.length === 0 ||
    galleryState?.topGallery.length === 0;

  const disableBotttom =
    !colorState?.bottomColor ||
    !colorState?.bottomColorName ||
    colorState?.bottomSizing.length === 0 ||
    galleryState?.bottomGallery.length === 0;

  return (
    <DynamicList tableHide title="Create New Product">
      <Collapse
        bordered={false}
        accordion
        items={items}
        expandIcon={({ isActive }) => (
          <CaretRightFilled
            style={{ fontSize: "18px" }}
            rotate={isActive ? 90 : 0}
          />
        )}
        expandIconPosition="end"
        style={{ background: "#fff" }}
        defaultActiveKey={["1"]}
      />
      <div className="text-right">
        <Button
          type="primary"
          onClick={onAddProduct}
          disabled={
            inventoryDisable ||
            (infoState?.isAccessories
              ? !inventoryState.quantity || galleryState?.gallery.length == 0
              : inventoryState?.colors?.length === 0)
          }
          loading={loading}
        >
          Create
        </Button>
      </div>

      <Modal
        open={open}
        title="Add Color Inventory"
        onCancel={closeModal}
        width={800}
        onOk={handleAddColor}
        okButtonProps={{
          disabled:
            infoState.sizeType === catSubType.fullSet
              ? disableFullset
              : infoState.sizeType === catSubType.top
              ? disableTop
              : disableBotttom,
        }}
        okText="Add"
      >
        <Colors
          colorState={colorState}
          setColorState={setColorState}
          sizeType={infoState.sizeType}
        />
        <Row gutter={[16, 16]} className="mb-4">
          {infoState.sizeType === catSubType.fullSet ? (
            <Fragment>
              <SizeTable
                dataSource={colorState.topSizing}
                inventoryState={colorState}
                setInventoryState={setColorState}
                sizeCategory="topSizing"
              />
              <SizeTable
                dataSource={colorState.bottomSizing}
                inventoryState={colorState}
                setInventoryState={setColorState}
                sizeCategory="bottomSizing"
              />
            </Fragment>
          ) : (
            <SizeTable
              dataSource={colorState[infoState.sizeType?.toLowerCase() + "Sizing"]}
              inventoryState={colorState}
              setInventoryState={setColorState}
              sizeCategory={infoState.sizeType?.toLowerCase() + "Sizing"}
            />
          )}
        </Row>
        <Row>
          <ColorsGallery
            galleryState={galleryState}
            setGalleryState={setGalleryState}
            sizeType={infoState.sizeType}
          />
        </Row>
      </Modal>
    </DynamicList>
  );
};
