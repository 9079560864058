import { notification } from "antd";
import { S3_BASE_URL } from "../config";
const noImage = "/assets/img/noimage.png";
export const getFile = (image) => {
  if (!image) return noImage;
  return S3_BASE_URL + image;
};

export const showNotification = (data) => {
  if (data.success) {
    notification.success({
      message: data.message,
      placement: "bottomLeft",
    });
  } else {
    notification.error({
      message: data.message,
      placement: "bottomLeft",
    });
  }
};

export const Reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const AddressRender = (data) => {
  let first = "";
  let second = "";
  if (data?.address) {
    first = first.concat(data?.address);
  }
  if (data?.apertment) {
    first = first.concat(" ", data?.apertment);
  }
  if (data?.city) {
    second = second.concat(data.city);
  }
  if (data?.state) {
    second = second.concat(", ", data.state);
  }
  if (data?.zipCode) {
    second = second.concat(" ", data.zipCode);
  }
  return { first, second };
};

export const FullName = (data) => {
  let name = "";
  if (data?.firstname) {
    name = name.concat(data?.firstname);
  }
  if (data?.firstname) {
    name = name.concat(" ", data?.lastname);
  }
  return name;
};
