import { userRole } from "../config";

export const sidebar = [
  {
    path: "/admin",
    label: "Admin Group",
    icon: "fas fa-atom",
    role: [userRole.superAdmin, userRole.Admin]
  },
  {
    path: "/collaborators",
    label: "Collaborators",
    icon: "far fa-handshake",
    role: [userRole.superAdmin, userRole.Admin, userRole.Moderator]
  },
  {
    path: "/customers",
    label: "Customers",
    icon: "fas fa-users",
    role: [userRole.superAdmin, userRole.Admin, userRole.Moderator]
  },
  {
    path: "/orders",
    label: "Orders",
    icon: "fab fa-sellsy",
    role: [userRole.superAdmin, userRole.Admin, userRole.Moderator]
  },
  {
    label: "All Category",
    icon: "fab fa-bitbucket",
    path: "/categories",
    role: [userRole.superAdmin, userRole.Admin, userRole.Moderator]
  },
  {
    label: "Settings",
    icon: "fas fa-cog",
    path: "/settings",
    role: [userRole.superAdmin, userRole.Admin]
  },
  {
    label: "Size",
    icon: "fas fa-border-none",
    path: "/sizing",
    role: [userRole.superAdmin, userRole.Admin]
  },
  {
    label: "Landing Page",
    icon: "fas fa-plane",
    path: "/landing-page",
    role: [userRole.superAdmin, userRole.Admin]
  },
  {
    label: "Content Management",
    icon: "fas fa-tasks",
    path: "/content-management",
    role: [userRole.superAdmin, userRole.Admin]
  },
  {
    path: "/faq",
    label: "FAQ",
    icon: "far fa-question-circle",
    role: [userRole.superAdmin, userRole.Admin]
  },
  {
    path: "/legal",
    label: "Legal & Terms",
    icon: "fas fa-gavel",
    role: [userRole.superAdmin, userRole.Admin]
  },
  {
    path: "/my-profile",
    label: "Account",
    icon: "far fa-user",
    role: [userRole.superAdmin, userRole.Admin, userRole.Moderator]
  },
];
