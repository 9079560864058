import gql from "graphql-tag";

export const FETCH_FAQ_CATEGORY = gql`
  query FetchFAQ($limit: Int, $offset: Int) {
    FetchFAQ(limit: $limit, offset: $offset) {
      code
      success
      message
      result {
        _id
        name
        faq {
          _id
          qua
          ans
        }
      }
    }
  }
`;

export const ADD_FAQ_CATEGORY = gql`
  mutation AddFAQCategory($name: String!) {
    AddFAQCategory(name: $name) {
      code
      success
      message
    }
  }
`;

export const EDIT_FAQ_CATEGORY = gql`
  mutation EditFAQCategory($categoryId: ID!, $name: String!) {
    EditFAQCategory(categoryId: $categoryId, name: $name) {
      code
      success
      message
    }
  }
`;

export const DELETE_FAQ_CATEGORY = gql`
  mutation DeleteFAQCategory($categoryId: ID!) {
    DeleteFAQCategory(categoryId: $categoryId) {
      code
      success
      message
    }
  }
`;

export const ADD_FAQ_IN_CATEGORY = gql`
  mutation AddFAQ($qua: String!, $ans: String!, $categoryId: ID!) {
    AddFAQ(qua: $qua, ans: $ans, categoryId: $categoryId) {
      code
      success
      message
    }
  }
`;

export const EDIT_FAQ_IN_CATEGORY = gql`
  mutation EditFAQ($qua: String!, $ans: String!, $faqId: ID!) {
    EditFAQ(qua: $qua, ans: $ans, faqId: $faqId) {
      code
      success
      message
    }
  }
`;

export const DELETE_FAQ_IN_CATEGORY = gql`
  mutation DeleteFAQ($faqId: ID!) {
    DeleteFAQ(faqId: $faqId) {
      code
      success
      message
    }
  }
`;

export const FETCH_POLICY = gql`
  query FetchPrivacyAndPolicy($limit: Int, $offset: Int) {
    FetchPrivacyAndPolicy(limit: $limit, offset: $offset) {
      code
      success
      message
      result {
        _id
        title
        desc
      }
    }
  }
`;

export const ADD_POLICY = gql`
  mutation AddPrivacyAndPolicy($data: PrivacyAndPolicyInput) {
    AddPrivacyAndPolicy(data: $data) {
      code
      success
      message
    }
  }
`;

export const EDIT_POLICY = gql`
  mutation EditPrivacyAndPolicy($id: ID!, $data: PrivacyAndPolicyInput) {
    EditPrivacyAndPolicy(id: $id, data: $data) {
      code
      success
      message
    }
  }
`;

export const DELETE_POLICY = gql`
  mutation DeletePrivacyAndPolicy($id: ID!) {
    DeletePrivacyAndPolicy(id: $id) {
      code
      success
      message
    }
  }
`;

export const FETCH_LEGAL_TERMS = gql`
  query FetchLegalTerm($limit: Int, $offset: Int) {
    FetchLegalTerm(limit: $limit, offset: $offset) {
      code
      success
      message
      result {
        _id
        title
        desc
      }
    }
  }
`;

export const ADD_LEAGAL_TERMS = gql`
  mutation AddLegalTerm($data: PrivacyAndPolicyInput) {
    AddLegalTerm(data: $data) {
      code
      success
      message
    }
  }
`;

export const EDIT_LEAGAL_TERM = gql`
  mutation EditLegalTerm($id: ID!, $data: PrivacyAndPolicyInput) {
    EditLegalTerm(id: $id, data: $data) {
      code
      success
      message
    }
  }
`;

export const DELETE_LEGAL_TERM = gql`
  mutation DeleteLegalTerm($id: ID!) {
    DeleteLegalTerm(id: $id) {
      code
      success
      message
    }
  }
`;