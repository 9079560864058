import React, { useRef } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Card, Col, Empty, Input, Modal, Popconfirm, Row, Spin } from "antd";
import { getFile, notify, successNotify, warnNotify } from "../../util";
import {
  ADD_BANNER,
  DELETE_BANNER,
  EDIT_BANNER,
  FETCH_BANNER,
  FILE_UPLOAD,
  UPDATE_BANNER_POSITION,
} from "../../graphql/modules";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useState } from "react";

const SetupPoster = ({mobile}) => {
  const fileInputRef = useRef(null);
  const [link, setLink] = useState("");
  const [anchorLink, setAnchorLink] = useState("");
  const [firstBtnLink, setFirstBtnLink] = useState("");
  const [firstBtnText, setFirstBtnText] = useState("");
  const [secondBtnLink, setSecondBtnLink] = useState("");
  const [secondBtnText, setSecondBtnText] = useState("");
  const [bannerText, setBannerText] = useState("");
  const [visible, setVisible] = useState("");
  const [fileType, setFileType] = useState("");
  const [bannerId, setBannerId] = useState("");

  // fetch banner
  const { data, refetch, loading } = useQuery(FETCH_BANNER, {
    variables: { isBottomPoster: false, isMobileItem: mobile },
  });
  const banner = data?.FetchBanner?.result || [];
  // video set into home page api
  const [AddBannerMutation, { loading: bannerUploading }] =
    useMutation(ADD_BANNER);

  const uploadBanner = async () => {
    try {
      const {
        data: { AddBanner },
      } = await AddBannerMutation({
        variables: {
          link,
          anchorLink,
          type: fileType,
          firstBtnLink,
          isMobileItem: mobile,
          firstBtnText,
          secondBtnLink,
          secondBtnText,
          bannerText,
        },
      });
      if (AddBanner.success) {
        onCloseModal();
        successNotify(AddBanner.message);
        refetch();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // update banner
  const [EditBannerMutation, { loading: updateBannerLoading }] =
    useMutation(EDIT_BANNER);
  const editBanner = async () => {
    try {
      const {
        data: { EditBanner },
      } = await EditBannerMutation({
        variables: {
          id: bannerId,
          link,
          anchorLink,
          type: fileType,
          firstBtnLink,
          firstBtnText,
          secondBtnLink,
          secondBtnText,
          bannerText,
          isMobileItem: mobile
        },
      });
      if (EditBanner.success) {
        onCloseModal();
        successNotify(EditBanner.message);
        refetch();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // delete banner
  const [DeleteBannerMutation, { loading: deleteLoading }] =
    useMutation(DELETE_BANNER);
  const deleteBanner = async (id) => {
    try {
      const {
        data: { DeleteBanner },
      } = await DeleteBannerMutation({
        variables: {
          id,
        },
      });
      if (DeleteBanner.success) {
        notify(DeleteBanner);
        refetch();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // position
  const [BannerPostionMutation] = useMutation(
    UPDATE_BANNER_POSITION
  );
  const updateBannerPostion = async (newData) => {
    const data = newData.map((item) => item._id);
    try {
      const {
        data: { UpdateBannerPosition },
      } = await BannerPostionMutation({
        variables: {
          data,
        },
      });
      if (UpdateBannerPosition.success) {
        notify(UpdateBannerPosition);
        refetch();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // dnd
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const { source, destination } = result;
    console.log(source, destination);
    const [removed] = banner.splice(source.index, 1);
    banner.splice(destination.index, 0, removed);
    updateBannerPostion(banner);
  };

  // file upload to aws
  const [FileUploadMutaion, { loading: uploadLoading }] =
    useMutation(FILE_UPLOAD);
  const uploadFile = async (e) => {
    const file = e.target.files[0];
    try {
      const {
        data: { SingleUpload },
      } = await FileUploadMutaion({
        variables: {
          file,
        },
      });
      // console.log(SingleUpload)
      if (SingleUpload.success) {
        let type = file.type.includes("video") ? "video" : "image";
        setLink(SingleUpload.filename);
        setFileType(type);
        // uploadBanner(SingleUpload.filename, type);
      } else {
        warnNotify(SingleUpload.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleEditBanner = ({ _id, link, anchorLink, type, ...data }) => {
    setLink(link);
    setFileType(type);
    setAnchorLink(anchorLink);
    setBannerId(_id);
    setFirstBtnLink(data?.firstBtnLink || "");
    setFirstBtnText(data?.firstBtnText || "");
    setSecondBtnText(data?.secondBtnText || "");
    setSecondBtnLink(data?.secondBtnLink || "");
    setBannerText(data?.bannerText || "");
    setVisible(true);
  };

  const onCloseModal = () => {
    setVisible(false);
    setLink("");
    setFileType("");
    setAnchorLink("");
    setBannerId("");
    setFirstBtnLink("");
    setFirstBtnText("");
    setSecondBtnLink("");
    setSecondBtnText("");
    setBannerText("");
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  return (
    <Spin spinning={loading || deleteLoading}>
      <div className="landing-video">
        {banner?.length < 1 && <Empty />}
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                <Row gutter={[16, 16]}>
                  {banner.map((bnr, index) => (
                    <Col lg={24} key={bnr._id}>
                      <Draggable
                        key={bnr._id}
                        draggableId={bnr._id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <div
                              style={{
                                width: "100%",
                                height: "100%",
                                marginBottom: "40px",
                              }}
                            >
                              <Card
                                bodyStyle={{ padding: 0 }}
                                style={{
                                  width: 300,
                                }}
                                hoverable
                                cover={
                                  bnr?.type === "video" ? (
                                    <video
                                      key={bnr?.link}
                                      width="100%"
                                      height="100%"
                                      autoPlay
                                      loop
                                      style={{ objectFit: "fill" }}
                                      muted
                                    >
                                      <source src={getFile(bnr?.link)} />
                                    </video>
                                  ) : (
                                    <img
                                      width="100%"
                                      alt=""
                                      src={getFile(bnr?.link)}
                                    />
                                  )
                                }
                                actions={[
                                  <EditOutlined
                                    key="pencil"
                                    onClick={() => handleEditBanner(bnr)}
                                  />,
                                  <Popconfirm
                                    title="Are you sure?"
                                    onConfirm={() => deleteBanner(bnr?._id)}
                                    okButtonProps={{ danger: "true" }}
                                  >
                                    <DeleteOutlined
                                      key="ellipsis"
                                      // onClick={() => deleteBanner(bnr?._id)}
                                    />
                                  </Popconfirm>,
                                ]}
                              />
                            </div>
                          </div>
                        )}
                      </Draggable>
                    </Col>
                  ))}
                </Row>
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <Modal
          title={bannerId ? "Update Banner" : "Add Banner"}
          open={visible}
          okText={bannerId ? "Update" : "Add"}
          onCancel={onCloseModal}
          onOk={bannerId ? editBanner : uploadBanner}
          okButtonProps={{
            disabled: !link,
            loading: bannerUploading || updateBannerLoading,
          }}
        >
          <div className="mb-4">
            <div className="mb-2">File *</div>
            <Spin spinning={uploadLoading}>
              <div className="mb-2">
                {fileType === "video" ? (
                  <video
                    width="100%"
                    height="100%"
                    autoPlay
                    loop
                    style={{ objectFit: "fill" }}
                    muted
                  >
                    <source src={getFile(link)} />
                  </video>
                ) : (
                  <img width="100%" src={getFile(link)} alt="" />
                )}
              </div>
            </Spin>
            <input
              type="file"
              accept="video/*,image/*"
              onChange={uploadFile}
              id="video-upload"
              disabled={uploadLoading}
              ref={fileInputRef}
            />
            {/* <label htmlFor="video-upload" className="btn-common">
              <i className="fas fa-plus-circle mr-2"></i>Upload
            </label> */}
          </div>
          <div className="mb-2">
            <div className="mb-2">Banner Redirect URL</div>
            <Input
              size="large"
              placeholder="Banner redirect url"
              value={anchorLink}
              onChange={(e) => setAnchorLink(e.target.value)}
            />
          </div>
          <h6>First Button:</h6>
          <div className="p-3 border mb-3">
            <div className="mb-2">
              <div className="mb-2">Button Text</div>
              <Input
                size="large"
                placeholder="Text"
                value={firstBtnText}
                onChange={(e) => setFirstBtnText(e.target.value)}
              />
            </div>
            <div className="mb-2">
              <div className="mb-2">Redirect URL (Optional)</div>
              <Input
                size="large"
                placeholder="Redirect url"
                value={firstBtnLink}
                onChange={(e) => setFirstBtnLink(e.target.value)}
              />
            </div>
          </div>
          <h6>Second Button:</h6>
          <div className="p-3 border">
            <div className="mb-2">
              <div className="mb-2">Button Text</div>
              <Input
                size="large"
                placeholder="Text"
                value={secondBtnText}
                onChange={(e) => setSecondBtnText(e.target.value)}
              />
            </div>
            <div className="mb-2">
              <div className="mb-2">Redirect URL</div>
              <Input
                size="large"
                placeholder="Redirect url"
                value={secondBtnLink}
                onChange={(e) => setSecondBtnLink(e.target.value)}
              />
            </div>
          </div>
          <div className="mb-2">
            <div className="mb-2">Banner Text (Optional)</div>
            <Input.TextArea
              size="large"
              placeholder="Text on the banner..."
              value={bannerText}
              onChange={(e) => setBannerText(e.target.value)}
            />
          </div>
        </Modal>

        <div className="text-right mt-4">
          <button
            className="btn-common"
            type="primary"
            onClick={() => setVisible(true)}
          >
            <i className="fas fa-plus-circle mr-2"></i> Add Banner
          </button>
        </div>
      </div>
    </Spin>
  );
};

export default SetupPoster;

const getListStyle = (isDraggingOver) => ({});
const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  ...draggableStyle,
});
