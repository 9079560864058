import React from "react";
import { useMutation } from "@apollo/react-hooks";
import { FILE_UPLOAD } from "../../graphql/modules";
import { message, Spin, Tabs } from "antd";
import "react-quill/dist/quill.snow.css";
import ProductBasic from "./ProductBasic";
import Sizes from "./Sizes";
import Gallery from "./Gallery";
import imageCompression from "browser-image-compression";

const options = {
  maxSizeMB: 1,
  maxWidthOrHeight: 1920,
  useWebWorker: true,
};

export const ProductDetails = ({
  productData,
  setProductData,
  gallery,
  setGallery,
  styles,
  setStyles,
  productId,
  fileList,
  setFileList,
  key,
  description,
  setDescription,
  sizeType
  // fileListForCropping
}) => {
  // const [fileListForCropping] = useState([]);

  // handle input
  const handleInput = (e) => {
    const { name, value, checked } = e.target;
    if (name === "price" || name==='discount' || name==='topPrice' || name==='bottomPrice') {
      setProductData({ ...productData, [name]: parseFloat(value) });
    } else if (name === "haveDiscount") {
      setProductData({ ...productData, haveDiscount: checked });
    } else {
      setProductData({ ...productData, [name]: value });
    }
  };

  
  // handle size
  const handleSizeBottom = (sizeList) => {
    setProductData({ ...productData, bottomSizing: sizeList });
  };
  const handleSizeTop = (sizeList) => {
    setProductData({ ...productData, topSizing: sizeList });
  };

  //description handler
  const onEditorStateChange = (value) => {
    setDescription(value);
  };

  // image handler
  const onRemove = (name) => {
    let images = gallery?.filter((item) => item !== name);
    setGallery(images);
    setFileList(images);
  };


  // upload image
  const [SingleUplaodMutation, { loading: uploadLoading }] =
    useMutation(FILE_UPLOAD);


  const handleUploadGallery = async (file) => {
    let images = [...gallery] || [];
    try {
      const compressedFile = await imageCompression(file, options);
      const {
        data: { SingleUpload },
      } = await SingleUplaodMutation({
        variables: {
          file: compressedFile,
        },
      });
      if (SingleUpload.success) {
        images.push(SingleUpload.filename);
        setGallery([...images]);
        setFileList([...images]);
      }
    } catch (error) {
      console.log(error.message);
    }
    // e.persist();
  };

  const handleUplaodGalleryMultiple = async (e) => {
    const MAX_LENGTH = 4 - gallery?.length;
    if (Array.from(e.target.files).length > MAX_LENGTH) {
      e.preventDefault();
      message.warn("Cannot upload files more than 4");
      return;
    }

    const { files = [] } = await e.target;
    let images = [...gallery] || [];

    for (var i = 0; i < files.length; i++) {
      try {
        // const compressedFile = await imageCompression(files[i], options);
        const {
          data: { SingleUpload },
        } = await SingleUplaodMutation({
          variables: {
            file: files[i],
          },
        });
        if (SingleUpload.success) {
          images.push(SingleUpload.filename);
          await setGallery([...images]);
          await setFileList([...images]);
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };


  return (
    <div>
      <Tabs key={key} tabPosition="left" defaultActiveKey="details">
        <Tabs.TabPane
          tab={
            <span>
              <i className="fas fa-tags mr-2"></i>Details
            </span>
          }
          key="details"
        >
          <ProductBasic
            productData={productData}
            sizeType={sizeType}
            description={description}
            handleInput={handleInput}
            onEditorStateChange={onEditorStateChange}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={
            <span>
              <i className="fas fa-balance-scale mr-2"></i> Inventory
            </span>
          }
          key="size"
        >
          <Sizes
            productData={productData}
            handleSizeBottom={handleSizeBottom}
            handleSizeTop={handleSizeTop}
            productId={productId}
            setProductData={setProductData}
            sizeType={sizeType}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={
            <span>
              <i className="fas fa-book-reader mr-2"></i>Gallery
            </span>
          }
          key="description"
        >
          <Spin spinning={uploadLoading}>
            <Gallery
              isAccessories={productData?.isAccessories}
              fileList={fileList}
              handleUploadGallery={handleUploadGallery}
              handleUplaodGalleryMultiple={handleUplaodGalleryMultiple}
              onRemove={onRemove}
              styles={styles}
              setStyles={setStyles}
            />
          </Spin>
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

ProductDetails.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "blockquote"],
    [{ indent: "-1" }, { indent: "+1" }],
  ],
  clipboard: {
    matchVisual: false,
  },
};
