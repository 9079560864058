import { MenuOutlined } from "@ant-design/icons";
import { Table } from "antd";
import React, { useEffect, useState } from "react";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import arrayMove from "array-move";
import { userRole } from "../../config";
import { useSelector } from "react-redux";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { FETCH_HOME_SECTION_SEQUENCE, UPDATE_HOME_SECTION_SEQUENCE } from "../../graphql/modules";
import { warnNotify } from "../../util";

const DragHandle = sortableHandle(() => (
  <MenuOutlined style={{ cursor: "grab", color: "#999" }} />
));
const SortableItem = sortableElement((props) => <tr {...props} />);
const SortableContainer = sortableContainer((props) => <tbody {...props} />);
const SectionSort = () => {
  const [dataSource, setDataSource] = useState([]);
  const { user } = useSelector((state) => state.auth);

  const { data, loading, refetch } = useQuery(FETCH_HOME_SECTION_SEQUENCE, {
    fetchPolicy: "cache-and-network",
  });

  const result = data?.FetchHomePageSectionsSequence?.result || [];

  // update position

   // update drag and drop
   const [DragAndDropMutation] = useMutation(UPDATE_HOME_SECTION_SEQUENCE);
   const onSectionPostionUpdate = async (newData = []) => {
     const newArray = newData.map((item) => item._id);
     try {
       const {
         data: { UpdateHomePageSectionPosition },
       } = await DragAndDropMutation({
         variables: {
           data: newArray,
         },
       });
       if (UpdateHomePageSectionPosition.success) {
         refetch();
       } else {
         warnNotify(UpdateHomePageSectionPosition.message);
       }
     } catch (error) {
       console.log(error.message);
     }
   };

  const updateDataState = () => {
    setDataSource(result);
  };

  useEffect(() => {
    if (Array.isArray(result) && !loading) {
      updateDataState();
    }
  }, [result]);

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = dataSource.findIndex(
      (item) => item._id === restProps["data-row-key"]
    );
    return <SortableItem index={index} {...restProps} />;
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMove(
        [].concat(dataSource),
        oldIndex,
        newIndex
      ).filter((el) => !!el);
        setDataSource(newData);
        console.log(newData)
        onSectionPostionUpdate(newData);
    }
  };
  const DraggableContainer = (props) => (
    <SortableContainer
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const columns = [
    { title: "#", render: (_text, _row, index) => index + 1 },
    {
      title: "Sort",
      render: () => <DragHandle />,
      className: "drag-visible",
      hideen: user.role === userRole.Moderator,
    },
    { title: "Section Name", dataIndex: "name" },
  ].filter((item) => !item.hideen);
  return (
    <div>
      <Table
        columns={columns}
        dataSource={dataSource}
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
          },
        }}
        rowKey={(row) => row._id}
      />
    </div>
  );
};
export default SectionSort;
