import React, { Fragment } from "react";
import { Input, List } from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  DeleteFilled,
  EditFilled,
} from "@ant-design/icons";

const SizeList = ({
  item,
  sizeId,
  qtcUpdate,
  setQtcUpdate,
  setSizeId,
  editSizingFromStore,
  handleQtcEdit,
  deleteSizeFromStore,
  name,
  handleChangequantity,
}) => {
  return (
    <List.Item className="d-flex justify-content-between align-items-center">
      <div>
        <p style={{ margin: "0" }}>{item?.size?.name ?? ""}</p>
      </div>
      <div>
        {sizeId === item.size?._id ? (
          <Input
            value={qtcUpdate}
            style={{ width: "100px" }}
            type="number"
            onChange={(e) => handleChangequantity(name, e.target.value)}
          />
        ) : (
          item?.quantity ?? 0
        )}
      </div>
      <div>
        {sizeId === item.size?._id ? (
          <Fragment>
            <CheckOutlined className="mr-2" onClick={editSizingFromStore} />
            <CloseOutlined
              onClick={() => {
                setSizeId("");
                setQtcUpdate({});
              }}
            />
          </Fragment>
        ) : (
          <Fragment>
            <EditFilled
              className="mr-2"
              onClick={() => handleQtcEdit(item.size._id, item.quantity)}
            />
           
              <DeleteFilled style={{ color: "red" }} onClick={() => deleteSizeFromStore(item?.size?._id)}/>
          </Fragment>
        )}
      </div>
    </List.Item>
  );
};

export default SizeList;