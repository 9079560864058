import { Button, Input, Modal, Popconfirm } from "antd";
import React from "react";
import { OrderStatus } from "../../config";

export const CancelOrder = ({
  statusLoading,
  cancelNote,
  setCancelNote,
  updateOrderStatus,
  visibleForCancel,
  setVisibleForCancel,
  status,
}) => {
  const closeModal = () => {
    setCancelNote("");
    setVisibleForCancel(false);
  };
  return (
    <div className="cancel-order d-flex justify-content-between align-items-center">
      {status === OrderStatus.Canceled ? (
        <div>
          <h6>This order is cancelled</h6>
          <p>
            Do you want to go with <b>Prepared to shipping</b>?
          </p>
        </div>
      ) : (
        <h6>Do you want to cancel the order?</h6>
      )}
      <Popconfirm
        okType={status === OrderStatus.Canceled ? "primary" : "danger"}
        title="Are you sure?"
        onConfirm={
          status === OrderStatus.Canceled
            ? () => updateOrderStatus(getEnum(OrderStatus.Pending))
            : () => setVisibleForCancel(true)
        }
      >
        {status ===OrderStatus.PreOrder}
        <Button type="primary" danger={status === OrderStatus.Canceled? false: true}>
          {status === OrderStatus.Canceled ? "Pending" : "Cancel"}
        </Button>
      </Popconfirm>
      <Modal
        open={visibleForCancel}
        okType="danger"
        onCancel={closeModal}
        onOk={() => updateOrderStatus(getEnum(OrderStatus.Canceled))}
        okButtonProps={{
          disabled: !cancelNote || statusLoading,
          loading: statusLoading,
        }}
      >
        <div className="mb-3">
          <label>Please write the reson for cancellation*</label>
          <Input.TextArea
            onChange={(e) => setCancelNote(e.target.value)}
            size="large"
            value={cancelNote || ""}
            placeholder="write..."
          />
        </div>
      </Modal>
    </div>
  );
};

const getEnum = (status) => {
  switch (status) {
    case OrderStatus.Shipped:
      return "shipped";
    case OrderStatus.Pending:
      return "preparingToShip";
    case OrderStatus.Canceled:
      return "canceled";
    default:
      return "delivered";
  }
};
