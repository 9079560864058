import React, { useEffect, useState } from "react";
import {
  DELETE_SUBCATEGORY,
  FETCH_SINGLE_CATEGORY,
  FILE_UPLOAD,
  SUBCATEGORY_LIST_UPDATE,
} from "../graphql/modules";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { DynamicList } from "../components/Shared";
import { Button, Input, Modal, Popconfirm, Select, Spin, Switch, Upload } from "antd";
import { Link } from "react-router-dom";
import { CREATE_SUBCATEGORY, UPDATE_SUBCATEGORY } from "../graphql/modules";
import { errorNotify, getFile, successNotify, warnNotify } from "../util";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import arrayMove from "array-move";
import { MenuOutlined, UploadOutlined } from "@ant-design/icons";
import { ProductList } from "../components/Products";
import { useSelector } from "react-redux";
import { userRole } from "../config";
import { CreateGroup, GroupManage } from "../components/CategoryView";

const DragHandle = sortableHandle(() => (
  <MenuOutlined style={{ cursor: "grab", color: "#999" }} />
));
const SortableItem = sortableElement((props) => <tr {...props} />);
const SortableContainer = sortableContainer((props) => <tbody {...props} />);

export const CategoryView = ({ match }) => {
  // category id
  const categoryId = match.params.id;

  // initial state
  const [subcategoryId, setSubCategoryId] = useState("");
  const [subcategoryName, setSubCategoryName] = useState("");
  const [subcategoryGroup, setSubCategoryGroup] = useState("");
  const [filterGroupId, setFilterGroupId] = useState("");
  const [visible, setVisible] = useState(false);
  const [coverImage, setCoverImage] = useState("");
  // const [prodVisible, setProdVisible] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const { user } = useSelector((state) => state.auth);

  // fetch subcategory
  const { data, loading, refetch } = useQuery(FETCH_SINGLE_CATEGORY, {
    variables: {
      categoryId,
    },
    fetchPolicy: "cache-and-network",
  });
  const result = data?.FetchSingleCategoryAdmin?.result;
  const groups = data?.FetchSingleCategoryAdmin?.result?.subcategoryGroups;
  const categoryName = data?.FetchSingleCategoryAdmin?.result?.name || "";

  useEffect(() => {
    if (Array.isArray(groups)) {
      setDataSource(groups[0]?.subcategory || []);
      setFilterGroupId(groups[0]?._id);
    }
  }, [result]);

  // create category api
  const [CreateSubCategoryMutation, { loading: createLoading }] =
    useMutation(CREATE_SUBCATEGORY);
  const createSubCategory = async () => {
    try {
      const {
        data: { AddSubcategory },
      } = await CreateSubCategoryMutation({
        variables: {
          categoryId,
          name: subcategoryName,
          coverImage,
          subcategoryGroup,
          sizeType: result?.sizeType,
        },
      });
      if (AddSubcategory.success) {
        successNotify(AddSubcategory.message);
        closeModal();
        setSubCategoryGroup("");
        refetch();
      } else {
        warnNotify(AddSubcategory.message);
      }
    } catch (error) {}
  };

  // uypdate category
  const [UpdateSubCategoryMutation, { loading: updateLoading }] =
    useMutation(UPDATE_SUBCATEGORY);

  const updateSubCategory = async () => {
    try {
      const {
        data: { EditSubcategory },
      } = await UpdateSubCategoryMutation({
        variables: {
          subcategoryId,
          name: subcategoryName,
          subcategoryGroupId: subcategoryGroup,
          coverImage,
        },
      });
      if (EditSubcategory.success) {
        successNotify(EditSubcategory.message);
        refetch();
        closeModal();
      } else {
        warnNotify(EditSubcategory.message);
      }
    } catch (error) {
      errorNotify(error.message);
    }
  };

  const handleUpdateCategory = async(data, value) => {
    try {
      const {
        data: { EditSubcategory },
      } = await UpdateSubCategoryMutation({
        variables: {
          subcategoryId: data?._id,
          name: data?.name,
          subcategoryGroupId: filterGroupId,
          coverImage: data?.coverImage,
          isShowAllSize: value,
        },
      });
      if (EditSubcategory.success) {
        successNotify(EditSubcategory.message);
        refetch();
        closeModal();
      } else {
        warnNotify(EditSubcategory.message);
      }
    } catch (error) {
      errorNotify(error.message);
    }
  };

  // delete subcategory
  const [DeleteSubcategoryMutation] = useMutation(DELETE_SUBCATEGORY);
  const deleteSubcategory = async (id) => {
    try {
      const {
        data: { DeleteSubcategory },
      } = await DeleteSubcategoryMutation({
        variables: {
          subcategoryId: id,
        },
      });
      if (DeleteSubcategory.success) {
        successNotify(DeleteSubcategory.message);
        refetch();
      } else {
        warnNotify(DeleteSubcategory.message);
      }
    } catch (error) {
      errorNotify(error.message);
    }
  };

  // handle modal
  const closeModal = () => {
    setSubCategoryName("");
    setVisible(false);
    setSubCategoryId("");
    setSubCategoryGroup("");
  };
  const openModal = (data) => {
    setSubCategoryName(data?.name || "");
    setCoverImage(data?.coverImage);
    setSubCategoryId(data?._id);
    setVisible(true);
    setSubCategoryGroup(filterGroupId);
  };

  // drag and drop
  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMove(
        [].concat(dataSource),
        oldIndex,
        newIndex
      ).filter((el) => !!el);
      setDataSource(newData);
      subcategoryDnD(newData);
    }
  };

  // drag container
  const DraggableContainer = (props) => (
    <SortableContainer
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );
  // function findIndex base on Table rowKey props
  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = dataSource?.findIndex(
      (item) => item._id === restProps["data-row-key"]
    );
    return <SortableItem index={index} {...restProps} />;
  };

  const [DragAndDropMutation] = useMutation(SUBCATEGORY_LIST_UPDATE);
  const subcategoryDnD = async (newData = []) => {
    const newArray = newData?.map((item) => item._id);
    try {
      const {
        data: { UpdateSubcategoryPosition },
      } = await DragAndDropMutation({
        variables: {
          data: newArray,
        },
      });
      if (UpdateSubcategoryPosition.success) {
      } else {
        warnNotify(UpdateSubcategoryPosition.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // coverimage upload
  const [FileUploadMutation, { loading: coverLoading }] =
    useMutation(FILE_UPLOAD);
  const onUploadCover = async (e) => {
    try {
      const {
        data: { SingleUpload },
      } = await FileUploadMutation({
        variables: {
          file: e.file?.originFileObj,
        },
      });
      if (SingleUpload?.success) {
        setCoverImage(SingleUpload?.filename);
      } else {
        warnNotify(SingleUpload?.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // table column
  const columns = [
    {
      title: "#",
      render: (_text, _row, index) => index + 1,
    },
    {
      title: "Sort",
      render: () => <DragHandle />,
      className: "drag-visible",
      hideen: user.role === userRole.Moderator,
    },
    {
      title: "Subcategory Name",
      dataIndex: "name",
    },
    {
      title: "All size visible",
      dataIndex: "isShowAllSize",
      render: (text, record) => (
        <Switch
          checked={text}
          onChange={(value) => handleUpdateCategory(record, value)}
          checkedChildren="All sizes"
          unCheckedChildren="Stock only"
        />
      ),
    },
    {
      title: "Image",
      dataIndex: "coverImage",
      render: (text) => (
        <img
          src={getFile(text)}
          width="100"
          alt=""
          style={{ height: "100px", objectFit: "cover" }}
        />
      ),
    },
    {
      title: "Type",
      dataIndex: "sizeType",
    },
    {
      title: "Action",
      width: "15%",
      render: (text, record) => (
        <React.Fragment>
          {user.role !== userRole.Moderator && (
            <div style={{ display: "inline-block" }}>
              <div
                onClick={() => openModal(record)}
                style={{ cursor: "pointer", display: "inline-block" }}
              >
                <i className="fas fa-pencil mr-2"></i>
              </div>
              {record?.products?.length === 0 && (
                <Popconfirm
                  title="Are you sure?"
                  okText="Delete"
                  okType="danger"
                  onConfirm={() => deleteSubcategory(record._id)}
                >
                  <div style={{ cursor: "pointer", display:'inline-block' }}>
                    <i className="fas fa-trash mr-2"></i>
                  </div>
                </Popconfirm>
              )}
            </div>
          )}

          <Link to={`${match.url}/${record?._id}`}>
            <i className="fas fa-eye" style={{ cursor: "pointer" }}></i>
          </Link>
        </React.Fragment>
      ),
    },
  ].filter((item) => !item.hideen);

  // const onAddProductModal = () => {
  //   setProdVisible(true);
  // };

  const onFilterGroup = (id) => {
    const { subcategory } = groups.find((tt) => tt._id === id);
    setDataSource(subcategory);
    setFilterGroupId(id);
  };

  // create button
  let createBtn = (
    <div className="d-flex">
      {groups?.length === 0 && !loading && (
        <Link
          // onClick={onAddProductModal}
          to={`/add-product/${categoryId}?size-type=${result?.sizeType}&cat-sub=categoryId`}
          className="btn-common mr-3"
          // type="primary"
        >
          <i className="fas fa-plus-circle mr-2"></i> Add Product
        </Link>
      )}
      {result?.products?.length < 1 && (
        <React.Fragment>
          <CreateGroup categoryId={categoryId} refetch={refetch} />{" "}
          <button
            onClick={() => setVisible(true)}
            className="btn-common ml-3"
            type="primary"
          >
            <i className="fas fa-plus-circle mr-2"></i> Create Subcategory
          </button>
        </React.Fragment>
      )}
    </div>
  );
  const tableFilter = (
    <div className="text-right">
      <div>
        <label className="mr-3">Groups:</label>
        <Select
          size="large"
          className="text-left"
          placeholder="Select Group"
          style={{ width: "250px" }}
          onChange={onFilterGroup}
          value={filterGroupId}
        >
          {groups?.map((grp) => (
            <Select.Option key={grp?._id}>{grp?.name}</Select.Option>
          ))}
        </Select>
      </div>
    </div>
  );

  const title = (
    <div className="d-flex align-items-center">
      <div className="mr-5">
        {`${
          result?.products?.length > 0 ? "Products" : "Subcategory"
        } list of ${categoryName} category`}
      </div>
      {groups?.length > 0 && <GroupManage groups={groups} refetch={refetch} />}
    </div>
  );

  return (
    <React.Fragment>
      <DynamicList
        title={title}
        loading={loading}
        columns={columns}
        data={dataSource || []}
        tableHide={result?.products?.length > 0}
        actions={createBtn}
        tableFilter={groups?.length > 0 ? tableFilter : ""}
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
          },
        }}
      >
        <ProductList
          data={result?.products || []}
          sizeType={result?.sizeType}
          refetch={refetch}
          from={{
            type: "categoryId",
            id: categoryId,
          }}
        />
      </DynamicList>

      {/* <ProductForm
        visible={prodVisible}
        setVisible={setProdVisible}
        data={{}}
        sizeType={result?.sizeType}
        refetch={refetch}
        from={{
          type: "categoryId",
          id: categoryId,
        }}
      /> */}

      <Modal
        title={subcategoryId ? "Update subcategory" : "Add new subcategory"}
        open={visible}
        onOk={subcategoryId ? updateSubCategory : createSubCategory}
        okText={subcategoryId ? "Update" : "Create"}
        okButtonProps={{
          disabled: !subcategoryName || !subcategoryGroup,
          loading: updateLoading || createLoading,
        }}
        onCancel={closeModal}
      >
        <div className="mb-3">
          <div className="mb-1" style={{ fontSize: "16px" }}>
            Subcategory Name
          </div>
          <Input
            size="large"
            value={subcategoryName}
            placeholder="Subcategory name"
            onChange={(e) => setSubCategoryName(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <div className="mb-1" style={{ fontSize: "16px" }}>
            Assignee group
          </div>
          <Select
            size="large"
            className="text-left"
            placeholder="Select Group"
            style={{ width: "100%" }}
            value={subcategoryGroup}
            onChange={(value) => setSubCategoryGroup(value)}
          >
            {groups?.map((grp) => (
              <Select.Option key={grp?._id}>{grp?.name}</Select.Option>
            ))}
          </Select>
        </div>

        <div className="mt-2">
          <div>
            <label>Cover Image</label>
          </div>
          <Spin spinning={coverLoading}>
            <img
              src={getFile(coverImage)}
              alt="cover"
              width="150"
              height="100"
            />
            <div className="mt-2">
              <Upload showUploadList={false} onChange={onUploadCover}>
                <Button type="primary" icon={<UploadOutlined />}>
                  Upload
                </Button>
              </Upload>
            </div>
          </Spin>
        </div>
      </Modal>
    </React.Fragment>
  );
};
