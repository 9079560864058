import { UploadOutlined } from "@ant-design/icons";
import { Col, Row, Spin, Switch, Upload, message } from "antd";
import React from "react";
import ImgCrop from "antd-img-crop";
// import SingleCrop from "./SingleCrop";
import { useState } from "react";
import { catSubType } from "../../config";
import { ImageList } from "./Gallery/ImageList";
import { useMutation } from "@apollo/react-hooks";
import { FILE_UPLOAD } from "../../graphql/modules";
import { StyleImages } from "./Gallery/StyleImages";
// import { StyleImg } from "./Gallery/StyleImg";

export const Gallery = ({
  isAccessories,
  sizeType,
  galleryState,
  setGalleryState
}) => {
  // state declare

  const [galleryImageMode, setGalleryImageMode] = useState(true);

  // image upload api
  const [SingleUplaodMutation, { loading: uploadLoading }] =
    useMutation(FILE_UPLOAD);

  const handleUploadGallery = async (e) => {
    // const MAX_LENGTH = 4 - galleryState[galleryType].length;
    // if (Array.from(e.target.files).length > MAX_LENGTH) {
    //   e.preventDefault();
    //   message.warning("Cannot upload files more than 4");
    //   return;
    // }

    const { files = [] } = await e.target;
    let images = [...galleryState[galleryType]] || [];

    for (var i = 0; i < files.length; i++) {
      try {
        // const compressedFile = await imageCompression(files[i], options);
        const {
          data: { SingleUpload },
        } = await SingleUplaodMutation({
          variables: {
            file: files[i],
          },
        });
        if (SingleUpload.success) {
          images.push(SingleUpload.filename);
          setGalleryState({ ...galleryState, [galleryType]: images });
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };



  // product type
  const galleryType =
    sizeType === catSubType.fullSet
      ? "gallery"
      : sizeType?.toLowerCase() + "Gallery";


  return (
      <Row gutter={[16, 16]}>
        {isAccessories && (
          <Col lg={24}>
            <div className="mb-5">
              <StyleImages galleryState={galleryState} setGalleryState={setGalleryState}/>
              {/* <StyleImg styles={styles} setStyles={setStyles} /> */}
            </div>
          </Col>
        )}

        <Col lg={24}>
          <Spin spinning={uploadLoading}>
            <h5>Gallery</h5>
            <small>
              Switch to {galleryImageMode ? "crop" : "multiple"} image uplaod
            </small>
            <Switch
              style={{ marginLeft: "5px" }}
              size="small"
              checked={galleryImageMode}
              onClick={(check) => setGalleryImageMode(check)}
            />
            <div className="d-flex flex-wrap" style={{ marginTop: "10px" }}>
              <ImageList
                galleryState={galleryState}
                galleryType={galleryType}
                setGalleryState={setGalleryState}
              />

              {!galleryImageMode ? (
                  <ImgCrop
                    rotationslider={true}
                    showGrid
                    quality={1}
                    onModalOk={(data) =>
                      handleUploadGallery({ target: { files: [data] } })
                    }
                    aspect={5 / 7}
                  >
                    <Upload
                      showUploadList={false}
                      className="upload-picture"
                      listType="picture-card"
                    >
                      <UploadOutlined /> Upload
                    </Upload>
                  </ImgCrop>
                ) : (
                  <div className="d-inline-block">
                    <label
                      style={{
                        padding: "60px 22px",
                        aspectRatio: "5/7",
                        border: "1px dotted black",
                      }}
                      htmlFor="upload-gallery"
                    >
                      + Upload
                    </label>
                    <input
                      type="file"
                      onChange={handleUploadGallery}
                      className="d-none"
                      id="upload-gallery"
                      multiple
                      accept="image/*,video/*"
                      style={{width:'100px'}}
                    />
                  </div>
                )}
            </div>
          </Spin>
        </Col>
      </Row>
  );
};
