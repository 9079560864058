import React from "react";
import { OrderItem } from "./OrderItem";
import { OrderStatus } from "../../config";
import { Tag } from "antd";

export const OrderItemContainer = ({
  orderData,
  title,
  status,
  isAllOrder,
}) => {
  return (
    <div className="mt-3">
      <h4>{title}:</h4>
      {orderData?.items?.map((item) => (
        <OrderItem
          key={item?._id}
          data={{
            ...item,
            haveDiscount:
              item?.haveDiscount &&
              ((item?.discountType !== "preOrder" && !item?.isPreOrderItem) ||
                (item?.discountType !== "instock" && item?.isPreOrderItem)),
          }}
        />
      ))}
      {isAllOrder && (
        <div>
          <p className="font-weight-bold">
            Shipping Method: {orderData?.shippingType}
          </p>
        </div>
      )}
      {isAllOrder && (
        <div>
          {status !== "Shipped" && (
            <p className="font-weight-bold">
              Status:{" "}
              <Tag color="Orange" style={{ fontSize: "14px" }}>
                {status}
              </Tag>
            </p>
          )}
          {status !== OrderStatus.Pending &&
            status !== OrderStatus.Canceled && (
              <p className="font-weight-bold">
                Tracking Url :{" "}
                <a
                  href={orderData?.trackingUrl}
                  target="_blank"
                  style={{ color: "#3366CC " }}
                  rel="noreferrer"
                >
                  {orderData?.trackingNumber}
                </a>
              </p>
            )}
        </div>
      )}
    </div>
  );
};
