import React from "react";
import { Input, Modal, Spin } from "antd";
import { UPDATE_HOME_PAGE_SECTION } from "../../graphql/modules";
import { useMutation } from "@apollo/react-hooks";
import { useState } from "react";
import { notify } from "../../util";
import { CheckCircleOutlined, SelectOutlined } from "@ant-design/icons";

const SetupLandingCategory = ({ type, category, loading, data }) => {
  const [typeOfCatOrSub, setTypeOfCatOrSub] = useState(
    data?.isCategory ? "category" : "subcategory"
  );
  const [catSubId, setCatSubId] = useState(
    data?.isCategory ? data?.category?._id : data?.subcategory?._id
  );
  const [visible, setVisible] = useState(false);
  const [catOrSubName, setCatOrSubName] = useState(
    data?.isCategory ? data?.category?.name : data?.subcategory?.name
  );
  const [inputData, setInputData] = useState({
    sectionTitle: data?.sectionTitle || "",
    categoryBackground: data?.categoryBackground || "",
    isCategory: data?.isCategory,
  });

  const [updateFeaturedCategory, { loading: addLoading }] = useMutation(
    UPDATE_HOME_PAGE_SECTION
  );
  const onUpdateFeaturedCategory = async () => {
    try {
      const {
        data: { UpdateHomePageSection },
      } = await updateFeaturedCategory({
        variables: {
          type,
          inputData: {
            ...inputData,
            [typeOfCatOrSub]: catSubId,
            isCategory: typeOfCatOrSub === "category",
          },
        },
      });
      notify(UpdateHomePageSection);
    } catch (error) {}
  };

  // handle select cat or sub

  const checkCatSubType = (id, type, name) => {
    setCatSubId(id);
    setTypeOfCatOrSub(type);
    setCatOrSubName(name);
    setVisible(false)
  };

  const handleOpenModal = () => {
    setVisible(true);
  };

  const handleCloseModal = () => {
    setVisible(false);
  };

  return (
    <Spin spinning={loading || addLoading}>
      <div className="add-product-modal">
        <div className="product-wrapper">
          {/* {!category.length && (
            <Empty
              description="No category added yet."
              image="https://virtualshipment.in/images/no-data.png"
              className="w-100 empty-text"
            />
          )} */}
          <div>
            <label>Select Category / Subcategory</label>
          </div>
          <Input
            style={{ width: "100%" }}
            size="large"
            value={catOrSubName}
            onClick={handleOpenModal}
          />
        </div>
        <div className="product-wrapper">
          <div>
            <label>Section Title</label>
          </div>
          <Input
            style={{ width: "100%" }}
            placeholder="section title"
            size="large"
            onChange={(e) =>
              setInputData({ ...inputData, sectionTitle: e.target.value })
            }
            value={inputData?.sectionTitle}
          />
        </div>
        {type === "Featured" && (
          <div className="product-wrapper">
            <div>
              <label>Color Code (Hex)</label>
            </div>
            <Input
              style={{ width: "100%" }}
              placeholder="Ex. #fff"
              size="large"
              onChange={(e) =>
                setInputData({
                  ...inputData,
                  categoryBackground: e.target.value,
                })
              }
              value={inputData?.categoryBackground}
            />
          </div>
        )}
      </div>
      <div className="text-right mt-4">
        <button
          className="btn-common"
          type="primary"
          onClick={onUpdateFeaturedCategory}
        >
          <i className="fas fa-plus-circle mr-2"></i> Update
        </button>
      </div>
      <Modal
        open={visible}
        title="Select category or subcategory"
        onCancel={handleCloseModal}
        footer={false}
      >
        {category?.map((item) => (
          <div key={item?._id} className="mb-3">
            <div>
              <span className="mr-2">&#8226;</span>
              <span className="mr-3">{item?.name}</span>
              {item?.subcategory?.length < 1 && (
                <SelectOutlined
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    checkCatSubType(item?._id, "category", item?.name)
                  }
                />
              )}
              {item?._id === catSubId && (
                <CheckCircleOutlined
                  style={{
                    color: "green",
                    fontWeight: "bold",
                    marginLeft: "25px",
                    fontSize: "16px",
                  }}
                />
              )}
            </div>
            <div className="ml-5">
              {item?.subcategory?.map((sub) => (
                <div key={sub?._id}>
                  <span className="mr-3">{sub?.name}</span>
                  <SelectOutlined
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      checkCatSubType(sub?._id, "subcategory", sub?.name)
                    }
                  />
                  {sub?._id === catSubId && (
                    <CheckCircleOutlined
                      style={{
                        color: "green",
                        fontWeight: "bold",
                        marginLeft: "25px",
                        fontSize: "16px",
                      }}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
        ))}
      </Modal>
    </Spin>
  );
};

export default SetupLandingCategory;
