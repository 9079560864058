import gql from "graphql-tag";

export const FETCH_ALL_ORDER = gql`
  query FetchAllOrderAdmin(
    $limit: Int
    $offset: Int
    $email: String
    $orderId: String
    $status: String
    $code: String
    $productName: String
    $customerName: String
  ) {
    FetchAllOrderAdmin(
      limit: $limit
      offset: $offset
      email: $email
      orderId: $orderId
      status: $status
      code: $code
      productName: $productName
      customerName: $customerName
    ) {
      code
      success
      message
      count
      result {
        _id
        email
        isGuestUser
        createdAt
        status
        orderId
        grandTotal
        isRefunded
        coupon {
          _id
        }
        user {
          _id
          firstname
          lastname
          phone
          email
          birthDate
        }
        cardType
        items {
          _id
          topSizing
          bottomSizing
          product {
            _id
            name
            price
          }
        }
      }
    }
  }
`;

export const FETCH_SINGLE_ORDER = gql`
  query FetchSingleOrderAdmin($orderId: ID) {
    FetchSingleOrderAdmin(orderId: $orderId) {
      code
      success
      message
      result {
        _id
        email
        status
        orderId
        totalPrice
        grandTotal
        tax
        shippingCherge
        shippingType
        shippingDurationText
        cancelationNote
        trackingNumber
        preOrderTrackingNumber
        preOrderTrackingUrl
        preOrderShippingType
        trackingUrl
        isRefunded
        cardType
        cardNo
        wasPreOrder
        couponDiscount
        orderNote
        discountOnTotalPrice
        isGuestUser
        couponOwner {
          _id
        }
        coupon {
          code
          discountInPercent
        }
        shippingAddress {
          _id
          country
          state
          zipCode
          city
          address
          apertment
          firstname
          lastname
          phone
        }
        billingAddress {
          country
          state
          zipCode
          city
          address
          apertment
          firstname
          lastname
          phone
        }
        items {
          _id
          product {
            _id
            styles{
              name
              url
            }
            isAccessories
          }
          isFreePoolItem
          isShipped
          isPreOrderItem
          isTopPre
          isBottomPre
          topColor
          topColorName
          bottomColor
          bottomColorName
          name
          quantity
          topSizing
          bottomSizing
          style
          price
          gallery
          discount
          discountType
          haveDiscount
          code
          topPrice
          bottomPrice
        }
      }
    }
  }
`;

export const ORDER_STATUS_UPDATE = gql`
  mutation UpdateOrderStatus(
    $orderId: ID
    $status: OrderStatusEnum
    $trackingNumber: String
    $preOrderTrackingNumber: String,
    $partialShippedItemsId: [ID]
    $preOrderTrackingUrl: String,
    $trackingUrl: String
    $cancelationNote: String
  ) {
    UpdateOrderStatus(
      orderId: $orderId
      status: $status
      trackingNumber: $trackingNumber
      partialShippedItemsId: $partialShippedItemsId
      preOrderTrackingUrl: $preOrderTrackingUrl,
      preOrderTrackingNumber: $preOrderTrackingNumber
      trackingUrl: $trackingUrl
      cancelationNote: $cancelationNote
    ) {
      code
      success
      message
    }
  }
`;
