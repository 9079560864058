import { DeleteOutlined, DollarCircleOutlined } from "@ant-design/icons";
import { Col, Input, Row, Button, Checkbox } from "antd";
import React from "react";
import { useState } from "react";
import { catSubType } from "../../config";
import ProdDescription from "./Description/ProdDescription";

const ProductBasic = ({
  productData,
  handleInput,
  onEditorStateChange,
  description,
  sizeType,
}) => {
  const [code, setCode] = useState("");
  const onAddCode = () => {
    const data = productData?.code;
    data.push(code);
    handleInput({ target: { name: "code", value: data } });
    setCode("");
  };
  const deleteCode = (name) => {
    const data = [...productData?.code].filter((item) => item !== name);
    handleInput({ target: { name: "code", value: data } });
  };

  const price =
    sizeType === "FULL_SET"
      ? productData?.price
      : productData?.[sizeType?.toLowerCase() + "Price"];

  return (
    <Row gutter={[16, 16]}>
      <Col lg={8}>
        <label>Product Name</label>
        <Input
          size="large"
          placeholder="Product name"
          value={productData?.name || ""}
          onChange={handleInput}
          name="name"
        />
      </Col>
      {sizeType === catSubType.fullSet && (
        <Col lg={8}>
          <label>Price</label>
          <Input
            type="number"
            prefix={<DollarCircleOutlined />}
            size="large"
            placeholder="Price"
            onChange={handleInput}
            value={productData?.price || ""}
            name="price"
          />
        </Col>
      )}
      {sizeType === catSubType.top && (
        <Col lg={8}>
          <label>Price (Top)</label>
          <Input
            type="number"
            prefix={<DollarCircleOutlined />}
            size="large"
            placeholder="Price"
            onChange={handleInput}
            value={productData?.topPrice || ""}
            name="topPrice"
          />
        </Col>
      )}
      {sizeType === catSubType.bottom && (
        <Col lg={8}>
          <label>Price (Bottom)</label>
          <Input
            type="number"
            prefix={<DollarCircleOutlined />}
            size="large"
            placeholder="Price"
            onChange={handleInput}
            value={productData?.bottomPrice || ""}
            name="bottomPrice"
          />
        </Col>
      )}
      <Col lg={8}>
        <label>
          Sale Discount( %)
          <Checkbox
            checked={productData?.haveDiscount}
            onChange={handleInput}
            name="haveDiscount"
            className="ml-2"
          ></Checkbox>
        </label>
        <div className="d-flex">
          <Input
            type="number"
            size="large"
            style={{ width: "50%" }}
            disabled={!productData?.haveDiscount}
            placeholder="Discount"
            onChange={handleInput}
            value={productData?.discount || ""}
            name="discount"
          />
          {productData?.haveDiscount && (
            <Input
              style={{ width: "50%" }}
              disabled
              value={
                Math.round(price - (productData.discount * price) / 100) || ""
              }
              name="price"
            />
          )}
        </div>
      </Col>
      <Col lg={8}>
        <label>Product description</label>
        <ProdDescription data={description} onChange={onEditorStateChange} />
      </Col>
      <Col lg={8}>
        <label>Product code</label>
        <Input
          size="large"
          placeholder="Product code"
          onChange={(e) => setCode(e.target.value)}
          name="code"
          value={code}
          suffix={
            <Button type="primary" onClick={onAddCode} disabled={!code}>
              Add
            </Button>
          }
        />
        {Array.isArray(productData?.code) &&
          productData?.code?.map((item) => (
            <div className="d-flex justify-content-between m-3" key={item}>
              <p>{item}</p>
              <div>
                <DeleteOutlined
                  style={{ cursor: "pointer" }}
                  onClick={() => deleteCode(item)}
                />
              </div>
            </div>
          ))}
      </Col>
    </Row>
  );
};

export default ProductBasic;
