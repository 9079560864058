import React, { useState } from "react";
import {
  Card,
  Collapse,
  Input,
  Modal,
  Popconfirm,
  Space,
  Tooltip,
} from "antd";
import {
  CaretRightOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusCircleFilled,
} from "@ant-design/icons";
import ReactQuill from "react-quill";
import CloneDetails from "./CloneDetails";

const { Panel } = Collapse;
const CloneProdDescription = ({ data = [], onChange }) => {
  const [visible, setVisible] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [selectedDesc, setSelectedDesc] = useState("");

  // handle editor input
  const onEditorStateChange = (value) => {
    setDescription(value);
  };

  // fetch all policy
  // add policy api
  const addDescription = async () => {
    let desc = [...data];
    desc.push({ title, description });
    onChange(desc);
    closeModal();
  };
  // update faq api

  const editDescription = async () => {
    let desc = [...data];
    desc[selectedDesc]={ title, description };
    console.log(desc)
    onChange(desc);
    closeModal();
  };

  // delete faq question
  const deleteDescription = async (idx) => {
    let desc = [...data];
    desc.splice(idx,1);
    onChange(desc);
  };

  // handle modal
  const closeModal = () => {
    setSelectedDesc("");
    setTitle("");
    setDescription("");
    setVisible(false);
  };
  const openModal = ({ title, description, idx }) => {
    setSelectedDesc(idx);
    setTitle(title);
    setDescription(description);
    setVisible(true);
  };

  // extra
  const getExtra = ({ title, description, idx }) => (
    <React.Fragment>
      <div className="d-flex justify-content-around align-items-center">
        <div className="mr-2">
          <EditOutlined
            onClick={(e) => {
              openModal({ title, description, idx });
              e.stopPropagation();
            }}
          />
        </div>
        <div className="mr-2">
          <Popconfirm
            title="Are you sure?"
            okType="danger"
            onConfirm={(e) => {
              deleteDescription(idx);
              e.stopPropagation();
            }}
          >
            <DeleteOutlined
              style={{ color: "red" }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
          </Popconfirm>
        </div>
      </div>
    </React.Fragment>
  );

  const getExtraForCategoryCard = () => (
    <div className="d-flex justify-content-around align-items-center">
      <div className="mr-2">
        <Tooltip title="Add policy">
          <PlusCircleFilled
            style={{ cursor: "pointer" }}
            onClick={() => setVisible(true)}
          />
        </Tooltip>
      </div>
    </div>
  );
  return (
    <Card
      className="mb-4"
      title="Click plus icon to add more"
      extra={getExtraForCategoryCard()}
      bodyStyle={{padding:"0"}}
    >
      <Space direction="vertical" style={{ width: "100%" }}>
        {data.map(({ description, title }, idx) => (
          <Collapse
            key={idx}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotationslider={isActive ? 90 : 0} />
            )}
          >
            <Panel
              key="1"
              header={title}
              extra={getExtra({ title, description,idx })}
            >
              <CloneDetails key="1" answerText={description} />
            </Panel>
          </Collapse>
        ))}
      </Space>
      <Modal
        open={visible}
        title={
          String(selectedDesc)
            ? "Update question and answer"
            : "Add question and answer"
        }
        onCancel={closeModal}
        okText={String(selectedDesc) ? "Update" : "Add"}
        okButtonProps={{
          disabled: !title || !description,
        }}
        onOk={String(selectedDesc) ? editDescription : addDescription}
      >
        <div className="mb-4">
          <label>Title*</label>
          <Input
            size="large"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Title"
          />
        </div>
        <div>
          <label>Description*</label>
          <ReactQuill
            modules={CloneProdDescription.modules}
            value={description || ""}
            onChange={onEditorStateChange}
          />
        </div>
      </Modal>
    </Card>
  );
};

export default CloneProdDescription;

CloneProdDescription.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "blockquote"],
    [{ indent: "-1" }, { indent: "+1" }],
  ],
  clipboard: {
    matchVisual: false,
  },
};
