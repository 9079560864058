import gql from "graphql-tag";

export const FETCH_LEGAL_CATEGORY = gql`
 query FetchLegalTerm($limit: Int, $offset: Int) {
    FetchLegalTerm(limit: $limit, offset: $offset) {
      code
      success
      message
      result {
        _id
        name
        faq {
          _id
          qua
          ans
        }
      }
    }
  }
`;

export const ADD_LEGAL_CATEGORY = gql`
   mutation AddLeagalCategory($name: String!) {
    AddLeagalCategory(name: $name) {
      code
      success
      message
    }
  }
`;

export const EDIT_LEGAL_CATEGORY = gql`
  mutation EditLeagalCategory($categoryId: ID!, $name: String!) {
    EditLeagalCategory(categoryId: $categoryId, name: $name) {
      code
      success
      message
    }
  }
`;

export const DELETE_LEGAL_CATEGORY = gql`
  mutation DeleteLeagalCategory($categoryId: ID!) {
    DeleteLeagalCategory(categoryId: $categoryId) {
      code
      success
      message
    }
  }
`;

export const ADD_LEGAL_IN_CATEGORY = gql`
  mutation AddLegalTerm($qua: String!, $ans: String!, $categoryId: ID!) {
    AddLegalTerm(qua: $qua, ans: $ans, categoryId: $categoryId) {
      code
      success
      message
    }
  }
`;

export const EDIT_LEGAL_IN_CATEGORY = gql`
 mutation EditLegalTerm($qua: String!, $ans: String!, $faqId: ID!) {
    EditLegalTerm(qua: $qua, ans: $ans, faqId: $faqId) {
      code
      success
      message
    }
  }
`;

export const DELETE_LEGAL_IN_CATEGORY = gql`
  mutation DeleteLegalTerm($faqId: ID!) {
    DeleteLegalTerm(faqId: $faqId) {
      code
      success
      message
    }
  }
`;

