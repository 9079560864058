import React from "react";
// import { Collapse } from "antd";

// const {Panel} = Collapse
const Answer = ({ answerText }) => {
  // handle modal

  return (
    <div className="col-12">
      <div
       className="content-p-text"
        dangerouslySetInnerHTML={{
          __html: answerText,
        }}
      />
    </div>
  );
};

export default Answer;
