import React, { Fragment, useState } from "react";
import {
  Input,
  Modal,
  Radio,
  Spin,
  Table,
  Tabs,
} from "antd";
import {
  ArrowRightOutlined,
  DeleteFilled,
  MenuOutlined,
} from "@ant-design/icons";
import { DynamicList } from "../components/Shared";
import { useQuery, useMutation } from "@apollo/react-hooks";
import {
  ADD_SIZING,
  DELETE_SIZING,
  EDIT_SIZING,
  FETCH_SIZING,
  UPDATE_SIZING_POSITION,
} from "../graphql/modules";
import { errorNotify, successNotify, warnNotify } from "../util";
import { Link } from "react-router-dom";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import arrayMove from "array-move";
import { useEffect } from "react";

const DragHandle = sortableHandle(() => (
  <MenuOutlined style={{ cursor: "grab", color: "#999" }} />
));
const SortableItem = sortableElement((props) => <tr {...props} />);
const SortableContainer = sortableContainer((props) => <tbody {...props} />);
export const Size = () => {
  const [offset] = useState(0);
  const [visible, setVisible] = useState(false);
  const [sizingName, setSizingName] = useState("");
  const [type, setType] = useState("topSizing");
  const [typeInput, setTypeInput] = useState("");
  const [sizingId, setSizingId] = useState("");
  // const [limit] = useState(16);
  const [dataSource, setDataSource] = useState([]);

  // fetch size
  const { data, refetch, loading } = useQuery(FETCH_SIZING, {
    variables: {
      offset: offset,
      type,
    },
  });
  const sizing = data?.FetchSizing?.result || [];
  // const total = data?.FetchSizing?.count;

  useEffect(() => {
    setDataSource(sizing);
  }, [sizing?.length]);

  // const onChangePagination = (offset) => setOffset(offset - 1);

  // add sizing
  const [CreateSizingMutation, { loading: createLoading }] =
    useMutation(ADD_SIZING);
  const onCreateSizing = async () => {
    try {
      const {
        data: { AddSizing },
      } = await CreateSizingMutation({
        variables: {
          name: sizingName,
          type: typeInput,
        },
      });
      if (AddSizing.success) {
        successNotify(AddSizing.message);
        closeModal();
        refetch();
      } else {
        warnNotify(AddSizing.message);
      }
    } catch (error) {
      errorNotify(error.message);
    }
  };

  // delete size
  const [DeleteSizingMutation, {loading: deleteLoading}] = useMutation(DELETE_SIZING);
  const onDelete = async (sizingId) => {
    try {
      const {
        data: { DeleteSizing },
      } = await DeleteSizingMutation({
        variables: {
          sizingId,
        },
      });
      if (DeleteSizing.success) {
        successNotify(DeleteSizing.message);
        refetch();
      }
    } catch (error) {
      errorNotify(error.message);
    }
  };

  // update sizing
  const [EditSizingMutation, { loading: editLoading }] =
    useMutation(EDIT_SIZING);
  const onEditSizing = async () => {
    try {
      const {
        data: { EditSizing },
      } = await EditSizingMutation({
        variables: {
          sizingId,
          name: sizingName,
        },
      });
      if (EditSizing.success) {
        successNotify(EditSizing.message);
        refetch();
        closeModal();
      } else {
        warnNotify(EditSizing.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // handle select
  const handleSelectType = (e) => {
    setTypeInput(e.target.value);
  };

  // handle tab
  const onChangeTab = (tab) => {
    setType(tab);
  };
  const closeModal = () => {
    setVisible(false);
    setSizingId("");
    setSizingName("");
    setTypeInput("");
  };

  //page top action button
  let createBtn = (
    <React.Fragment>
      <button
        onClick={() => setVisible(true)}
        className="btn-common mr-3"
        type="primary"
      >
        <i className="fas fa-plus-circle mr-2"></i> New Size
      </button>
      <Link
        to="/sizing/guide?type=top"
        style={{ minWidth: "110px", fontSize: "18px" }}
      >
        Size Guide <ArrowRightOutlined />
      </Link>
    </React.Fragment>
  );

  const columns = [
    {
      title: "#",
      render: (_text, _row, index) => index + 1,
    },
    {
      title: "Sort",
      render: () => <DragHandle />,
      className: "drag-visible",
    },
    { title: "Size Name", dataIndex: "name" },
    {
      title: "Action",
      render: (_, record) => (
        <DeleteFilled
          style={{ color: "red" }}
          onClick={() => onDelete(record?._id)}
        />
      ),
      align: "right",
    },
  ];

  // drag and drop
  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMove(
        [].concat(dataSource),
        oldIndex,
        newIndex
      ).filter((el) => !!el);
      setDataSource([...newData]);
      onDragUpdateSize(newData);
    }
  };

  // drag container
  const DraggableContainer = (props) => (
    <SortableContainer
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  // function findIndex base on Table rowKey props
  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = dataSource.findIndex(
      (item) => item._id === restProps["data-row-key"]
    );
    return <SortableItem index={index} {...restProps} />;
  };

  // update drag and drop
  const [DragAndDropMutation] = useMutation(UPDATE_SIZING_POSITION);
  const onDragUpdateSize = async (newData = []) => {
    const newArray = newData.map((item) => item._id);
    try {
      const {
        data: { UpdateSizingPosition },
      } = await DragAndDropMutation({
        variables: {
          data: newArray,
        },
      });
      if (UpdateSizingPosition.success) {
        refetch();
      } else {
        warnNotify(UpdateSizingPosition.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <Fragment>
      <DynamicList title="All Sizes" actions={createBtn} tableHide>
        <Spin spinning={loading}>
          <Tabs defaultActiveKey="1" centered onChange={onChangeTab}>
            <Tabs.TabPane tab="TOP SIZE" key="topSizing">
              <Table
                columns={columns}
                dataSource={dataSource || []}
                rowKey={(row) => row?._id}
                components={{
                  body: {
                    wrapper: DraggableContainer,
                    row: DraggableBodyRow,
                  },
                }}
                loading={deleteLoading}
                pagination={false}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="BOTTOM SIZE" key="bottomSizing">
              <Table
                columns={columns}
                dataSource={dataSource || []}
                rowKey={(row) => row?._id}
                components={{
                  body: {
                    wrapper: DraggableContainer,
                    row: DraggableBodyRow,
                  },
                }}
                pagination={false}
              />
            </Tabs.TabPane>
          </Tabs>
        </Spin>
      </DynamicList>

      <Modal
        title={sizingId ? "Update Category" : "Add new subcategory"}
        open={visible}
        onOk={sizingId ? onEditSizing : onCreateSizing}
        okText={sizingId ? "Update" : "Create"}
        okButtonProps={{
          disabled: !sizingName || !typeInput,
          loading: createLoading || editLoading,
        }}
        onCancel={closeModal}
      >
        <div className="mb-3">
          <label>Size Name</label>
          <Input
            size="large"
            value={sizingName}
            placeholder="Size"
            onChange={(e) => setSizingName(e.target.value)}
          />
        </div>
        <div>
          <div>
            <label>Select Type</label>
          </div>
          <Radio.Group onChange={handleSelectType} value={typeInput}>
            <Radio value="topSizing" key="topSizing">
              TOP
            </Radio>
            <Radio value="bottomSizing" key="bottomSizing">
              BOTTOM
            </Radio>
          </Radio.Group>
        </div>
      </Modal>
    </Fragment>
  );
};
