import { useMutation, useQuery } from "@apollo/react-hooks";
import { Button, Input, List, Modal, Select } from "antd";
import React from "react";
import { useState } from "react";
import {
  ADD_TAX,
  DELETE_TAX,
  EDIT_TAX,
  FETCH_TAXES,
} from "../../graphql/modules";
import { stateOfCanada, successNotify, warnNotify } from "../../util";

const Tax = () => {
  const [country, setCountry] = useState("CA");
  const [taxId, setTaxId] = useState("");
  const [state, setState] = useState("");
  const [rate, setRate] = useState("");
  const [visible, setVisible] = useState(false);

  const { data, loading, refetch } = useQuery(FETCH_TAXES);
  const allStateTax = data?.FetchTax?.result || [];

  // add tax
  const [AddTaxMutation, { loading: addLoading }] = useMutation(ADD_TAX);
  const onAddTax = async () => {
    try {
      const {
        data: { AddTax },
      } = await AddTaxMutation({
        variables: {
          data: {
            country,
            state,
            rate: parseFloat(rate),
          },
        },
      });
      if (AddTax.success) {
        successNotify(AddTax.message);
        refetch();
        handleCancel();
      } else {
        warnNotify(AddTax.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  // add tax
  const [EditTaxMutation, { loading: editLoading }] = useMutation(EDIT_TAX);
  const onEditTax = async () => {
    try {
      const {
        data: { EditTax },
      } = await EditTaxMutation({
        variables: {
          data: {
            country,
            state,
            rate: parseFloat(rate),
          },
          taxId
        },
      });
      if (EditTax.success) {
        successNotify(EditTax.message);
        refetch();
        handleCancel();
      } else {
        warnNotify(EditTax.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // add tax
  const [DeleteTaxMutation, { loading: deleteLoading }] =
    useMutation(DELETE_TAX);
  const onDeleteTax = async (taxId) => {
    try {
      const {
        data: { DeleteTax },
      } = await DeleteTaxMutation({
        variables: {
          taxId,
        },
      });
      if (DeleteTax.success) {
        successNotify(DeleteTax.message);
        refetch();
      } else {
        warnNotify(DeleteTax.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleCancel = () => {
    setVisible(false);
    setRate("");
    setTaxId("");
    setState("");
  };

  const handleEdit = (data) => {
    setState(data?.state);
    setRate(data?.rate);
    setTaxId(data?._id);
    setVisible(true);
  };

  return (
    <div>
      <div className="mb-5">
        <Button type="primary" onClick={() => setVisible(true)}>
          Add Tax
        </Button>
      </div>
      <div className="col-md-6">
        <Input value="Canada" size="large"/>
      </div>
      <div className="col-md-6">
        <List
          className="demo-loadmore-list"
          loading={loading || deleteLoading}
          itemLayout="horizontal"
          dataSource={allStateTax}
          renderItem={(item) => (
            <List.Item
              actions={[
                <Button onClick={()=>handleEdit(item)}>Edit</Button>,
                <Button type="danger" onClick={()=>onDeleteTax(item?._id)}>Delete</Button>,
              ]}
            >
              {/* <List.Item.Meta
                avatar={<Avatar src={item.picture.large} />}
                title={<a href="https://ant.design">{item.name?.last}</a>}
                description="Ant Design, a design language for background applications, is refined by Ant UED Team"
              /> */}
              <div className="d-flex w-100 justify-content-between align-items-center">
                <div style={{ fontWeight: "600", fontSize: "16px" }}>
                  {stateOfCanada.find((st) => st.code === item.state)?.name}
                </div>
                <div>{item.rate}</div>
              </div>
            </List.Item>
          )}
        />
        {/* {allStateTax?.map((tx) => (
          <div
            key={tx?._id}
            className="d-flex justify-content-between align-items-center"
          >
            <div>{tx?.state}</div>
            <div>{tx?.rate}</div>
          </div>
        ))} */}
      </div>
      <Modal
        open={visible}
        title={taxId?"Update":"Add Tax"}
        onCancel={handleCancel}
        onOk={taxId ? onEditTax:onAddTax}
        okButtonProps={{
          loading: addLoading,
          disabled: !state || !rate || addLoading,
        }}
        okText={taxId ? "Update":"Add"}
      >
        <div className="mb-3">
          <div className="mb-2">Country</div>
          <Input value="Canada" size="large" disabled />
        </div>
        <div className="mb-3">
          <div className="mb-2">State</div>
          <Select
            style={{ width: "100%" }}
            size="large"
            value={state}
            onChange={(value) => setState(value)}
          >
            {stateOfCanada.map(({ name, code }) => (
              <Select.Option key={code}>{name}</Select.Option>
            ))}
          </Select>
        </div>
        <div className="mb-3">
          <div className="mb-2">Rate</div>
          <Input
            value={rate}
            type="number"
            onChange={(e) => setRate(e.target.value)}
            size="large"
          />
        </div>
      </Modal>
    </div>
  );
};

export default Tax;
