import gql from "graphql-tag";

export const FETCH_HOMEPAGE_DATA = gql`
  query FetchHomePageProducts($type: String) {
    FetchHomePageProducts(type: $type) {
      code
      success
      message
      result {
        product {
          _id
          name
          description
          defaultImg
          gallery
          price
          gallery
          isAvailable
          topSizing {
            _id
            size {
              _id
              name
            }
          }
          bottomSizing {
            _id
            size {
              _id
              name
            }
          }
        }
        categoryTitle
        isDefault
        type
      }
    }
  }
`;

export const FETCH_BANNER = gql`
  query FetchBanner($isBottomPoster: Boolean, $isMobileItem: Boolean) {
    FetchBanner(isBottomPoster: $isBottomPoster, isMobileItem: $isMobileItem) {
      code
      success
      result {
        _id
        link
        type
        anchorLink
        firstBtnLink
        secondBtnLink
        firstBtnText
        secondBtnText
        bannerText
        isBottomPoster
        isFirstBottomPoster
      }
    }
  }
`;

export const ADD_BANNER = gql`
  mutation AddBanner(
    $link: String
    $type: String
    $anchorLink: String
    $firstBtnLink: String
    $firstBtnText: String
    $secondBtnLink: String
    $secondBtnText: String
    $bannerText: String
    $isBottomPoster: Boolean
    $isFirstBottomPoster: Boolean
    $isMobileItem: Boolean
  ) {
    AddBanner(
      link: $link
      type: $type
      anchorLink: $anchorLink
      firstBtnLink: $firstBtnLink
      firstBtnText: $firstBtnText
      secondBtnLink: $secondBtnLink
      secondBtnText: $secondBtnText
      bannerText: $bannerText
      isBottomPoster: $isBottomPoster
      isFirstBottomPoster: $isFirstBottomPoster
      isMobileItem: $isMobileItem
    ) {
      code
      success
      message
    }
  }
`;

export const EDIT_BANNER = gql`
  mutation EditBanner(
    $id: ID
    $link: String
    $anchorLink: String
    $type: String
    $firstBtnLink: String
    $firstBtnText: String
    $secondBtnLink: String
    $secondBtnText: String
    $bannerText: String
  ) {
    EditBanner(
      id: $id
      link: $link
      anchorLink: $anchorLink
      type: $type
      firstBtnLink: $firstBtnLink
      firstBtnText: $firstBtnText
      secondBtnLink: $secondBtnLink
      secondBtnText: $secondBtnText
      bannerText: $bannerText
    ) {
      code
      success
      message
    }
  }
`;

export const DELETE_BANNER = gql`
  mutation DeleteBanner($id: ID) {
    DeleteBanner(id: $id) {
      code
      success
      message
    }
  }
`;

export const UPDATE_BANNER_POSITION = gql`
  mutation UpdateBannerPosition($data: [ID]) {
    UpdateBannerPosition(data: $data) {
      code
      success
      message
    }
  }
`;

export const VIDEO_UPLOAD = gql`
  mutation UpdateHomepageVideo($videoLink: String) {
    UpdateHomepageVideo(videoLink: $videoLink) {
      code
      success
      message
    }
  }
`;

export const HOME_PAGE_PRODUCT_SET = gql`
  mutation UpdateHomepageProducts($products: [HomepageProductInput]) {
    UpdateHomepageProducts(products: $products) {
      code
      success
      message
    }
  }
`;

export const UPDATE_FETURED_CATEGORY = gql`
  mutation UpdateFeaturedCategory($categories: [ID]) {
    UpdateFeaturedCategory(categories: $categories) {
      code
      success
      message
    }
  }
`;

export const UPDATE_HOME_PAGE_SECTION = gql`
  mutation UpdateHomePageSection(
    $type: HomepageSectionType
    $inputData: SectionInput
  ) {
    UpdateHomePageSection(type: $type, inputData: $inputData) {
      code
      success
      message
    }
  }
`;

export const FETCH_HOMEPAGE_SECTION = gql`
  query FetchHomepageSectionsWithProduct($productSize: Int) {
    FetchHomepageSectionsWithProduct(productSize: $productSize) {
      code
      result {
        category {
          _id
          name
        }
        subcategory {
          _id
          name
        }
        isCategory
        sectionTitle
        categoryBackground
        type
      }
    }
  }
`;

export const UPDATE_FETURED_CATEGORY_TITLE = gql`
  mutation UpdateHomepageCategoryTitle($text: String) {
    UpdateHomepageCategoryTitle(text: $text) {
      code
      success
      message
    }
  }
`;

export const FETCH_HOME_SECTION_SEQUENCE = gql`
  query FetchHomePageSectionsSequence {
    FetchHomePageSectionsSequence {
      code
      success
      result {
        _id
        name
      }
    }
  }
`;

export const UPDATE_HOME_SECTION_SEQUENCE = gql`
  mutation UpdateHomePageSectionPosition($data: [ID]) {
    UpdateHomePageSectionPosition(data: $data) {
      code
      success
      message
    }
  }
`;

export const FETCH_SHOP_COLLECTION = gql`
  query {
    FetchHomePageSectionFour {
      code
      result {
        sectionTitle
        sectionDescription
        photo
        buttonLink
        buttonText
      }
    }
  }
`;
export const UPDATE_SHOP_COLLECTION = gql`
  mutation UpdateHomePageSectionFour(
    $sectionTitle: String
    $sectionDescription: String
    $photo: String
    $buttonLink: String
    $buttonText: String
  ) {
    UpdateHomePageSectionFour(
      sectionTitle: $sectionTitle
      sectionDescription: $sectionDescription
      photo: $photo
      buttonLink: $buttonLink
      buttonText: $buttonText
    ) {
      code
      success
      message
    }
  }
`;

export const ADD_CUSTOMER_PICK = gql`
  mutation AddCustomerPicks(
    $link: String
    $userName: String
    $pickLink: String
  ) {
    AddCustomerPicks(link: $link, userName: $userName, pickLink: $pickLink) {
      code
      success
      message
    }
  }
`;
export const EDIT_CUSTOMER_PICK = gql`
  mutation EditCustomerPicks(
    $id: ID
    $link: String
    $userName: String
    $pickLink: String
  ) {
    EditCustomerPicks(
      id: $id
      link: $link
      userName: $userName
      pickLink: $pickLink
    ) {
      code
      success
      message
    }
  }
`;
export const DELETE_CUSTOMER_PICK = gql`
  mutation DeleteCustomerPicks($id: ID) {
    DeleteCustomerPicks(id: $id) {
      code
      success
      message
    }
  }
`;
export const FETCH_CUSTOMER_PICK = gql`
  query FetchCustomerPicks {
    FetchCustomerPicks {
      code
      success
      message
      sectionTitle
      result {
        _id
        link
        userName
        pickLink
      }
    }
  }
`;

export const UPDATE_CUSTOMR_PICK_TITLE = gql`
  mutation UpdateCustomerPicksTitle($title: String) {
    UpdateCustomerPicksTitle(title: $title) {
      code
      success
      message
    }
  }
`;

export const ADD_CATEGORY_FEATURE = gql`
  mutation AddFeaturedCategory(
    $cardTitle: String
    $imageLink: String
    $url: String
  ) {
    AddFeaturedCategory(
      cardTitle: $cardTitle
      imageLink: $imageLink
      url: $url
    ) {
      success
      message
    }
  }
`;

export const EDIT_FEATURE_CATEGORY = gql`
  mutation EditFeaturedCategory(
    $cardTitle: String
    $imageLink: String
    $url: String
    $id: ID
  ) {
    EditFeaturedCategory(
      cardTitle: $cardTitle
      imageLink: $imageLink
      url: $url
      id: $id
    ) {
      success
      message
    }
  }
`;

export const FETCH_FEATURE_CATEOGRY = gql`
  query FetchFeaturedCategory {
    FetchFeaturedCategory {
      success
      message
      result {
        _id
        sectionTitle
        imageLink
        url
        cardTitle
      }
    }
  }
`;

export const DELETE_FEATURE_CATEOGRY = gql`
  mutation DeleteFeaturedCategory($id: ID) {
    DeleteFeaturedCategory(id: $id) {
      success
      message
    }
  }
`;

export const UPDATE_FEATURE_SECTION_TITLE = gql`
  mutation UpdateFeaturedCategoryTitle($sectionTitle: String) {
    UpdateFeaturedCategoryTitle(sectionTitle: $sectionTitle) {
      success
      message
    }
  }
`;
