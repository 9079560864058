import React, { useState } from "react";
import { Button, Input, Modal, Pagination, Spin } from "antd";
import { useQuery } from "@apollo/react-hooks";
import { FETCH_CATEGORY_WITH_PRODUCT } from "../../graphql/modules";
import SingleSubcategory from "./SingleSubcategory";
import { SearchOutlined } from "@ant-design/icons";
import SingleCategory from "./SingleCategory";

const AddProductAction = ({ products, setProducts }) => {
  const [state, setState] = useState({
    limit: 1,
    offset: 0,
    visible: false,
    searchText: "",
  });
  const [newProcuts, setNewProducts] = useState([]);

  const onChangePagination = (offset) =>
    setState({ ...state, offset: offset - 1 });
  const onClose = () => setState({ ...state, visible: false });
  const onOpen = () => setState({ ...state, visible: true });
  const onSelectToggle = (product) => {
    const idx = newProcuts.findIndex(
      (item) => item.product._id === product._id
    );
    if (idx === -1) setNewProducts([...newProcuts, { product }]);
    else {
      newProcuts.splice(idx, 1);
      setNewProducts([...newProcuts]);
    }
  };
  const isSelected = (product) => {
    const idx = newProcuts.findIndex(
      (item) => item.product._id === product._id
    );
    if (idx === -1) return false;
    else return idx + 1;
  };

  const onSetProducts = () => {
    setProducts([...newProcuts]);
    onClose();
  };

  const { data, loading } = useQuery(FETCH_CATEGORY_WITH_PRODUCT, {
    variables: {
      limit: state.limit,
      offset: state.offset,
      categoryName: state.searchText,
    },
    // fetchPolicy: "no-cache",
  });
  const result = data?.FetchCategoryAdmin?.result || [];
  const total = data?.FetchCategoryAdmin?.count || 0;

  const title = (
    <div className="d-flex justify-content-between align-items-center">
      <h2 className="c-modal-header">{newProcuts.length} product selected</h2>
      <div>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={onSetProducts}
          className="ml-2"
          type="primary"
        >
          <i className="fas fa-plus-circle mr-2"></i> Done
        </Button>
      </div>
    </div>
  );
  const modalProps = {
    title,
    open: state.visible,
    footer: null,
    className: "add-product-modal custom-positioning-modal",
  };

  // useEffect(() => {
  //   const data = [];
  //   products?.map((item) => {
  //     if (item) {
  //       const { _id } = item;
  //       data.push({ product: _id });
  //     }
  //   });
  //   setNewProducts(data);
  // }, [products]);

  return (
    <>
      <div className=" mt-2">
        <button onClick={onOpen} className="btn-common" type="primary">
          <i className="fas fa-plus-circle mr-2"></i> Add Product
        </button>
      </div>
      <Modal {...modalProps}>
        <Spin spinning={loading}>
          <div className="subcategory">
            <div className="text-right">
              <Input
                size="large"
                style={{ maxWidth: "200px", marginLeft: "auto" }}
                placeholder="Search by category"
                prefix={<SearchOutlined />}
                onChange={(e) =>
                  setState({ ...state, searchText: e.target.value })
                }
              />
            </div>
            {result.map(({ name, subcategory, products, _id, sizeType }) => {
              if (products?.length) {
                if (products?.length === 0) return "";
                return (
                  <SingleCategory
                   key={_id}
                   products={products}
                   categoryName={name}
                   onSelectToggle={onSelectToggle}
                   isSelected={isSelected}
                   newProcuts={newProcuts}
                   sizeType={sizeType}
                  />
                  // <div>
                  //   <h2 className="category">{name}</h2>
                  //   <Empty
                  //     description="No subcategory has been added to this category."
                  //     image="https://virtualshipment.in/images/no-data.png"
                  //     className="w-100 empty-text"
                  //   />
                  // </div>
                );
              }
              return subcategory.map((item) => {
                if (item?.products?.length === 0) return "";
                return (
                  <SingleSubcategory
                    key={item._id}
                    {...item}
                    categoryName={name}
                    onSelectToggle={onSelectToggle}
                    isSelected={isSelected}
                    newProcuts={newProcuts}
                  />
                );
              });
            })}
          </div>
          <Pagination
            className="pagination"
            total={total}
            pageSize={state.limit}
            current={state.offset + 1}
            showSizeChanger={false}
            onChange={onChangePagination}
          />
        </Spin>
      </Modal>
    </>
  );
};

export default AddProductAction;
