import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { Spin } from "antd";
import { FETCH_BANNER } from "../../graphql/modules";
import PosterOrVideo from "./PosterOrVideo";

const PosterOrVideoWrapper = ({mobile}) => {
  // fetch banner
  const { data, refetch, loading } = useQuery(FETCH_BANNER, {
    variables: { isBottomPoster: true, isMobileItem: mobile },
  });
  const bannerList = data?.FetchBanner?.result || [];

  return (
    <Spin spinning={loading}>
      <div className="landing-video">
        <div className="row">
          <PosterOrVideo
            refetch={refetch}
            serialText={"First"}
            mobile={mobile}
            banner={bannerList?.[0]}
            isFirstBottomPoster={true}
          />
          <PosterOrVideo
            refetch={refetch}
            mobile={mobile}
            serialText={"Second"}
            banner={bannerList?.[1]}
            isFirstBottomPoster={false}
          />
        </div>
      </div>
    </Spin>
  );
};

export default PosterOrVideoWrapper;
